import { IoClose } from 'react-icons/io5';
import { Autocomplete, Dialog, DialogActions, DialogContent, Grid, IconButton, TextField } from '@mui/material';
import { Button } from '../../components/button/Button';
import { Form } from '../../components/form/Form';
import { BackdropCustom } from '../../components/backdrop/Backdrop';
import InputMask from 'react-input-mask';
import { useEffect, useState } from 'react';
import { TitleContainer } from '../../components/title-container/TitleContainer';
import { useCustomerLevelService } from '../../services/useCustomerLevelService';
import ICustomerLevel from '../../interfaces/ICustomerLevel';
import { useToastr } from '../../hooks/useToastr';
import * as Yup from 'yup';
import IFormError from '../../interfaces/IFormError';
import { useValidateSchema } from '../../services/useValidateSchema';
import { useCustomerService } from '../../services/useCustomerService';
import { ICustomer } from '../../interfaces/ICustomer';
import { useSalesRegionService } from '../../services/useSalesRegionService';
import { useAuth } from '../../hooks/auth';
import { useCnpjValidator } from '../../util/useCnpjValidator';

interface IProps {
  open: boolean;
  onClose(): void;
  customerCreated(customer: ICustomer): void;
  customerId?: string;
}

const STATES = [
  { id: 'SC', label: 'Santa Catarina' },
  { id: 'PR', label: 'Paraná' },
  { id: 'SP', label: 'São Paulo' },
  { id: 'RJ', label: 'Rio de Janeiro' },
  { id: 'ES', label: 'Espírito Santo' },
  { id: 'MG', label: 'Minas Gerais' },
  { id: 'BA', label: 'Bahia' },
  { id: 'SE', label: 'Sergipe' },
  { id: 'AL', label: 'Alagoas' },
  { id: 'PE', label: 'Pernambuco' },
  { id: 'RN', label: 'Rio Grande do Norte' },
  { id: 'PB', label: 'Paraíba' },
  { id: 'PI', label: 'Piauí' },
  { id: 'CE', label: 'Ceará' },
  { id: 'MA', label: 'Maranhão' },
  { id: 'PA', label: 'Pará' },
  { id: 'AM', label: 'Amazonas' },
  { id: 'AP', label: 'Amapá' },
  { id: 'AC', label: 'Acre' },
  { id: 'MT', label: 'Mato Grosso' },
  { id: 'MS', label: 'Mato Grosso do Sul' },
  { id: 'GO', label: 'Goiás' },
  { id: 'TO', label: 'Tocantins' },
  { id: 'RO', label: 'Rondônia' },
  { id: 'RR', label: 'Roraima' },
  { id: 'DF', label: 'Distrito Federal' },
  { id: 'RS', label: 'Rio Grande do Sul' },
];

const FISCAL_BENEFITS = [
  {
    id: 0,
    label: 'Sem Benefício',
  },
  {
    id: 1,
    label: 'Zona Franca de Manaus',
  },
  {
    id: 3,
    label: 'Área de Livre Comércio',
  },
  {
    id: 4,
    label: 'Amazônia Ocidental',
  },
];

const CustomerDialogForm = ({ open, onClose, customerCreated, customerId }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [corporateDocument, setCorporateDocument] = useState('');
  const [corporateName, setCorporateName] = useState('');
  const [tradeName, setTradeName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [city, setCity] = useState('');
  const [customerLevel, setCustomerLevel] = useState<ICustomerLevel>({ id: 0, description: '' });
  const [customerLevels, setCustomerLevels] = useState<ICustomerLevel[]>([]);
  const [salesRegion, setSalesRegion] = useState<{ id?: string; name?: string }>({ id: '', name: '' });
  const [salesRegions, setSalesRegions] = useState<{ id?: string; name?: string }[]>([]);
  const [errors, setErrors] = useState<IFormError | null>(null);
  const [states] = useState(STATES);
  const [state, setState] = useState<{ id?: string; label?: string }>({ id: '', label: '' });
  const [fiscalBenefits] = useState(FISCAL_BENEFITS);
  const [fiscalBenefit, setFiscalBenefit] = useState<{ id?: number; label?: string }>({ id: 0, label: '' });
  const [isAdmin, setIsAdmin] = useState(false);

  const { validate } = useValidateSchema();

  const toastr = useToastr();
  const customerLevelService = useCustomerLevelService();
  const customerService = useCustomerService();
  const salesRegionService = useSalesRegionService();
  const user = useAuth();
  const validCNPJ = useCnpjValidator();

  const validateIfUserIsAdmin = () => {
    const adminResource = user?.state?.resources.find(resource => resource === 'administrador-pedido-web');
    if (adminResource) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
      const salesRegion = user?.state?.seller?.salesRegion;
      if (salesRegion) {
        setSalesRegion({ id: salesRegion.id, name: salesRegion.name });
      }
    }
  };

  const listCustomerLevels = async () => {
    setLoading(true);
    await customerLevelService
      .listAll(
        `perPage=50&currentPage=1&orderBy=description&orderDirection=asc&filterField=description&filterValue=prat&precision=`,
      )
      .then(result => {
        setCustomerLevels([...result.data]);
      })
      .catch(error => {
        toastr.error(`Houve um erro ao listar categorias: ${error.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const listSalesRegions = async () => {
    setLoading(true);

    await salesRegionService
      .findAll(`perPage=50&currentPage=1&orderBy=name&orderDirection=asc&filterField=name&filterValue=prat&precision=`)
      .then(result => {
        setSalesRegions([...result.data]);
      })
      .catch(error => {
        toastr.error(`Houve um erro ao listar categorias: ${error.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const save = async () => {
    const schema = Yup.object().shape({
      corporateName: Yup.string().required('A razão social é obrigatória'),
      corporateDocument: Yup.string()
        .required('CNPJ é Obrigatório')
        .test((corporateDocument, schema) => {
          const isDocumentValid = validCNPJ(corporateDocument);
          if (!isDocumentValid) {
            return schema.createError({ message: `CNPJ Inválido` });
          } else {
            return true;
          }
        }),
      state: Yup.string().required('O estado é obrigatório'),
      customerLevel: Yup.number().required('A categoria é obrigatória').min(1, 'A categoria é obrigatória'),
      idSalesRegion: Yup.string().required('Região obrigatória'),
    });

    const data = {
      corporateName,
      state: state?.id || '',
      corporateDocument,
      customerLevel: customerLevel?.id || 0,
      idSalesRegion: salesRegion.id,
    };
    const { errors, isValid } = await validate(schema, data);

    setErrors(errors);

    if (!isValid) {
      toastr.warning('Verifique os campos inválidos');
      return;
    }

    const customer = {
      code: '',
      corporateName,
      tradeName,
      corporateDocument,
      stateRegistration: '',
      customerAddress: [
        {
          addressTypeId: 1,
          street: '',
          number: '',
          complement: '',
          neighborhood: '',
          city,
          state: state.id || '',
          postalCode: '',
          country: 'Brasil',
        },
      ],
      idSalesRegion: salesRegion.id,
      fiscalBenefitId: fiscalBenefit?.id && fiscalBenefit?.id !== 0 ? fiscalBenefit?.id : null,
      phoneNumber,
      email,
      contact,
      customerLevelId: customerLevel.id,
      channelId: 1,
      isProspect: true,
    };

    if (!customerId) {
      setLoading(true);
      await customerService
        .create(customer)
        .then(createdCustomer => {
          reset();
          toastr.success('Cliente cadastrado com sucesso');
          customerCreated(createdCustomer);
        })
        .catch(error => {
          toastr.error(`Houve um erro ao cadastrar o cliente: ${error.message}`);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(true);

      await customerService
        .update(`${customerId}`, { ...customer, id: customerId })
        .then(createdCustomer => {
          reset();
          toastr.success('Cliente cadastrado com sucesso');
          customerCreated(createdCustomer);
        })
        .catch(error => {
          toastr.error(`Houve um erro ao cadastrar o cliente: ${error.message}`);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const listCustomer = async () => {
    setLoading(true);
    await customerService
      .listById(`${customerId}`)
      .then(result => {
        const address = result.customerAddress.find(address => address.addressTypeId === 1);
        setCorporateDocument(result.corporateDocument);
        setCorporateName(result.corporateName);
        setTradeName(result.tradeName);
        setPhoneNumber(result.phoneNumber);
        setEmail(result.email);
        setContact(result.contact);
        setState(states.find(value => value.id === address?.state) || {});
        if (result.salesRegion) {
          setSalesRegion({ id: result.salesRegion.id, name: result.salesRegion.name });
        }
        const benefit = fiscalBenefits.find(obj => obj.id === result.fiscalBenefitId);
        if (benefit) {
          setFiscalBenefit({ ...benefit });
        }
        setCity(`${address?.city}`);
        setCustomerLevel(
          customerLevels.find(value => value.id === result.customerLevelId) || { id: 0, description: '' },
        );
      })
      .catch(error => {
        toastr.error(`Erro ao carregar dados do cliente: ${error.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const reset = () => {
    setCorporateDocument('');
    setCorporateName('');
    setTradeName('');
    setPhoneNumber('');
    setEmail('');
    setContact('');
    setState({ id: '' });
    setCity('');
    setCustomerLevel({ id: 0, description: '' });
    setErrors(null);
    setSalesRegion({ id: '', name: '' });
    setIsAdmin(false);
  };

  const closeDialog = () => {
    reset();
    onClose();
  };

  useEffect(() => {
    listSalesRegions();
    listCustomerLevels();
    validateIfUserIsAdmin();
  }, []);

  useEffect(() => {
    if (open) {
      validateIfUserIsAdmin();
    }
    if (open && customerId) {
      listCustomer();
    }
  }, [open]);

  return (
    <Dialog open={open} maxWidth="md" fullWidth={true}>
      <TitleContainer>
        <IconButton style={{ position: 'absolute', top: '-10px', right: '-10px' }} onClick={closeDialog}>
          <IoClose />
        </IconButton>
        <h1>Cadastro de Cliente - Prospect</h1>
      </TitleContainer>
      <DialogContent>
        <Form>
          {loading && <BackdropCustom />}

          <Grid container spacing={3}>
            <Grid item xs={12} md={6} sm={12}>
              {
                <InputMask
                  mask={'99.999.999/9999-99'}
                  value={corporateDocument}
                  onChange={e => setCorporateDocument(e.target.value)}
                  maskPlaceholder={null}
                >
                  <TextField
                    fullWidth
                    size="small"
                    label="CNPJ"
                    autoComplete="off"
                    helperText={errors?.corporateDocument}
                    error={!!errors?.corporateDocument}
                  />
                </InputMask>
              }
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <TextField
                fullWidth
                size="small"
                label="Razão Social"
                value={corporateName}
                onChange={e => setCorporateName(e.target.value)}
                autoComplete="off"
                helperText={errors?.corporateName}
                error={!!errors?.corporateName}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <TextField
                fullWidth
                size="small"
                label="Nome Fantasia"
                value={tradeName}
                onChange={e => setTradeName(e.target.value)}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <InputMask
                mask={'(99)99999-9999'}
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
                maskPlaceholder={null}
              >
                <TextField fullWidth size="small" label="Telefone" autoComplete="off" />
              </InputMask>
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <TextField
                fullWidth
                size="small"
                label="E-mail"
                value={email}
                onChange={e => setEmail(e.target.value)}
                autoComplete="off"
                type="email"
              />
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <TextField
                fullWidth
                size="small"
                label="Contato"
                value={contact}
                onChange={e => setContact(e.target.value)}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                fullWidth
                options={fiscalBenefits}
                value={fiscalBenefit}
                getOptionLabel={state => state.label || ''}
                isOptionEqualToValue={(option, value) => {
                  return option?.id === value?.id || true;
                }}
                onChange={(e, value) => setFiscalBenefit({ ...value })}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Benefício Fiscal"
                    size="small"
                    helperText={errors?.state}
                    error={!!errors?.state}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <TextField
                fullWidth
                size="small"
                label="Cidade"
                value={city}
                onChange={e => setCity(e.target.value)}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                fullWidth
                options={salesRegions}
                value={salesRegion}
                disabled={!isAdmin}
                getOptionLabel={region => region.name || ''}
                isOptionEqualToValue={(option, value) => {
                  return option?.id === value?.id || true;
                }}
                onChange={(e, value) => {
                  if (value?.id && value?.name) {
                    setSalesRegion({ ...value });
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Região de Venda"
                    size="small"
                    helperText={errors?.idSalesRegion}
                    error={!!errors?.idSalesRegion}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Autocomplete
                fullWidth
                options={states}
                value={state}
                getOptionLabel={state => state.label || ''}
                isOptionEqualToValue={(option, value) => {
                  return option?.id === value?.id || true;
                }}
                onChange={(e, value) => setState({ ...value })}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Estado"
                    size="small"
                    helperText={errors?.state}
                    error={!!errors?.state}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                fullWidth
                options={customerLevels}
                value={customerLevel}
                getOptionLabel={level => level.description}
                isOptionEqualToValue={(option, value) => {
                  return option?.id === value?.id || true;
                }}
                onChange={(e, value) => {
                  if (value?.id && value?.description) {
                    setCustomerLevel({ ...value });
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Categoria"
                    size="small"
                    helperText={errors?.customerLevel}
                    error={!!errors?.customerLevel}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="inherit" onClick={closeDialog} buttonSize="medium">
          Cancelar
        </Button>
        <Button variant="contained" buttonSize="medium" onClick={save}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerDialogForm;
