import { useCallback, useEffect, useRef, useState } from 'react';
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import { useNavigate } from 'react-router-dom';

import { PageCard } from '../../../components/page-card/PageCard';
import { useToastr } from '../../../hooks/useToastr';
import CustomTableSortLabel from '../../../components/table/CustomTableSortLabel/CustomTableSortLabel';
import CustomTablePagination from '../../../components/table/CustomTablePagination/CustomTablePagination';
import CustomTableNoContent from '../../../components/table/CustomTableNoContent/CustomTableNoContent';
import { CustomMenuItem } from '../../../components/custom-menu-item/CustomMenuItem';
import { usePriceTableItemsService } from '../../../services/usePriceTableItemsService';
import { IPriceTableItem } from '../../../interfaces/IPriceTableItem';
import useFormatIntegerDecimalValues from '../../../util/useFormatIntegerDecimalValues';
import { ButtonGroupFilter } from './PriceTablesList.styled';
import { BackdropCustom } from '../../../components/backdrop/Backdrop';

interface IParams {
  perPage: number;
  currentPage: number;
  orderField: string;
  orderDirection: 'asc' | 'desc';
  field?: string;
  value?: string;
  precision?: string;
  delay?: number;
}

const fields = [
  {
    field: 'productSku',
    label: 'Código Produto',
  },
  {
    field: 'productDescription',
    label: 'Descrição Produto',
  },
];

interface IPriceTablesListItemsProps {
  priceTableId: string;
}

const PriceTablesListItems: React.FC<IPriceTablesListItemsProps> = ({ priceTableId }) => {
  const navigate = useNavigate();
  const toastr = useToastr();
  const timeout = useRef<any>(null);

  const { listAllPriceTableItems } = usePriceTableItemsService();
  const { formatIntegerDecimalValues } = useFormatIntegerDecimalValues();

  const [priceTableItems, setPriceTablesItems] = useState<IPriceTableItem[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderField, setOrderField] = useState<string>('code');
  const [loading, setLoading] = useState(false);

  const [filterField, setFilterField] = useState<string>('');
  const [filterPrecision, setFilterPrecision] = useState<string>('containing');
  const [filterValue, setFilterValue] = useState<string>('');
  const [delay, setDelay] = useState(0);

  const [filterAreaOpen, setFilterAreaOpen] = useState(false);

  const handleToPriceTableItem = useCallback(
    (id: string | null) => {
      !!id ? navigate(`/price-table-item/${id}`) : navigate('/price-table-item');
    },
    [navigate],
  );

  const handlePagination = async (
    perPage: number,
    currentPage: number,
    orderField: string,
    orderDirection: 'asc' | 'desc',
  ) => {
    handleListAll({
      perPage,
      currentPage,
      orderField,
      orderDirection,
      field: filterField,
      value: filterValue,
      precision: filterPrecision,
      delay: 0,
    });
  };

  const handleListAll = async ({
    perPage,
    currentPage,
    orderField,
    orderDirection,
    field,
    value,
    precision,
    delay,
  }: IParams) => {
    let url = `perPage=${perPage}&currentPage=${
      currentPage + 1
    }&orderBy=${orderField}&orderDirection=${orderDirection}`;

    url = url + `&filterField=${field}&filterValue=${value}&precision=${precision}`;

    clearInterval(timeout.current);
    timeout.current = setTimeout(async () => {
      setLoading(true);

      await listAllPriceTableItems(priceTableId, url)
        .then(response => {
          if (response?.data?.length > 0) {
            setPriceTablesItems(response.data);
            setTotalRows(response.totalRows);
            setLoading(false);
          } else {
            setPriceTablesItems([]);
            setTotalRows(0);
            setPerPage(10);
            setCurrentPage(0);
            setLoading(false);
          }
        })
        .catch(error => {
          toastr.error(error?.message || 'Contate a equipe de suporte');
        })
        .finally(() => {
          setLoading(false);
        });
    }, delay || 0);
  };

  useEffect(() => {
    const field = filterField;
    const value = filterValue;
    const precision = filterPrecision;

    handleListAll({ perPage, currentPage, orderField, orderDirection, field, value, precision, delay });
  }, [filterField, filterPrecision, filterValue, delay]);

  const handleTextFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
    setTotalRows(0);
    setCurrentPage(0);
    setDelay(500);
  };

  return (
    <PageCard>
      <ButtonGroupFilter>
        <Tooltip title="Filtros" placement="top">
          <IconButton
            onClick={() => setFilterAreaOpen(!filterAreaOpen)}
            size="small"
            color="primary"
            style={{ alignItems: 'flex-end' }}
          >
            {filterAreaOpen ? <FilterAltOffOutlinedIcon /> : <FilterAltOutlinedIcon />}
          </IconButton>
        </Tooltip>
      </ButtonGroupFilter>

      {filterAreaOpen && (
        <Grid container spacing={2} sx={{ marginBottom: '16px', padding: '8px' }}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel id="select-filter">Filtrar por</InputLabel>
              <Select
                label="Filtrar Por"
                value={`${filterField}`}
                onChange={e => {
                  setFilterField(`${e.target.value}`);
                }}
              >
                {fields.map(filter => {
                  return (
                    <MenuItem key={filter.field} value={filter.field}>
                      {filter.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Precisão</InputLabel>
              <Select
                label="Precisão"
                value={`${filterPrecision}`}
                onChange={e => {
                  setFilterPrecision(`${e.target.value}`);
                }}
              >
                <MenuItem key={'equal'} value={'equal'}>
                  Igual
                </MenuItem>
                <MenuItem key={'containing'} value={'containing'}>
                  Contendo
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={7}>
            <TextField
              fullWidth
              label="Valor a filtrar"
              placeholder="Valor a filtrar"
              value={filterValue}
              onChange={handleTextFilterChange}
              size="small"
            />
          </Grid>
        </Grid>
      )}

      <TableContainer sx={{ marginTop: '24px', paddingLeft: '8px', paddingRight: '8px', minHeight: '250px' }}>
        {loading && <BackdropCustom />}
        <Table>
          <TableHead>
            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  borderBottom: 1,
                  borderColor: '#E5E5E5',
                },
              }}
            >
              <TableCell size="small" width={60}>
                Ações
              </TableCell>

              <CustomTableSortLabel
                perPage={perPage}
                currentPage={currentPage}
                orderField={orderField}
                field="productSku"
                label="Cód. Produto"
                handleRequest={handlePagination}
                orderDirection={orderDirection}
                setField={setOrderField}
                setDirection={setOrderDirection}
              />
              <CustomTableSortLabel
                perPage={perPage}
                currentPage={currentPage}
                orderField={orderField}
                field="productDescription"
                label="Desc. Produto"
                handleRequest={handlePagination}
                orderDirection={orderDirection}
                setField={setOrderField}
                setDirection={setOrderDirection}
              />
              <CustomTableSortLabel
                perPage={perPage}
                currentPage={currentPage}
                orderField={orderField}
                field="basePrice"
                label="Preço Base"
                handleRequest={handlePagination}
                orderDirection={orderDirection}
                setField={setOrderField}
                setDirection={setOrderDirection}
              />
              <CustomTableSortLabel
                perPage={perPage}
                currentPage={currentPage}
                orderField={orderField}
                field="isActive"
                label="Ativo"
                handleRequest={handlePagination}
                orderDirection={orderDirection}
                setField={setOrderField}
                setDirection={setOrderDirection}
              />
            </TableRow>
          </TableHead>

          {priceTableItems && priceTableItems.length > 0 && (
            <TableBody>
              {priceTableItems.map(priceTable => {
                return (
                  <TableRow
                    key={priceTable.id}
                    sx={{
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },
                    }}
                  >
                    <CustomMenuItem id={priceTable.id ? priceTable.id : ''} handleToEdit={handleToPriceTableItem} />

                    <TableCell size="small">{priceTable.product?.sku}</TableCell>
                    <TableCell size="small">{priceTable.product?.description}</TableCell>
                    <TableCell size="small">{formatIntegerDecimalValues(priceTable.basePrice, 'DECIMAL')}</TableCell>
                    <TableCell size="small">{priceTable?.isActive ? 'Sim' : 'Não'}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>

        {!priceTableItems ||
          (priceTableItems.length === 0 && <CustomTableNoContent text="Nenhum item para listar..." />)}
      </TableContainer>
      <CustomTablePagination
        totalRows={totalRows}
        currentPage={currentPage}
        perPage={perPage}
        handleRequest={handlePagination}
        setPerPage={setPerPage}
        setCurrentPage={setCurrentPage}
        orderField={orderField}
        orderDirection={orderDirection}
      />
    </PageCard>
  );
};
export { PriceTablesListItems };
