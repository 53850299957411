import { TextField } from '@mui/material';
import styled from 'styled-components';
import { LoadingButton } from '@mui/lab';

interface ILoginStyle {
  isDark: boolean;
}

export const LoginStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px ${(props: ILoginStyle) => (props.isDark ? '#121212' : '#fff')} inset !important;
    box-shadow: 0 0 0px 1000px ${(props: ILoginStyle) => (props.isDark ? '#121212' : '#fff')} inset !important;
  }
`;

export const LoginFormContainer = styled.div`
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > img {
    width: 280px;
    margin-bottom: 16px;
  }
`;

export const MainTitle = styled.div`
  font-size: 35px;
  color: var(--main-color);
`;

export const SecondaryTitle = styled.div`
  font-size: 23px;
  color: dimgrey;
  font-weight: 300;
  letter-spacing: 4px;
`;

export const AccessMessage = styled.div`
  font-size: 15px;
  color: dimgrey;
  font-weight: 100;
  margin-top: 30px;
`;

export const Form = styled.form`
  width: 50%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoginButton = styled(LoadingButton)`
  background-color: var(--main-color);
  width: 80%;
`;

export const EmailTextFiled = styled(TextField)`
  width: 80%;
  margin: 5px 0px 10px 0px !important;
  box-sizing: content-box;
`;

export const PasswordTextFiled = styled(TextField)`
  width: 80%;
  margin: 10px 0px 25px 0px !important;
  box-sizing: content-box;
`;
