import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  min-height: 400px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  font-size: 0.75rem;
  border: 1px solid #f0f0f0;
  border-radius: 8px;

  & + & {
    margin-top: 8px;
  }
`;

export const HeaderCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .order-area {
    display: flex;
  }

  .order {
    font-size: 0.875rem;
    text-decoration: none;
    color: var(--main-color);
    margin-right: 10px;
    line-height: 16px;
  }
`;

export const BodyCard = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4px;

  span + span {
    margin-top: 12px;
  }
`;

export const BodyCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const OrderStatus = styled.span`
  font-weight: bold;
  margin-left: 8px;
  border-radius: 16px;
  color: #fff;
  white-space: nowrap;
  padding: 8px;
  height: 14px;
`;
