import { Button, Dialog, DialogContentText } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Dialog)``;

export const CustonDialogContentText = styled(DialogContentText)`
  padding: 0px;
  h1 {
    margin: 0;
    padding: 0;
    font-size: 1rem;
  }

  @media (max-width: 600px) {
    max-height: 400px;
  }
`;

export const Title = styled.span`
  font-weight: bold;
  font-size: 1.075rem;
  margin-bottom: 14px;
`;

export const InvoiceCard = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 8px 0;
  min-width: 300px;
  margin-top: 24px;
  width: auto;

  > span {
    font-size: 0.85rem;
    margin-top: 16px;
  }

  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;

  & + & {
    border-top: none;
  }

  @media (min-width: 620px) {
    width: 500px;
    max-height: 500px;
  }

  @media (max-width: 620px) {
    span {
      font-size: 0.75rem;
    }
  }
`;

export const Header = styled.div`
  margin-top: 16px;
  width: 100%;
  font-size: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  strong {
    color: var(--main-color);
    font-size: 1rem;
    font-weight: bold;
  }

  > span {
    font-size: 0.75rem;
  }
`;

export const InvoiceKey = styled.div`
  font-size: 0.75rem;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const NoneInvoices = styled.div`
  padding: 36px 0;
  display: flex;
  justify-content: flex-start;
`;

export const DownloadArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 24px;
`;

export const BottonCustom = styled(Button)`
  && {
    text-transform: none;

    svg {
      margin-right: 8px;
    }

    cursor: pointer;
  }
`;

export const NoneInvoiceCard = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 8px 0;
  min-width: 300px;
  margin-top: 24px;
  width: auto;

  @media (min-width: 620px) {
    width: 500px;
    max-height: 500px;
  }
`;
