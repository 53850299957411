import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';

import { Container, Content, LogoImg, ButtonCustom, Input, TextFieldCustom } from './UserConfirmation.styles';
import logoLoginDefault from '../../../assets/logoLogin.svg';
import { useToastr } from '../../../hooks/useToastr';
import IPage from '../../../interfaces/IPage';
import { useCustomerPortalUserService } from '../../../services/useCustomerPortalUserService';
import { useOpenedRoutesService } from '../../../services/useOpenedRoutesService';
import { BackdropCustom } from '../../../components/backdrop/Backdrop';

const UserConfirmation: React.FC<IPage> = ({ title }) => {
  window.document.title = title;

  const navigate = useNavigate();
  const toastr = useToastr();
  const location = useLocation();

  const customerPortalUserService = useCustomerPortalUserService();
  const { getCustomization } = useOpenedRoutesService();

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);

  const [id] = useState(() => {
    return location.pathname.split('/')[location.pathname.split('/').length - 1];
  });
  const [tenant] = useState(() => {
    return process.env.REACT_APP_CLIENT_TENANT_DOMAIN
      ? process.env.REACT_APP_CLIENT_TENANT_DOMAIN
      : location.search.replaceAll('?tenant=', '');
  });

  const [logoLogin, setLogoLogin] = useState<string | null>(null);
  const [loadingCustom, setLoadingCustom] = useState(false);

  const handleListUser = useCallback(async () => {
    await customerPortalUserService
      .findUserCustomer(id, tenant)
      .then(response => {
        setName(response?.customerPortalUser.name);
        setEmail(response?.customerPortalUser?.email || '');
        setPhoneNumber(response?.customerPortalUser?.phoneNumber || '');
      })
      .catch(error => {
        toastr.error(error?.message || 'Contate a equipe de suporte');
      });
  }, []);

  const handleSubmit = useCallback(async (): Promise<void> => {
    setLoading(true);
    await customerPortalUserService
      .activate(id, tenant)
      .then(() => {
        setLoading(false);
        navigate(`/customer-portal/signin?tenant=${tenant}`);
      })
      .catch(error => {
        setLoading(false);
        toastr.error(error.message || 'Ocorreu um erro ao liberar o acesso ao usuário. Contate o suporte.');
      });
  }, [toastr, navigate]);

  useEffect(() => {
    handleListUser();
  }, []);

  const handleGetCustomization = useCallback(async () => {
    setLoadingCustom(true);
    await getCustomization(btoa(tenant))
      .then(customization => {
        localStorage.setItem('@PEDIDO-WEB:customization', JSON.stringify(customization));

        setLogoLogin(customization.logoLogin);
      })
      .catch(error => {
        toastr.error(`${error.message}. Contate o suporte.` || 'Erro ao obter domínio. Contate o suporte.');
      })
      .finally(() => {
        setLoadingCustom(false);
      });
  }, [tenant, toastr]);

  useEffect(() => {
    if (tenant) {
      handleGetCustomization();
    }
  }, [tenant]);

  return (
    <Container>
      {loadingCustom ? (
        <BackdropCustom />
      ) : (
        <Content elevation={5}>
          <LogoImg>
            <img src={logoLogin || logoLoginDefault} alt="Portal de Boletos" />
          </LogoImg>

          <form>
            <Input>
              <label>Permitir o acesso ao usuário?</label>
              <TextFieldCustom size="small" value={name} autoComplete="off" autoFocus id="name" disabled />

              {email && <TextFieldCustom size="small" value={email} autoComplete="off" autoFocus id="email" disabled />}

              {phoneNumber && (
                <InputMask
                  mask={'(99) 9 9999-9999'}
                  value={phoneNumber}
                  onChange={e => setPhoneNumber(e.target.value)}
                  maskPlaceholder={null}
                  disabled
                >
                  <TextFieldCustom fullWidth size="small" />
                </InputMask>
              )}
            </Input>

            <ButtonCustom onClick={handleSubmit} variant="contained" id="submit" loading={loading}>
              Permitir Acesso
            </ButtonCustom>
          </form>
        </Content>
      )}
    </Container>
  );
};

export { UserConfirmation };
