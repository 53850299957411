import { HTMLAttributes } from 'react';
import { IOrder } from '../../../interfaces/IOrder';
import { BodyCard, BodyCardInfo, Container, Content, HeaderCard, OrderStatus } from './CustomerOrders.styles';

import moment from 'moment';
import CustomTableNoContent from '../../../components/table/CustomTableNoContent/CustomTableNoContent';

interface ICustomerOrdersProps extends HTMLAttributes<HTMLDivElement> {
  orders: IOrder[];
}

const CustomerOrders: React.FC<ICustomerOrdersProps> = ({ orders }) => {
  return (
    <Container>
      {orders.length > 0 ? (
        <>
          {orders.map(order => (
            <Content key={order.id}>
              <HeaderCard>
                <div className="order-area">
                  <strong className="order">#{order.orderNumber}</strong>
                  <span>{order.seller?.name}</span>
                </div>

                <OrderStatus style={{ background: order.orderStatus?.color }}>
                  {order.orderStatus?.description}
                </OrderStatus>
              </HeaderCard>
              <BodyCard>
                <BodyCardInfo>
                  <span>{`${moment(order.orderDate).format('DD/MM/YYYY')}`}</span>
                  <span>
                    <strong>
                      {`R$ ${(order.totalValue ? order.totalValue : 0).toLocaleString('pt-BR', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                      })}`}
                    </strong>
                  </span>
                </BodyCardInfo>
              </BodyCard>
            </Content>
          ))}
        </>
      ) : (
        <CustomTableNoContent text="Nenhum pedido para listar..." />
      )}
    </Container>
  );
};

export default CustomerOrders;
