import { useLocation, useNavigate, useParams } from 'react-router-dom';
import IPage from '../../interfaces/IPage';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import { useOpenedRoutesService } from '../../services/useOpenedRoutesService';
import { Container, Content, Footer, FooterPrint, Header, ImageLink, ImageLinkFooter } from './Order.styled';
import OrderHead from './OrderHead';
import OrderContent from './OrderContent';
import OrderFooter from './OrderFooter';
import { IOrder } from '../../interfaces/IOrder';
import { IActiveImages } from '../../interfaces/IActiveImages';
import { useToastr } from '../../hooks/useToastr';
import OrderTradeConditions from './OrderTradeConditions';
import { ICustomerAddress } from '../../interfaces/ICustomerAddress';
import { Loading } from '../loading/Loading';
import IOrderParam from '../../interfaces/IOrderParam';

const Order = (props: IPage) => {
  window.document.title = props.title;

  const navigate = useNavigate();
  const location = useLocation();

  const header: HTMLElement | null = document.getElementById('header');

  if (header) {
    header.parentNode?.removeChild(header);
  }

  const params = useParams();
  const opededService = useOpenedRoutesService();
  const toastr = useToastr();

  const [tenantDomain] = useState(() => {
    return location.search.replaceAll('?token=', '');
  });
  const [isPDF, setIsPDF] = useState(false);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<IOrder | null>(null);
  const [activeImages, setActiveImages] = useState<IActiveImages | undefined>();
  const [orderParam, setOrderParam] = useState<IOrderParam>({});

  const [invoicingAddress, setInvoicingAddress] = useState<ICustomerAddress | undefined>();
  // const [deliveryAddress, setDeliveryAddress] = useState<ICustomerAddress | null>(null);
  // const [bellingyAddress, setBellingyAddress] = useState<ICustomerAddress | null>(null);

  const [os] = useState(() => {
    const toMatch = [/Macintosh/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
    return toMatch.some(toMatchItem => {
      return navigator.userAgent.match(toMatchItem);
    });
  });

  const listOrderParam = async () => {
    setLoading(true);
    opededService
      .listOrderParam(tenantDomain)
      .then(param => {
        if (!param.comments) {
          param.comments = '';
        }
        setOrderParam(param);
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDownloadPDF = useCallback(
    async (nome: string, customerCode: string) => {
      const id = params.id;

      if (location.pathname.indexOf('/pdf') > 0 && id) {
        setLoading(true);
        await opededService
          .getOrderPDF(id, tenantDomain)
          .then(base64String => {
            const arrayBuffer = Uint8Array.from(atob(base64String), c => c.charCodeAt(0)).buffer;

            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download =
              Number(nome) > 0
                ? `${nome}_${moment().format('YYYYMMDD_HHmm')}`
                : `${customerCode}_${moment().format('YYYYMMDD_HHmm')}`;

            link.click();

            URL.revokeObjectURL(link.href);

            if (!os) {
              navigate(`/order/${id}/report?token=${tenantDomain}`);
              window.location.reload();
            }
          })
          .catch(() => {
            toastr.error('Ocorreu um erro ao listar os dados da página');
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [order?.orderNumber, os],
  );

  const getOrder = async () => {
    const id = params.id;

    await opededService
      .listOrder(`${id}`, tenantDomain)
      .then(async result => {
        await listOrderParam();
        setOrder(result);
        setInvoicingAddress(result?.customer?.customerAddress.find(a => a.addressTypeId === 1));

        // setDeliveryAddress(result?.customer?.customerAddress.find(a => a.addressTypeId === 2) || null);
        // setBellingyAddress(result?.customer?.customerAddress.find(a => a.addressTypeId === 3) || null);

        await handleDownloadPDF(`${result?.orderNumber}`, `${result?.customer?.code}`);
      })
      .catch(() => {
        toastr.error('Ocorreu um erro ao listar os dados da página');
      });
  };

  const getActiveImages = async () => {
    await opededService
      .listActiveImages(tenantDomain)
      .then(result => {
        setActiveImages(result);
      })
      .catch(error => {
        toastr.error('Ocorreu um erro ao listar os dados da página');
      });
  };

  useEffect(() => {
    if (location.pathname.indexOf('/pdf') > 0) {
      setIsPDF(true);
    }

    getOrder();
    getActiveImages();
  }, []);

  return (
    <>
      {loading && <Loading title="" />}

      {!isPDF && (
        <>
          <Container>
            <Header>
              <tr>
                <th>
                  {order && <OrderHead order={order} activeImages={activeImages} invoicingAddress={invoicingAddress} />}
                </th>
              </tr>
            </Header>
            <Content>
              <tr>
                <td>
                  <hr />
                  <br />
                  {order && (
                    <OrderTradeConditions order={order} invoicingAddress={invoicingAddress} orderParams={orderParam} />
                  )}
                </td>
              </tr>
              <tr>
                <td>{order && <OrderContent order={order} />}</td>
              </tr>
              <tr>
                <td>
                  <ImageLink href={activeImages?.campaignLink} target="blank">
                    <img src={activeImages?.campaignImage} width="100%" alt="Campanha" />
                  </ImageLink>
                </td>
              </tr>
            </Content>
            <FooterPrint>
              <tr>
                <td>{activeImages && <OrderFooter activeImages={activeImages} />}</td>
              </tr>
            </FooterPrint>
          </Container>
          <Footer>{activeImages && <OrderFooter activeImages={activeImages} />}</Footer>
          <ImageLinkFooter href={activeImages?.tabloidsLink} target="blank">
            <img src={activeImages?.tabloidsImage} alt="Campanha" />
          </ImageLinkFooter>
        </>
      )}
    </>
  );
};

export default Order;
