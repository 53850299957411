import AppError from '../errors/AppError';
import IFieldConfigurations from '../interfaces/IFieldConfigurations';
import { IPaginationReturn } from '../interfaces/IPaginationReturn';

import { useRequest } from './useRequest';

const useFieldConfigurationsService = () => {
  const request = useRequest<IFieldConfigurations>();
  const path = 'field-configurations';

  const findAll = async (queryParams: string): Promise<IPaginationReturn<IFieldConfigurations[]>> => {
    return await request
      .getManyPaginated({ path: `${path}?${queryParams}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const updateFieldVisible = async ({
    id,
    fieldVisible,
  }: {
    id: string;
    fieldVisible: boolean;
  }): Promise<IFieldConfigurations> => {
    return await request
      .patch({ path: `${path}/fieldVisible/${id}`, sendAuthorization: true, body: { fieldVisible: fieldVisible } })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const updateFieldRequired = async ({
    id,
    fieldRequired,
  }: {
    id: string;
    fieldRequired: boolean;
  }): Promise<IFieldConfigurations> => {
    return await request
      .patch({ path: `${path}/fieldRequired/${id}`, sendAuthorization: true, body: { fieldRequired: fieldRequired } })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  return { findAll, updateFieldVisible, updateFieldRequired };
};

export { useFieldConfigurationsService };
