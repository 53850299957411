import styled, { css } from 'styled-components';
import LoadingButton from '@mui/lab/LoadingButton';
import { Card, TextField } from '@mui/material';

interface IContainerProps {
  textLogin?: boolean;
}

export const Container = styled.div<IContainerProps>`
  height: 100vh;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: #fff;

  @media (max-width: 400px) {
    background: #fff;
  }

  ${props =>
    props.textLogin &&
    css`
      flex-direction: column;
      justify-content: flex-start;
    `}
`;

export const BannerLogin = styled.div`
  width: 100vw;
  background: var(--main-color);
  top: 0;
  color: #fff;
  margin-bottom: 46px;
  line-height: 1.5;
  display: flex;

  p {
    padding: 8px 16px;
    text-align: center;
  }
  @media (min-width: 400px) {
    justify-content: center;
    align-items: center;

    p {
      width: 700px;
    }
  }
`;

export const Content = styled(Card)`
  width: 23.75rem;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 1.25rem;
  margin: 24px;

  form {
    margin-top: 8px;
  }

  > strong {
    font-size: 0.75rem;
    font-weight: 600;
    margin-top: 0.25rem;
  }

  @media (max-width: 900px) {
    width: 20rem;
  }
`;

export const LogoImg = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 10rem;
  }

  h2 {
    font-size: 1.1rem;
    font-weight: 400;
  }
`;

export const Input = styled.div`
  font-size: 0.75rem;
  font-weight: 600;

  > div {
    display: flex;
    justify-content: space-between;
  }

  span {
    cursor: pointer;
    color: #1876d2;

    &:hover {
      color: #0054b0;
    }
  }

  & + & {
    margin-top: 16px;
  }
`;

export const TextFieldCustom = styled(TextField)`
  && {
    width: 100%;
    margin-top: 0.35rem;
  }
`;

export const ButtonCustom = styled(LoadingButton)`
  && {
    width: 100%;
    padding: 0.51rem;
    margin-top: 1rem;
    font-weight: 700;
    text-transform: none;
  }
`;

export const SignUpLink = styled.div`
  margin: 1.5rem 0 0.5rem 0;

  strong {
    font-size: 0.75rem;
    font-weight: 600;
    margin-top: 0.25rem;
    margin-right: 0.25rem;
  }

  span {
    font-size: 0.75rem;
    font-weight: 700;
    cursor: pointer;

    color: #1876d2;

    &:hover {
      color: #0054b0;
    }
  }
`;
