import { Button, MenuItem } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div<{ useQuery: boolean }>`
  background: var(--main-color);
  width: 100%;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex: 1;

  > span {
    font-size: 1.5rem;
    color: #fff;
    font-weight: 300;
    letter-spacing: 6px;
  }

  svg {
    color: #fff;
    margin-right: 20px;
    cursor: pointer;
  }

  > img {
    width: 120px;
    padding: 4px 0;
    position: absolute;
    left: 46.8%;
  }

  @media (max-width: 940px) {
    img {
      display: ${props => (props.useQuery ? 'none' : 'inline')};
    }
  }
`;

export const SellerSelection = styled.div`
  position: absolute;
  top: 10px;
  right: 40px;
  color: white;
  @media (max-width: 300px) {
    display: none;
  }
`;

export const MenuButton = styled(Button)`
  text-transform: capitalize !important;
  margin-left: 10px !important;
  white-space: nowrap;
  overflow: hidden;
`;

export const SellerCode = styled.span`
  width: 20px;
  text-align: start;
  @media (max-width: 420px) {
    display: none;
  }
`;

export const SellerCodeHyphen = styled.span`
  margin-right: 2.5px;
  margin-left: 2.5px;
  @media (max-width: 420px) {
    display: none;
  }
`;

export const SellerName = styled.span`
  max-width: 160px;
  text-align: start;
  text-transform: uppercase;
`;

export const SalesRegion = styled.span`
  width: 70px;
  text-align: start;
  text-transform: uppercase;
  @media (max-width: 420px) {
    display: none;
  }
`;

export const SalesRegionHyphen = styled.span`
  margin-right: 2.5px;
  margin-left: 2.5px;
  @media (max-width: 420px) {
    display: none;
  }
`;

export const SellerHyphen = styled.span`
  margin-right: 2.5px;
  margin-left: 2.5px;
`;

export const SellerMenuItem = styled(MenuItem)`
  && {
    text-transform: uppercase;
  }
`;

export const MenuCode = styled.span`
  width: 30px;
  text-align: end;
`;

export const MenuItemCustom = styled(MenuItem)`
  && {
    font-size: 14px;
  }
`;
