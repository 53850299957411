import AppError from '../errors/AppError';
import { ICustomerPortalUserForgotPassword } from '../interfaces/ICustomerPortalUserForgotPassword';
import { ICustomerPortalUserResetPassword } from '../interfaces/ICustomerPortalUserResetPassword';
import { useRequest } from './useRequest';

const useCustomerPortalUserPasswordService = () => {
  const request = useRequest<void>();
  const path = 'customer-portal/password';

  const forgotPassword = async (data: ICustomerPortalUserForgotPassword): Promise<void> => {
    return await request
      .post({ path: `${path}/forgot`, body: { ...data }, sendAuthorization: false })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const resetPassword = async (data: ICustomerPortalUserResetPassword): Promise<void> => {
    return await request
      .post({ path: `${path}/reset`, body: { ...data }, sendAuthorization: false })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  return { forgotPassword, resetPassword };
};

export { useCustomerPortalUserPasswordService };
