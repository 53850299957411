import AppError from '../errors/AppError';
import { IColor } from '../interfaces/IColor';

import { useRequest } from './useRequest';

const useColorService = () => {
  const request = useRequest<IColor>();
  const path = 'colors';

  const listColor = async (): Promise<IColor> => {
    return await request
      .getOne({ path: `${path}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const updateColor = async (color: IColor): Promise<void> => {
    await request
      .put({ path: `${path}`, sendAuthorization: true, body: color })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  return { listColor, updateColor };
};

export { useColorService };
