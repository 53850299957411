import AppError from '../errors/AppError';
import { useAuth } from '../hooks/auth';
import { IPaginationReturn } from '../interfaces/IPaginationReturn';
import IReceivableAccount from '../interfaces/IReceivableAccount';
import { useRequest } from './useRequest';

const useReceivableAccountsService = () => {
  const requestsReceivableAccounts = useRequest<IReceivableAccount>();
  const pathReceivableAccounts = 'receivable-accounts';
  const { state } = useAuth();

  const listReceivableAccountByCustomerId = async (
    customerId: string,
    queryParams: string,
  ): Promise<IPaginationReturn<IReceivableAccount[]>> => {
    const response = await requestsReceivableAccounts
      .getManyPaginated({
        path: `${pathReceivableAccounts}/customer/${customerId}?${queryParams}`,
        headers: {
          Authorization: `bearer ${state.access_token}`,
        },
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });

    return response;
  };

  return { listReceivableAccountByCustomerId };
};

export { useReceivableAccountsService };
