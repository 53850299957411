function formatPhoneNumber(phoneNumber: string): string {
  let regex = /^(\d{2})(\d{5})(\d{4})$/;
  let match = phoneNumber.match(regex);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  } else {
    regex = /^(\d{2})(\d{4})(\d{4})$/;
    match = phoneNumber.match(regex);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
  }
  return phoneNumber;
}

export { formatPhoneNumber };
