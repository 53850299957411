import { IconButton } from '@mui/material';
import styled from 'styled-components';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export const IconButtonMui = styled(IconButton)`
  && {
    span {
      text-transform: none;
    }
  }
`;

export const DatePickerMui = styled(DatePicker)`
  && {
    width: 100%;
  }
`;

export const LocalizationProviderMui = styled(LocalizationProvider)``;

export const ImageContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;

  img {
    cursor: pointer;
    border-radius: 8px;

    width: 100%;

    border-style: 2px;
    border-color: transparent;

    &:hover {
      -webkit-box-shadow: 0px 0px 8px -3px rgba(0, 87, 183, 1);
      -moz-box-shadow: 0px 0px 8px -3px rgba(0, 87, 183, 1);
      box-shadow: 0px 0px 10px -3px rgba(0, 87, 183, 1);
    }

    transition: border-color 0.2s;
    transition: background-color 0.2s;
  }
`;

export const ImageInputContainer = styled.div`
  position: relative;
  padding-left: 8px;

  div {
    border-radius: 50%;
    border: solid 2px var(--main-color);
    height: 110px;
    width: 110px;
    background: #e5e5e5;
    position: relative;
    margin-left: 8px;

    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }
  }

  label {
    font-family: 'Roboto';
    font-size: 13px;
    border: none;
    background: var(--main-color);
    color: #fff;
    border-radius: 4px;

    height: 30.75px;
    max-width: 110px;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;

    cursor: pointer;

    -webkit-box-shadow: 0px 0px 35px -21px rgba(0, 0, 0, 0.61);
    -moz-box-shadow: 0px 0px 35px -21px rgba(0, 0, 0, 0.61);
    box-shadow: 0px 0px 35px -21px rgba(0, 0, 0, 0.61);

    &:hover {
      background: var(--hover-color);
    }

    input {
      display: none;
    }
  }
`;
