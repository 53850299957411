import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const IconButtonMui = styled(IconButton)`
  && {
    span {
      text-transform: none;
    }
  }
`;

export const ImageContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    cursor: pointer;

    border-radius: 4px;
    height: auto;
    width: 50px;

    border-style: 2px;
    border-color: transparent;

    &:hover {
      -webkit-box-shadow: 0px 0px 8px -3px rgba(0, 87, 183, 1);
      -moz-box-shadow: 0px 0px 8px -3px rgba(0, 87, 183, 1);
      box-shadow: 0px 0px 10px -3px rgba(0, 87, 183, 1);
    }

    transition: border-color 0.2s;
    transition: background-color 0.2s;
  }
`;
