import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import styled from 'styled-components';

export const DatePickerMui = styled(DatePicker)`
  && {
    width: 100%;
  }
`;

export const LocalizationProviderMui = styled(LocalizationProvider)``;
