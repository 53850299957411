import React, { useCallback, useEffect, useRef, useState } from 'react';
import IPage from '../../../interfaces/IPage';
import { useCustomerService } from '../../../services/useCustomerService';
import { ICustomer } from '../../../interfaces/ICustomer';
import { useToastr } from '../../../hooks/useToastr';
import { TitleContainer } from '../../../components/title-container/TitleContainer';
import { PageCard } from '../../../components/page-card/PageCard';
import {
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import CustomTableSortLabel from '../../../components/table/CustomTableSortLabel/CustomTableSortLabel';
import CustomTablePagination from '../../../components/table/CustomTablePagination/CustomTablePagination';
import useCpfCnpjFormat from '../../../util/useCpfCnpjFormat';
import { CustomMenuItem } from '../../../components/custom-menu-item/CustomMenuItem';
import { useNavigate } from 'react-router-dom';
import { LinkCustom } from './CustomerList.styled';
import { BackdropCustom } from '../../../components/backdrop/Backdrop';
import { IconButtonMui, TitleAreaContainer } from '../../product-list/ProductList.styled';
import { ElementNoContent } from '../../../components/element-no-content/ElementNoContent';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const fields = [
  {
    field: 'code',
    label: 'Código',
  },
  {
    field: 'corporateName',
    label: 'Razão Social',
  },
  {
    field: 'tradeName',
    label: 'Nome Fantasia',
  },
  {
    field: 'corporateDocument',
    label: 'CPNJ / CPF',
  },
  {
    field: 'stateRegistration',
    label: 'Inscrição Estadual',
  },
  {
    field: 'email',
    label: 'E-mail',
  },
];

interface IParams {
  perPage: number;
  currentPage: number;
  orderField: string;
  orderDirection: 'asc' | 'desc';
  field?: string;
  value?: string;
  precision?: string;
  delay?: number;
}

const CustomerList: React.FC<IPage> = ({ title }) => {
  window.document.title = title;
  const { listAll } = useCustomerService();
  const toastr = useToastr();
  const { formatCnpj } = useCpfCnpjFormat();
  const navigate = useNavigate();

  const timeout = useRef<any>(null);
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderField, setOrderField] = useState<string>('tradeName');
  const [loading, setLoading] = useState(false);

  const [filterField, setFilterField] = useState<string>('');
  const [filterPrecision, setFilterPrecision] = useState<string>('');
  const [filterValue, setFilterValue] = useState<string>('');
  const [delay, setDelay] = useState(0);

  const handleToCustomers = useCallback(
    (id: string | null) => {
      !!id ? navigate(`/customer-details/${id}`) : navigate('/customer-details');
    },
    [navigate],
  );

  const handlePagination = async (
    perPage: number,
    currentPage: number,
    orderField: string,
    orderDirection: 'asc' | 'desc',
  ) => {
    handleListAll({
      perPage,
      currentPage,
      orderField,
      orderDirection,
      field: filterField,
      value: filterValue,
      precision: filterPrecision,
      delay: 0,
    });
  };

  const handleListAll = async ({
    perPage,
    currentPage,
    orderField,
    orderDirection,
    field,
    value,
    precision,
    delay,
  }: IParams) => {
    let url = `perPage=${perPage}&currentPage=${
      currentPage + 1
    }&orderBy=${orderField}&orderDirection=${orderDirection}`;

    if (field && value && precision) {
      url = url + `&filterField=${field}&filterValue=${value}&precision=${precision}`;
    }

    clearInterval(timeout.current);
    timeout.current = setTimeout(async () => {
      setLoading(true);

      await listAll(url)
        .then(response => {
          if (response?.data?.length > 0) {
            setCustomers(response.data);
            setTotalRows(response.totalRows);
            setLoading(false);
          } else {
            setCustomers([]);
            setTotalRows(0);
            setPerPage(10);
            setCurrentPage(0);
            setLoading(false);
          }
        })
        .catch(error => {
          toastr.error(error?.message || 'Contate a equipe de suporte');
        })
        .finally(() => {
          setLoading(false);
        });
    }, delay || 0);
  };

  const handleToCustomerCreate = useCallback(
    (id: string | null) => {
      !!id ? navigate(`/customers/${id}`) : navigate('/customers');
    },
    [navigate],
  );

  useEffect(() => {
    const field = filterField;
    const value = filterValue;
    const precision = filterPrecision;

    handleListAll({ perPage, currentPage, orderField, orderDirection, field, value, precision, delay });
  }, [filterField, filterPrecision, filterValue, delay]);

  const handleTextFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
    setTotalRows(0);
    setCurrentPage(0);
    setDelay(500);
  };

  return (
    <>
      <TitleContainer>
        <h1>Clientes</h1>
      </TitleContainer>
      <PageCard>
        <TitleAreaContainer>
          <ElementNoContent />
          <ButtonGroup>
            <IconButtonMui
              onClick={() => handleToCustomerCreate(null)}
              size="small"
              color="primary"
              style={{ marginBottom: '16px' }}
            >
              <AddOutlinedIcon />
            </IconButtonMui>
          </ButtonGroup>
        </TitleAreaContainer>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel id="select-filter">Filtrar por</InputLabel>
              <Select
                label="Filtrar Por"
                value={`${filterField}`}
                onChange={e => {
                  setFilterField(`${e.target.value}`);
                }}
              >
                {fields.map(filter => {
                  return (
                    <MenuItem key={filter.field} value={filter.field}>
                      {filter.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Precisão</InputLabel>
              <Select
                label="Precisão"
                value={`${filterPrecision}`}
                onChange={e => {
                  setFilterPrecision(`${e.target.value}`);
                }}
              >
                <MenuItem key={'equal'} value={'equal'}>
                  Igual
                </MenuItem>
                <MenuItem key={'containing'} value={'containing'}>
                  Contendo
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={7}>
            <TextField
              fullWidth
              label="Valor a filtrar"
              placeholder="Valor a filtrar"
              value={filterValue}
              onChange={handleTextFilterChange}
              size="small"
            />
          </Grid>
        </Grid>
        <TableContainer sx={{ marginTop: '2px', paddingLeft: '8px', paddingRight: '8px' }}>
          {loading && <BackdropCustom />}
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': {
                    borderBottom: 1,
                    borderColor: '#E5E5E5',
                  },
                }}
              >
                <TableCell size="small" width={60}>
                  Ações
                </TableCell>

                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="code"
                  label="Código"
                  orderDirection={orderDirection}
                  handleRequest={handlePagination}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="corporateName"
                  label="Razão Social"
                  handleRequest={handlePagination}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="tradeName"
                  label="Fantasia"
                  handleRequest={handlePagination}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="corporateDocument"
                  label="CPF / CNPJ"
                  handleRequest={handlePagination}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="stateRegistration"
                  label="Inscrição Estadual"
                  handleRequest={handlePagination}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="email"
                  label="E-mail"
                  handleRequest={handlePagination}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <TableCell>Estado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers &&
                customers.map(customer => {
                  return (
                    <TableRow
                      key={customer.id}
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}
                    >
                      <CustomMenuItem
                        id={customer.id ? customer.id : ''}
                        handleToView={handleToCustomers}
                        handleToEdit={handleToCustomerCreate}
                      />

                      <TableCell size="small">
                        <LinkCustom to={`/customer-details/${customer.id}`}>{customer.code}</LinkCustom>
                      </TableCell>
                      <TableCell size="small">{customer.corporateName}</TableCell>
                      <TableCell size="small">{customer.tradeName}</TableCell>
                      <TableCell size="small">{formatCnpj(`${customer.corporateDocument}`)}</TableCell>
                      <TableCell size="small">{customer.stateRegistration}</TableCell>
                      <TableCell size="small">{customer.email}</TableCell>
                      <TableCell size="small">{`${
                        customer?.customerAddress.find(a => a.addressTypeId === 1)?.state
                      }`}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <CustomTablePagination
            totalRows={totalRows}
            currentPage={currentPage}
            perPage={perPage}
            handleRequest={handlePagination}
            setPerPage={setPerPage}
            setCurrentPage={setCurrentPage}
            orderField={orderField}
            orderDirection={orderDirection}
          />
        </TableContainer>
      </PageCard>
    </>
  );
};

export default CustomerList;
