import { HTMLAttributes, useCallback, useState } from 'react';
import moment from 'moment';
import { BsFiletypeXml } from 'react-icons/bs';
import { BsFiletypePdf } from 'react-icons/bs';

import {
  BodyCard,
  BodyCardInfo,
  BottonCustom,
  Container,
  Content,
  DownloadArea,
  HeaderCard,
} from './CustomerInvoices.styles';

import IInvoice from '../../../interfaces/IInvoice';
import CustomTableNoContent from '../../../components/table/CustomTableNoContent/CustomTableNoContent';

interface ICustomerInvoicesProps extends HTMLAttributes<HTMLDivElement> {
  invoices: IInvoice[];
}

const CustomerInvoices: React.FC<ICustomerInvoicesProps> = ({ invoices }) => {
  const [os] = useState(() => {
    const toMatch = [/Macintosh/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
    return toMatch.some(toMatchItem => {
      return navigator.userAgent.match(toMatchItem);
    });
  });

  const downloadPDF = useCallback(
    (file: string | null, invoiceNumber: number) => {
      // Obter o base64 do arquivo PDF do estado ou de onde você o armazena
      const base64PDF = file;

      if (base64PDF) {
        // Converter o base64 em blob
        const byteCharacters = atob(base64PDF);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        // Criar um URL do blob e realizar o download
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        if (os) {
          link.target = '_blank';
          link.click();
        } else {
          link.download = `${invoiceNumber}.pdf`;
          link.click();
        }

        // Limpar o URL do blob após o download
        URL.revokeObjectURL(url);
      }
    },
    [os],
  );

  const downloadXML = useCallback((file: string | null, invoiceNumber: number) => {
    // Obter o base64 do arquivo XML do estado ou de onde você o armazena
    const base64XML = file;

    if (base64XML) {
      // Converter o base64 em blob
      const byteCharacters = atob(base64XML);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/xml' });

      // Criar um URL do blob e realizar o download
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      link.download = `${invoiceNumber}.xml`;
      link.click();

      // Limpar o URL do blob após o download
      URL.revokeObjectURL(url);
    }
  }, []);

  return (
    <Container>
      {invoices.length > 0 ? (
        <>
          {invoices.map(invoice => (
            <Content key={invoice.id}>
              <div>
                <HeaderCard>
                  <div className="invoice-area">
                    <strong className="invoice">{invoice.number}</strong>
                  </div>
                </HeaderCard>
                <BodyCard>
                  <BodyCardInfo>
                    <span>{`${moment(invoice.emissionDate).format('DD/MM/YYYY')}`}</span>
                    <span>
                      <strong>
                        {`R$ ${(invoice.netValue ? invoice.netValue : 0).toLocaleString('pt-BR', {
                          style: 'decimal',
                          minimumFractionDigits: 2,
                        })}`}
                      </strong>
                    </span>
                  </BodyCardInfo>
                </BodyCard>
              </div>

              <DownloadArea>
                {invoice.pdf && (
                  <BottonCustom
                    onClick={() => {
                      downloadPDF(invoice.pdf, invoice.number);
                    }}
                  >
                    <BsFiletypePdf size={24} />
                  </BottonCustom>
                )}

                {invoice.xml && (
                  <BottonCustom
                    onClick={() => {
                      downloadXML(invoice.xml, invoice.number);
                    }}
                  >
                    <BsFiletypeXml size={24} />
                  </BottonCustom>
                )}
              </DownloadArea>
            </Content>
          ))}
        </>
      ) : (
        <CustomTableNoContent text="Nenhum documento para listar..." />
      )}
    </Container>
  );
};

export default CustomerInvoices;
