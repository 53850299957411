import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Button } from '../../../components/button/Button';
import { useState } from 'react';
import IFormError from '../../../interfaces/IFormError';
import { ICustomerAddress } from '../../../interfaces/ICustomerAddress';
import { useCustomerAddressService } from '../../../services/useCustomerAddressService';
import * as yup from 'yup';
import { TitleContainer } from '../../../components/title-container/TitleContainer';
import { PageCard } from '../../../components/page-card/PageCard';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { IAddressType } from '../../../interfaces/IAddressType';
import InputMask from 'react-input-mask';
import { useValidateSchema } from '../../../services/useValidateSchema';
import { BackdropCustom } from '../../../components/backdrop/Backdrop';
import { Form } from '../../../components/form/Form';
import { useToastr } from '../../../hooks/useToastr';

interface IProps {
  open: boolean;
  closeDialog: () => void;
  id: string;
  customerId: string;
}

const CustomerAddressFormDialog = ({ open, closeDialog, id, customerId }: IProps) => {
  const customerAddressService = useCustomerAddressService();

  const validateSchema = useValidateSchema();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<IFormError>({});
  const toastr = useToastr();

  const [addressTypeId, setAddressTypeId] = useState(1);
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');

  const [addresTypes, setAddresTypes] = useState<IAddressType[]>([]);

  const listCustomerAddress = async (id: string) => {
    setLoading(true);

    console.log('function ', id);

    await customerAddressService
      .listById(id)
      .then(customerAddress => {
        setAddressTypeId(1);
        setStreet(customerAddress?.street ?? '');
        setNumber(customerAddress?.number ?? '');
        setComplement(customerAddress?.complement ?? '');
        setNeighborhood(customerAddress?.state ?? '');
        setCity(customerAddress?.city ?? '');
        setState(customerAddress?.state ?? '');
        setPostalCode(customerAddress?.postalCode ?? '');
        setCountry(customerAddress?.country ?? '');
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createCustomer = async (customer: ICustomerAddress) => {
    setLoading(true);
    customer.customerId = customerId;

    await customerAddressService
      .create(customer)
      .then(() => {
        toastr.success('Endereço adicionado com sucesso');
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateCustomer = async (customerAddress: ICustomerAddress) => {
    setLoading(true);
    customerAddress.customerId = customerId;
    await customerAddressService
      .update(`${id}`, customerAddress)
      .then(() => {
        toastr.success('Endereço atualizado com sucesso');
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const listAddressTypes = async () => {
    setLoading(true);

    await customerAddressService
      .listAllAddressTypes()
      .then(addressType => {
        setAddresTypes(addressType.data);
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const schema = yup.object({
    addressTypeId: yup.string().required('Campo obrigatório'),
    street: yup.string().required('Campo obrigatório'),
    number: yup.string().required('Campo obrigatório'),
    neighborhood: yup.string().required('Campo obrigatório'),
    city: yup.string().required('Campo obrigatório'),
    state: yup.string().required('Campo obrigatório'),
    postalCode: yup.string().required('Campo obrigatório'),
    country: yup.string().required('Campo obrigatório'),
  });

  const handleSubmit = async () => {
    const customer: ICustomerAddress = {
      addressTypeId,
      street,
      number,
      complement,
      neighborhood,
      city,
      state,
      postalCode,
      country,
    };

    const result = await validateSchema.validate(schema, customer);

    if (!result.isValid) {
      setErrors(result.errors ? result.errors : {});
      return;
    }

    if (id) {
      await updateCustomer(customer);
    } else {
      await createCustomer(customer);
    }

    closeDialog();
  };

  React.useEffect(() => {
    if (id) {
      listCustomerAddress(id);
    }
    listAddressTypes();
  }, [id]);

  return (
    <React.Fragment>
      <Dialog open={open}>
        <DialogContent>
          <TitleContainer>
            <h1>Endereço do cliente</h1>
          </TitleContainer>
          <PageCard>
            <Form>
              {loading && <BackdropCustom />}
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} sm={6}>
                  <FormControl sx={{ width: '100%' }} size="small">
                    <InputLabel id="select-filter">Tipo de endereço</InputLabel>
                    <Select
                      label="Tipo de endereço"
                      value={addressTypeId}
                      onChange={e => {
                        setAddressTypeId(Number(e.target.value));
                      }}
                    >
                      {addresTypes.map(addresType => {
                        return (
                          <MenuItem key={addresType.id} value={addresType.id}>
                            {`${addresType.id} - ${addresType.description}`}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <TextField
                    sx={{ width: '100%' }}
                    size="small"
                    label="Rua"
                    value={street}
                    onChange={e => setStreet(e.target.value)}
                    autoComplete="off"
                    helperText={errors.street}
                    error={!!errors.street}
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <TextField
                    sx={{ width: '100%' }}
                    size="small"
                    label="Número"
                    value={number}
                    onChange={e => setNumber(e.target.value)}
                    autoComplete="off"
                    helperText={errors.number}
                    error={!!errors.number}
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <TextField
                    sx={{ width: '100%' }}
                    size="small"
                    label="Complemento"
                    value={complement}
                    onChange={e => setComplement(e.target.value)}
                    autoComplete="off"
                    helperText={errors.complement}
                    error={!!errors.complement}
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <TextField
                    sx={{ width: '100%' }}
                    size="small"
                    label="Bairro"
                    value={neighborhood}
                    onChange={e => setNeighborhood(e.target.value)}
                    autoComplete="off"
                    helperText={errors.neighborhood}
                    error={!!errors.neighborhood}
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <TextField
                    sx={{ width: '100%' }}
                    size="small"
                    label="Cidade"
                    value={city}
                    onChange={e => setCity(e.target.value)}
                    autoComplete="off"
                    helperText={errors.city}
                    error={!!errors.city}
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <TextField
                    sx={{ width: '100%' }}
                    size="small"
                    label="Estado"
                    value={state}
                    onChange={e => setState(e.target.value)}
                    autoComplete="off"
                    helperText={errors.state}
                    error={!!errors.state}
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <InputMask
                    mask={'99999-999'}
                    value={postalCode}
                    onChange={e => setPostalCode(e.target.value)}
                    maskPlaceholder={'99999-999'}
                  >
                    <TextField
                      sx={{ width: '100%' }}
                      fullWidth
                      size="small"
                      label="CEP"
                      autoComplete="off"
                      helperText={errors.postalCode}
                      error={!!errors.postalCode}
                    />
                  </InputMask>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <TextField
                    sx={{ width: '100%' }}
                    size="small"
                    label="País"
                    value={country}
                    onChange={e => setCountry(e.target.value)}
                    autoComplete="off"
                    helperText={errors.country}
                    error={!!errors.country}
                  />
                </Grid>
              </Grid>

              <DialogActions>
                <Button onClick={() => closeDialog()}>Cancelar</Button>
                <Button onClick={() => handleSubmit()}>Confirmar</Button>
              </DialogActions>
            </Form>
          </PageCard>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default CustomerAddressFormDialog;
