import React, { useEffect, useState } from 'react';

import { IOrder } from '../../../interfaces/IOrder';
import { IEvent } from '../../../interfaces/IEvent';

import {
  BodyCard,
  Container,
  CollapseStyled,
  HeaderCard,
  OrderStatus,
  Content,
  ButtonStyled,
  BoxStyled,
  Date,
  BodyCardInfo,
  BodyCardActions,
  BodyActionsCustom,
} from './OrderCard.styled';
import { Tooltip } from '@mui/material';
import { FiEdit, FiPrinter, FiFileText } from 'react-icons/fi';
import { FaRegClone } from 'react-icons/fa';
import moment from 'moment';
import { OrderEventsDialog } from '../order-events-dialog/OrderEventsDialog';
import { OrderInvoicesDialog } from '../order-invoicess-dialog/OrderInvoicesDialog';

interface OrderCardProps {
  order: IOrder;
  handleReturnError: (events: IEvent[] | undefined) => string;
  handleViewOrder: (orderId: string | undefined) => void;
  handleToEdit: (id: string | undefined) => void;
  handleCloneOrder: (id: string | undefined) => void;
}

const OrderCard: React.FC<OrderCardProps> = ({ order, handleViewOrder, handleToEdit, handleCloneOrder }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [dialogEventsOpen, setDialogEventsOpen] = useState(false);
  const [dialogInvoicesOpen, setDialogInvoicesOpen] = useState(false);
  const [orderId, setOrderId] = useState('');

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleCloseDialogEvents = () => {
    setDialogEventsOpen(false);
  };

  const handleOpenDialogEvents = () => {
    setDialogEventsOpen(true);
  };

  const handleCloseDialogInvoices = () => {
    setDialogInvoicesOpen(false);
  };

  const handleOpenDialogInvoices = () => {
    setDialogInvoicesOpen(true);
  };

  useEffect(() => {
    setIsCollapsed(false);
  }, [order]);

  return (
    <Container>
      <Content>
        <HeaderCard>
          <div className="order-client-name-area" onClick={handleCollapseToggle}>
            <strong className="order">#{order.orderNumber}</strong>
            <Tooltip title={`${order.customer?.tradeName} - ${order.customer?.corporateName}`} placement="top">
              <strong className="client-name">{`${order.customer?.tradeName} - ${order.customer?.corporateName}`}</strong>
            </Tooltip>
          </div>

          <div>
            <OrderStatus
              style={{ background: order.orderStatus?.color }}
              onClick={() => {
                order.id && setOrderId(order.id);
                handleOpenDialogEvents();
              }}
            >
              {order.orderStatus?.description}
            </OrderStatus>
          </div>
        </HeaderCard>
        <BodyCard onClick={handleCollapseToggle}>
          <BodyCardInfo>
            <span>{order.seller?.name}</span>
            <Date>{`${moment(order.orderDate).format('DD/MM/YYYY')}`}</Date>
            <span>
              <strong>
                {`R$ ${(order.totalValue ? order.totalValue : 0).toLocaleString('pt-BR', {
                  style: 'decimal',
                  minimumFractionDigits: 2,
                })}`}
              </strong>
            </span>
          </BodyCardInfo>

          <BodyCardActions>
            <BodyActionsCustom title="Imprimir" onClick={() => handleViewOrder(order.id)}>
              <FiPrinter size={18} />
            </BodyActionsCustom>

            <BodyActionsCustom title="Editar" onClick={() => handleToEdit(order.id)}>
              <FiEdit size={18} />
            </BodyActionsCustom>

            <BodyActionsCustom
              disabled={!order.invoices || order.invoices.length < 1}
              title="Documentos Eletrônicos"
              onClick={() => {
                order.id && setOrderId(order.id);
                handleOpenDialogInvoices();
              }}
            >
              <FiFileText size={18} />
            </BodyActionsCustom>

            <BodyActionsCustom title="Duplicar" onClick={() => handleCloneOrder(order.id)}>
              <FaRegClone size={18} />
            </BodyActionsCustom>
          </BodyCardActions>
        </BodyCard>
      </Content>
      <CollapseStyled in={isCollapsed}>
        <BoxStyled mt={2}>
          <ButtonStyled title="Imprimir" onClick={() => handleViewOrder(order.id)}>
            <FiPrinter size={24} />
          </ButtonStyled>
          <ButtonStyled title="Editar" onClick={() => handleToEdit(order.id)}>
            <FiEdit size={24} />
          </ButtonStyled>
          <ButtonStyled
            disabled={!order.invoices || order.invoices.length < 1}
            title="Documentos Eletrônicos"
            onClick={() => {
              order.id && setOrderId(order.id);
              handleOpenDialogInvoices();
            }}
          >
            <FiFileText size={24} />
          </ButtonStyled>
          <ButtonStyled title="Duplicar" onClick={() => handleCloneOrder(order.id)}>
            <FaRegClone size={24} />
          </ButtonStyled>
        </BoxStyled>
      </CollapseStyled>

      <OrderEventsDialog
        dialogOpen={dialogEventsOpen}
        handleOpenDialog={handleOpenDialogEvents}
        handleCloseDialog={handleCloseDialogEvents}
        orderId={orderId}
      />

      <OrderInvoicesDialog
        dialogOpen={dialogInvoicesOpen}
        handleOpenDialog={handleOpenDialogInvoices}
        handleCloseDialog={handleCloseDialogInvoices}
        orderId={orderId}
      />
    </Container>
  );
};

export default OrderCard;
