import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { ICustomer } from '../../interfaces/ICustomer';
import { useCustomerService } from '../../services/useCustomerService';
import { useToastr } from '../../hooks/useToastr';
import IFormError from '../../interfaces/IFormError';
import { useValidateSchema } from '../../services/useValidateSchema';
import * as yup from 'yup';
import { IOrder } from '../../interfaces/IOrder';
import { useOrderService } from '../../services/useOrderService';
import AsyncSearch, { IOption } from '../../components/async-search/AsyncSearch';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import validateUuId from '../../util/validateUuid';
import moment from 'moment';
import IOrderCustomerFormProps from './interfaces/IOrderCustomerFormProps';
import { useAuth } from '../../hooks/auth';
import { ISeller } from '../../interfaces/ISeller';
import { useSellersService } from '../../services/useSellersService';
import { IBranchOffice } from '../../interfaces/IBranchOffice';
import { useOrderParamsService } from '../../services/useOrderParamsService';
import { Button, TextField } from '@mui/material';
import CustomerDialogForm from './CustomerDialogForm';
import { useHubSpotService } from '../../services/useHubSpotService';
import { BackdropCustom } from '../../components/backdrop/Backdrop';
import { ButtonCustom } from '../../components/confirmation-area/ConfirmationArea.styled';

interface IFormErrorResult {
  errors: IFormError | null;
  isValid: boolean;
}

const OrderCustomerForm = forwardRef<IOrderCustomerFormProps, {}>((props, ref) => {
  const timeout = useRef<any>(0);

  const customerService = useCustomerService();
  const sellerService = useSellersService();
  const orderService = useOrderService();
  const orderParamsService = useOrderParamsService();
  const toastr = useToastr();
  const validation = useValidateSchema();
  const navigate = useNavigate();
  const params = useParams();
  const { validateHubSpotDealId } = useHubSpotService();
  const location = useLocation();

  const { state } = useAuth();

  useImperativeHandle(ref, () => ({
    listOrder,
    async save() {
      return handleSave();
    },
  }));

  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = React.useState<ICustomer[]>([]);
  const [customerOptions, setCustomerOptions] = React.useState<IOption[]>([]);
  const [customerOption, setCustomerOption] = React.useState<IOption | null>(null);
  const [isCustomerDialogOpen, setIsCustomerDialogOpen] = useState(false);

  const [branchOffice, setBranchOffice] = React.useState<IBranchOffice | null>(null);

  const [errors, setErrors] = useState<IFormError | null>();
  const [perPage] = useState(50);
  const [currentPage] = useState(0);
  const [orderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderField] = useState<string>('corporateName');
  const [customer, setCustomer] = useState<ICustomer | null>(null);
  const [seller, setSeller] = useState<ISeller | null>(null);
  const [sellers, setSellers] = React.useState<ISeller[]>([]);
  const [errorsSeller, setErrorsSeller] = useState<IFormError | null>();
  const [sellerOptions, setSellerOptions] = React.useState<IOption[]>([]);
  const [sellerOption, setSellerOption] = React.useState<IOption | null>(null);
  const [order, setOrder] = useState<IOrder | null>(null);
  const [integrateHubSpot, setIntegrateHubSpot] = useState(false);
  const [hubSpotId, setHubSpotId] = useState('');
  const [errorsHubSport, setErrorsHubSport] = useState<IFormError | null>();
  const [enableProspect, setEnableProspect] = useState(false);
  const [orderParamsLoading, setOrderParamsLoading] = useState(false);
  const [validateHubSpotDealIdLoading, setValidateHubSpotDealIdLoading] = useState(false);
  const [isNew] = useState(() => {
    return location.pathname.includes('/new');
  });

  React.useEffect(() => {
    const id = params.id;

    if (id && validateUuId(id)) {
      listOrder(id);
    }
  }, []);

  React.useEffect(() => {
    listOrderParams();
    listCustomers();
    listSellers();
  }, [customer, seller, params.id]);

  const adminResource = state.resources.find(value => value === 'administrador-pedido-web');

  const schemaHubSpot = yup.object({
    hubSpotId: yup.string().required('Campo obrigatório').min(1, 'Campo obrigatório.'),
  });

  const schemaSeller = yup.object({
    name: yup.string().required('Campo obrigatório').min(1, 'Campo obrigatório.'),
  });

  const schema = yup.object({
    corporateDocument: yup.string().required('Campo obrigatório').min(1, 'Campo obrigatório.'),
  });

  const handleSave = async () => {
    if (params.id && validateUuId(params.id)) {
      return await updateOrder();
    } else {
      return await createOrder();
    }
  };

  const listOrderParams = async () => {
    setOrderParamsLoading(true);
    await orderParamsService
      .list()
      .then(param => {
        setBranchOffice(param?.defaultBranchOffice || null);
        setEnableProspect(!!param.enableProspect);
        setIntegrateHubSpot(param.integrateHubSpot || false);
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setOrderParamsLoading(false);
      });
  };

  const updateOrder = async () => {
    const resultCustomer = await validation.validate(schema, customer || {});
    setErrors(resultCustomer.errors);

    const resultSeller = await validation.validate(schemaSeller, seller || {});
    setErrorsSeller(resultSeller.errors);

    if (!adminResource) {
      resultSeller.isValid = true;
    }

    if (resultCustomer.isValid && resultSeller.isValid) {
      const sellerId = customer?.sellers?.find(() => true)?.id || state.seller?.id || seller?.id;

      if (!branchOffice?.id) {
        toastr.error('Não foi localizada uma filial para incluão do pedido.');
        return false;
      }

      if (!sellerId) {
        toastr.error('Cliente não possui um representante vinculado! Por favor revise os cadastros.');
        return false;
      }

      const order: IOrder = {
        id: params.id,
        branchOfficeId: branchOffice.id,
        orderNumber: '0',
        orderTypeId: 1, // pedido
        customerId: `${customer?.id}`,
        sellerId,
      };

      setLoading(true);
      return await orderService
        .updateOrder(`${order.id}`, order)
        .then(result => {
          return true;
        })
        .catch(error => {
          toastr.error(error.message);
          return false;
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      return false;
    }
  };

  const createOrder = async () => {
    const resultCustomer = await validation.validate(schema, customer || {});
    setErrors(resultCustomer.errors);

    const resultSeller = await validation.validate(schemaSeller, seller || {});
    setErrorsSeller(resultSeller.errors);

    let resultHubSpot: IFormErrorResult | null = null;
    if (integrateHubSpot) {
      const data = { hubSpotId };
      resultHubSpot = await validation.validate(schemaHubSpot, data || {});

      setErrorsHubSport(resultHubSpot.errors);
    }

    if (!adminResource) {
      resultSeller.isValid = true;
    }

    if (resultCustomer.isValid && resultSeller.isValid && (resultHubSpot?.isValid || !resultHubSpot)) {
      const sellerId = customer?.sellers?.find(() => true)?.id || state.seller?.id || seller?.id;
      if (!branchOffice?.id) {
        toastr.error('Não foi localizada uma filial para incluão do pedido.');
        return false;
      }

      if (!sellerId) {
        toastr.error('Cliente não possui um representante vinculado! Por favor revise os cadastros.');
        return false;
      }

      const order: IOrder = {
        orderDate: moment().format('yyyy-MM-DD'),
        branchOfficeId: branchOffice.id,
        orderNumber: '0',
        orderTypeId: 1, // pedido
        orderStatusId: 1, // orçamento
        customerId: `${customer?.id}`,
        sellerId,
        hubSpotId,
      };

      setLoading(true);
      return await orderService
        .createOrder(order)
        .then(result => {
          navigate(`/order/steper/${result.id}/edit`);
          return true;
        })
        .catch(error => {
          toastr.error(error.message);
          return false;
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      return false;
    }
  };

  const listOrder = async (id: string) => {
    setLoading(true);
    await orderService
      .listOrder(id, true)
      .then(result => {
        result && setOrder({ ...result });
        if (result && result.customer && result.seller) {
          setCustomer(result.customer);
          setCustomerOption(customerToOption(result.customer));

          setSeller(result.seller);
          setSellerOption(sellerToOption(result.seller));

          setHubSpotId(result.hubSpotId || '');
        }
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSetSeller = (option: IOption | null) => {
    if (option) {
      setSellerOption(option);
      const selected = sellers.find(customer => {
        return customer.id === option.value;
      });
      if (selected?.code) {
        setSeller(selected);
      } else {
        setSeller(null);
      }
    }
  };

  const sellerToOption = (seller: ISeller): IOption => {
    return {
      value: `${seller.id}`,
      description: `${seller.code} ${seller.name}`,
    };
  };

  const listSellers = async (filter = '') => {
    clearInterval(timeout.current);
    timeout.current = setTimeout(async () => {
      setLoading(true);
      const url = `perPage=100&currentPage=1&orderBy=name&orderDirection=asc&filter=${filter}`;

      await sellerService
        .listDynamically(url)
        .then(result => {
          if (result) {
            setSellers(result.data);
            const options: IOption[] = result.data.map(sellerToOption);
            const isSallerOnArray = options.find(opt => opt.value === seller?.id);
            if (seller?.id && !isSallerOnArray) {
              options.push(sellerToOption(seller));
            }
            setSellerOptions(options);
          } else {
            setSellerOptions([]);
          }
        })
        .catch(error => {
          setSellerOptions([]);
          toastr.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }, timeout.current);
  };

  const handleSetCustomer = (option: IOption | null) => {
    if (option) {
      setCustomerOption(option);
      const selected = customers.find(customer => {
        return customer.id === option.value;
      });
      if (selected?.id) {
        setCustomer(selected);
      } else {
        setCustomer(null);
      }
    }
  };

  const customerToOption = (customer: ICustomer): IOption => {
    let description = `${customer.code} ${customer.corporateName} ${customer.corporateDocument}`;
    if (customer.isProspect) {
      description = `(Prospect) ${customer.corporateName} ${customer.corporateDocument}`;
    }
    return {
      value: `${customer.id}`,
      description,
    };
  };

  const listCustomers = async (filter = '') => {
    clearInterval(timeout.current);
    timeout.current = setTimeout(async () => {
      setLoading(true);
      const url = `perPage=${perPage}&currentPage=${
        currentPage + 1
      }&orderBy=${orderField}&orderDirection=${orderDirection}&filter=${filter}`;

      await customerService
        .listDynamically(url)
        .then(result => {
          if (result) {
            setCustomers(result.data);
            const options: IOption[] = result.data.map(customerToOption);
            const isCustomerOnArray = options.find(opt => opt.value === customer?.id);
            if (customer?.id && !isCustomerOnArray) {
              options.push(customerToOption(customer));
            }
            setCustomerOptions(options);
          } else {
            setCustomerOptions([]);
          }
        })
        .catch(error => {
          setCustomerOptions([]);
          toastr.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }, timeout.current);
  };

  const handleValidateHubSpotDealId = async () => {
    let resultHubSpot: IFormErrorResult | null = null;
    setErrorsHubSport(null);

    const data = { hubSpotId };
    resultHubSpot = await validation.validate(schemaHubSpot, data || {});

    if (!resultHubSpot.isValid) {
      setErrorsHubSport(resultHubSpot.errors);
      return;
    }

    setValidateHubSpotDealIdLoading(true);
    await validateHubSpotDealId(hubSpotId)
      .then(response => {
        setCustomer(response.customer);
        setSeller(response.seller);
      })
      .catch(error => {
        toastr.error(error.message || 'Erro ao consultar pedido no HUB Spot, contate o suporte.');
      })
      .finally(() => {
        setValidateHubSpotDealIdLoading(false);
      });
  };

  const customerCreated = (customerParam: ICustomer) => {
    setCustomer({ ...customerParam });
    setCustomerOption(customerToOption(customerParam));
    setIsCustomerDialogOpen(false);
  };

  return (
    <>
      {orderParamsLoading && <BackdropCustom />}

      <>
        {validateHubSpotDealIdLoading && <BackdropCustom />}
        {integrateHubSpot ? (
          <>
            <div style={{ display: 'flex', marginBottom: '8px' }}>
              <TextField
                sx={{ width: '100%' }}
                size="small"
                label="HUB Spot ID"
                value={hubSpotId}
                onChange={e => setHubSpotId(e.target.value)}
                autoComplete="off"
                error={Boolean(errorsHubSport?.hubSpotId)}
                helperText={errorsHubSport?.hubSpotId}
                disabled={!!order?.id}
              />

              {isNew && (
                <ButtonCustom
                  variant="contained"
                  size="small"
                  sx={{ height: '35px', marginLeft: '8px' }}
                  onClick={handleValidateHubSpotDealId}
                >
                  Pesquisar
                </ButtonCustom>
              )}
            </div>

            {seller && customer && (
              <>
                {adminResource && (
                  <TextField
                    size="small"
                    value={seller.name}
                    autoComplete="off"
                    disabled
                    sx={{ width: '100%', marginBottom: '8px' }}
                  />
                )}
                <TextField
                  size="small"
                  value={customer.isProspect ? `(Prospect) ${customer.corporateName}` : customer.corporateName}
                  autoComplete="off"
                  disabled
                  sx={{ width: '100%', marginBottom: '8px' }}
                />
              </>
            )}
          </>
        ) : (
          <>
            {adminResource ? (
              <>
                <AsyncSearch
                  options={sellerOptions}
                  setOptions={setSellerOptions}
                  option={sellerOption}
                  setOption={handleSetSeller}
                  asyncSearch={listSellers}
                  loading={loading}
                  error={Boolean(errorsSeller?.name)}
                  errorMessage={errorsSeller?.name || null}
                  label="Representantes"
                  disabled={!!order?.totalValue && order?.totalValue !== 0}
                />
                <br />
              </>
            ) : (
              ''
            )}

            <AsyncSearch
              disabled={!!order?.totalValue && order?.totalValue !== 0}
              options={customerOptions}
              setOptions={setCustomerOptions}
              option={customerOption}
              setOption={handleSetCustomer}
              asyncSearch={listCustomers}
              loading={loading}
              error={Boolean(errors?.corporateDocument)}
              errorMessage={errors?.corporateDocument || null}
              label="Cliente"
            />
            <br />
            {enableProspect && isNew && (
              <Button style={{ textTransform: 'none' }} onClick={() => setIsCustomerDialogOpen(true)}>
                Adicionar Prospect
              </Button>
            )}
            {enableProspect && customer?.isProspect && (
              <Button style={{ textTransform: 'none' }} onClick={() => setIsCustomerDialogOpen(true)}>
                Editar Prospect
              </Button>
            )}
            <CustomerDialogForm
              open={isCustomerDialogOpen}
              onClose={() => setIsCustomerDialogOpen(false)}
              customerCreated={customerCreated}
              customerId={customer?.isProspect ? customer?.id : undefined}
            />
          </>
        )}
      </>
    </>
  );
});

export default OrderCustomerForm;
