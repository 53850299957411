import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Autocomplete, CircularProgress, Grid, TextField, debounce } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt-br';
import * as yup from 'yup';

import { ButtonGroup } from '../../components/button-group/ButtonGroup';
import { PageCard } from '../../components/page-card/PageCard';
import { TitleContainer } from '../../components/title-container/TitleContainer';
import IPage from '../../interfaces/IPage';
import { Button } from '../../components/button/Button';
import { Form } from '../../components/form/Form';
import { useToastr } from '../../hooks/useToastr';
import { useImageParamsService } from '../../services/useImageParamsService';
import { IImageType } from '../../interfaces/IImageType';
import IFormError from '../../interfaces/IFormError';
import { DatePickerMui, ImageContainer, ImageInputContainer, LocalizationProviderMui } from './ImageParams.styled';
import { validateSchema } from '../../util/validateSchema';
import { BackdropCustom } from '../../components/backdrop/Backdrop';

const ImageParams: React.FC<IPage> = ({ title }) => {
  window.document.title = title;

  const [imageParamsId, setImageParamsId] = useState('');

  const [imageType, setImageType] = useState<IImageType>({ id: 1, description: 'Cabeçalho' });
  const [loadingImageType, setLoadingImageType] = useState(false);
  const [openImageType, setOpenImageType] = useState(false);
  const [optionsImageTypes, setOptionsImageTypes] = useState<IImageType[]>([]);

  const [initialDate, setInitialDate] = useState<Dayjs | null>(dayjs());
  const [finalDate, setFinalDate] = useState<Dayjs | null>(dayjs());
  const [link, setLink] = useState('');
  const [image, setImage] = useState('');

  const [errors, setErrors] = useState<IFormError | null>(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const toastr = useToastr();

  const location = useLocation();

  const { getById, listImageTypes, createImageParams, updateImageParams, deleteImageParams, uploadImageParams } =
    useImageParamsService();

  const handleToImageParamsList = useCallback(() => {
    navigate('/image-params-list');
  }, []);

  const handleViewLink = useCallback(() => {
    if (link.length > 15) {
      window.open(link, '_blank');
    }
  }, [link]);

  const handleListImageParams = useCallback(async () => {
    const id = location.pathname.replace('/image-params', '').replace('/', '');
    setImageParamsId(id);

    if (id) {
      setLoading(true);
      await getById(id)
        .then(response => {
          setImageType(response.imageType);
          setInitialDate(dayjs(response.initialDate));
          setFinalDate(dayjs(response.finalDate));
          setLink(response.link ? response.link : '');
          setImage(response.image ? response.image : '');
        })
        .catch(error => {
          toastr.error(error?.message || 'Contate a equipe de suporte');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [navigate, location.pathname]);

  const handleImageChenge = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData();

        data.append('image', e.target.files[0]);

        setLoading(true);

        await uploadImageParams(imageParamsId, data)
          .then(async () => {
            toastr.success('Imagem atualizada com sucesso');

            await handleListImageParams();
          })
          .catch(error => {
            toastr.error(error?.message || 'Contate a equipe de suporte');
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [navigate, imageParamsId],
  );

  const handleImageTypes = useCallback(async (description: string) => {
    await listImageTypes(`perPage=10&currentPage=1&orderBy=description&orderDirection=asc&description=${description}`)
      .then(response => {
        setOptionsImageTypes(response.data);
      })
      .catch(error => {
        toastr.error(error?.message || 'Contate a equipe de suporte');
      })
      .finally(() => {
        setLoadingImageType(false);
      });
  }, []);

  const handleChangeImageTypes = debounce(async (description = '') => {
    setLoadingImageType(true);
    await handleImageTypes(description);
    setLoadingImageType(false);
  }, 500);

  const handleDeleteImageParams = useCallback(async () => {
    setLoading(true);
    await deleteImageParams(imageParamsId)
      .then(() => {
        toastr.success('Parâmetros deletados com sucesso');
        navigate(`/image-params-list`);
      })
      .catch(error => {
        toastr.error(error?.message || 'Contate a equipe de suporte');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [imageParamsId]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    setErrors(null);

    const data = {
      imageTypeId: imageType?.id,
      initialDate: initialDate?.format('YYYY-MM-DD 12:00:00') || dayjs().format('YYYY-MM-DD 12:00:00'),
      finalDate: finalDate?.format('YYYY-MM-DD 12:00:00') || dayjs().format('YYYY-MM-DD 12:00:00'),
      link,
    };

    const validation = validateSchema();

    const schema = yup.object({
      imageTypeId: yup.string().required('Tipo da imagem é obrigatória'),
    });

    const result = await validation.validate(schema, data).then(result => {
      return result;
    });

    if (!result.isValid) {
      setErrors(result.errors);
      setLoading(false);
      return;
    } else {
      setErrors(null);
    }

    if (imageParamsId) {
      await updateImageParams({
        id: imageParamsId,
        imageTypeId: data.imageTypeId,
        initialDate: data.initialDate,
        finalDate: data.finalDate,
        link: data.link,
      })
        .then(() => {
          toastr.success('Parâmetros atualizados com sucesso');
          navigate(`/image-params-list`);
        })
        .catch(() => {
          toastr.error('Contate a equipe de suporte');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      await createImageParams(data)
        .then(response => {
          toastr.success('Parâmetros cadastrados com sucesso');
          setImageParamsId(response.id);
          navigate(`/image-params/${response.id}`);
        })
        .catch(() => {
          toastr.error('Contate a equipe de suporte');
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setLoading(false);
  }, [imageParamsId, imageType, initialDate, finalDate, link, navigate, handleListImageParams]);

  useEffect(() => {
    handleListImageParams();
    handleImageTypes('');
  }, []);

  return (
    <>
      <TitleContainer>
        <h1>Parâmetros de Imagem</h1>
      </TitleContainer>
      <PageCard>
        <Form>
          {loading && <BackdropCustom />}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} sm={6}>
              <Autocomplete
                sx={{ width: '100%' }}
                open={openImageType}
                value={imageType}
                onChange={(e: any, newValue) => {
                  const castedImageType = { ...newValue } as IImageType;
                  setImageType({ ...castedImageType });
                }}
                onOpen={() => {
                  setOpenImageType(true);
                }}
                onClose={() => {
                  setOpenImageType(false);
                }}
                isOptionEqualToValue={(option, value) => {
                  return option?.description === value?.description;
                }}
                getOptionLabel={option => (option?.description ? option?.description : '')}
                options={optionsImageTypes}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Tipo de Imagem"
                    error={Boolean(errors?.imageTypeId)}
                    helperText={Boolean(errors?.imageTypeId) ? errors?.imageTypeId : ''}
                    size="small"
                    type="text"
                    autoComplete="off"
                    onChange={e => {
                      setLoadingImageType(true);
                      handleChangeImageTypes(e.target.value);
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingImageType ? <CircularProgress color="primary" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <TextField
                sx={{ width: '100%' }}
                size="small"
                label="Link"
                value={link}
                onChange={e => setLink(e.target.value)}
                autoComplete="off"
                error={Boolean(errors?.link)}
                helperText={Boolean(errors?.link) ? errors?.link : ''}
              />
            </Grid>

            <Grid item xs={12} md={6} sm={6}>
              <LocalizationProviderMui dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <DatePickerMui
                  slotProps={{ textField: { size: 'small' } }}
                  label="Data Inicial"
                  value={initialDate}
                  onChange={value => setInitialDate(dayjs(String(value)))}
                />
              </LocalizationProviderMui>
            </Grid>

            <Grid item xs={12} md={6} sm={6}>
              <LocalizationProviderMui dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <DatePickerMui
                  slotProps={{ textField: { size: 'small' } }}
                  label="Data Final"
                  value={finalDate}
                  onChange={value => setFinalDate(dayjs(String(value)))}
                />
              </LocalizationProviderMui>
            </Grid>
          </Grid>

          <ButtonGroup>
            <Button onClick={handleToImageParamsList} variant="contained" color="inherit" disabled={loading}>
              Voltar
            </Button>
            {imageParamsId && (
              <Button onClick={handleDeleteImageParams} variant="contained" disabled={loading} color="error">
                Excluir
              </Button>
            )}

            <Button onClick={handleSubmit} loading={loading} variant="contained" color="success">
              Confirmar
            </Button>
          </ButtonGroup>
        </Form>
      </PageCard>

      {imageParamsId && (
        <PageCard>
          <div style={{ marginBottom: '8px' }}>
            <ImageInputContainer>
              <label>
                {!!image ? 'Alterar Imagem' : 'Inserir Imagem'}
                <input type="file" id="image" onChange={handleImageChenge} accept="image/*"></input>
              </label>
            </ImageInputContainer>
          </div>
          {!!image && (
            <ImageContainer onClick={handleViewLink}>
              <img src={image} alt="imageToResource" />
            </ImageContainer>
          )}
        </PageCard>
      )}
    </>
  );
};

export { ImageParams };
