import styled from 'styled-components';
import AppRouter from '../../router/AppRouter';
import './Content.css';

const ContentStyled = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
`;

const Content = () => {
  return (
    <ContentStyled className="font-default">
      <AppRouter />
    </ContentStyled>
  );
};

export default Content;
