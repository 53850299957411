import AppError from '../errors/AppError';
import { IPaginationReturn } from '../interfaces/IPaginationReturn';
import { IPriceTable } from '../interfaces/IPriceTable';
import { useRequest } from './useRequest';

const usePriceTableService = () => {
  const requestPriceTables = useRequest<IPriceTable>();
  const pathPriceTables = 'price-tables';

  const createPriceTable = async (priceTable: IPriceTable): Promise<IPriceTable> => {
    return await requestPriceTables
      .post({ path: `${pathPriceTables}/`, sendAuthorization: true, body: priceTable })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message, error.status);
      });
  };

  const updatePriceTable = async (id: string, priceTable: IPriceTable): Promise<IPriceTable> => {
    return await requestPriceTables
      .put({ path: `${pathPriceTables}/${id}`, sendAuthorization: true, body: priceTable })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message, error.status);
      });
  };

  const listPriceTable = async (id: string): Promise<IPriceTable> => {
    return await requestPriceTables
      .getOne({ path: `${pathPriceTables}/${id}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message, error.status);
      });
  };

  const listAllPriceTables = async (url: string): Promise<IPaginationReturn<IPriceTable[]>> => {
    const response = await requestPriceTables
      .getManyPaginated({
        path: `${pathPriceTables}?${url}`,
        sendAuthorization: true,
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });

    return response;
  };

  const deletePriceTable = async (id: string): Promise<void> => {
    await requestPriceTables
      .remove({ path: `${pathPriceTables}/${id}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message, error.status);
      });
  };

  return {
    listPriceTable,
    listAllPriceTables,
    createPriceTable,
    updatePriceTable,
    deletePriceTable,
  };
};

export { usePriceTableService };
