import { Dialog, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TitleContainer } from '../../components/title-container/TitleContainer';
import { PageCard } from '../../components/page-card/PageCard';
import IMeasurementUnit from '../../interfaces/IMeasurementUnit';
import styled from 'styled-components';
import { Button } from '../../components/button/Button';
import { useMeasurementUnitService } from '../../services/useMeasurementUnitService';
import { useToastr } from '../../hooks/useToastr';
import { BackdropCustom } from '../../components/backdrop/Backdrop';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

interface IProps {
  id: string | null;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialMeasurementUnit: IMeasurementUnit = {
  id: '',
  description: '',
  precision: 0,
};

const MeasurementUniForm: React.FC<IProps> = ({ isOpen, setIsOpen, id }) => {
  const [loading, setLoading] = useState(false);
  const [measurementUnid, setMeasurementUnit] = useState<IMeasurementUnit>({ ...initialMeasurementUnit });
  const { listById, update } = useMeasurementUnitService();
  const toastr = useToastr();

  const listMeasurementUnit = async () => {
    setLoading(true);
    await listById(`${id}`)
      .then(resource => {
        setMeasurementUnit({ ...resource });
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = async () => {
    setLoading(true);
    await update(`${id}`, {
      id: measurementUnid.id,
      description: measurementUnid.description,
      precision: measurementUnid.precision,
    })
      .then(() => {
        setIsOpen(false);
        toastr.success('Dados atualizados com sucesso!');
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isOpen && id) {
      listMeasurementUnit();
    } else {
      setMeasurementUnit({ ...initialMeasurementUnit });
    }
  }, [isOpen]);

  const handlePrecisionChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (parseInt(event.target.value) >= 0) {
      setMeasurementUnit({ ...measurementUnid, precision: parseInt(event.target.value) });
    } else {
      setMeasurementUnit({ ...measurementUnid, precision: 0 });
    }
  };

  return (
    <Dialog fullWidth open={isOpen} maxWidth="md">
      <TitleContainer>
        <h1>Editar Unidade de Medida {id}</h1>
      </TitleContainer>
      <PageCard>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <TextField label="Código" value={`${measurementUnid?.id}`} fullWidth disabled />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField label="Descrição" value={`${measurementUnid?.description}`} fullWidth disabled />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              label="Precisão"
              value={`${measurementUnid?.precision}`}
              fullWidth
              type="number"
              onChange={handlePrecisionChange}
            />
          </Grid>
        </Grid>
        <ButtonContainer>
          <Button onClick={() => setIsOpen(false)} variant="contained" color="inherit" sx={{ textTransform: 'none' }}>
            Fechar
          </Button>
          <Button loading={loading} variant="contained" color="success" onClick={() => handleSave()}>
            Confirmar
          </Button>
        </ButtonContainer>
      </PageCard>

      {loading && <BackdropCustom />}
    </Dialog>
  );
};

export default MeasurementUniForm;
