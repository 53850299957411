import styled from 'styled-components';
import LoadingButton from '@mui/lab/LoadingButton';
import { RadioGroup, TextField } from '@mui/material';

export const RadioGroupCustom = styled(RadioGroup)`
  && {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const TextFieldCustom = styled(TextField)`
  && {
    width: 100%;
    margin-top: 0.35rem;
  }
`;

export const ButtonCustom = styled(LoadingButton)`
  && {
    width: 100%;
    padding: 0.51rem;
    margin-top: 1rem;
    font-weight: 700;
    text-transform: none;
  }
`;
