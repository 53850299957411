import React, { useEffect, useState } from 'react';

import IPage from '../../../interfaces/IPage';
import { DashboardCard, DashboardContainer, DashboardContent } from './CustomerPortalDashboard.styles';
import { useCustomerPortalDashboardService } from '../../../services/useCustomerPortalDashboardService';
import IDashboardReport from '../../../interfaces/IDashboardReport';
import { useToastr } from '../../../hooks/useToastr';
import useFormatIntegerDecimalValues from '../../../util/useFormatIntegerDecimalValues';

const CustomerPortalDashboard: React.FC<IPage> = ({ title }) => {
  window.document.title = title;

  const { listDashboard } = useCustomerPortalDashboardService();
  const toastr = useToastr();
  const { formatIntegerDecimalValues } = useFormatIntegerDecimalValues();

  const [reports, setReports] = useState<IDashboardReport>({
    expiredTitleValue: 0,
    expiredTitleCount: 0,
    maturingTitleValue: 0,
    maturingTitleCount: 0,
  });

  const handleLoadinData = async () => {
    await listDashboard()
      .then((response: any) => {
        setReports(response);
      })
      .catch(error => {
        toastr.error(error.message || 'Erro ao carregar dados');
      });
  };

  useEffect(() => {
    handleLoadinData();
  }, []);

  return (
    <DashboardContainer>
      <DashboardContent>
        <DashboardCard>
          <div>
            <span>Vlr. Títulos Vencidos</span>
          </div>

          <div>{<h1>R$ {formatIntegerDecimalValues(reports?.expiredTitleValue, 'DECIMAL')}</h1>}</div>
        </DashboardCard>

        <DashboardCard>
          <div>
            <span>Qtd. Títulos Vencidos</span>
          </div>
          <div>{<h1>{reports?.expiredTitleCount}</h1>}</div>
        </DashboardCard>

        <DashboardCard>
          <div>
            <span>Vlr. Títulos à Vencer</span>
          </div>
          <div>{<h1>R$ {formatIntegerDecimalValues(reports?.maturingTitleValue, 'DECIMAL')}</h1>}</div>
        </DashboardCard>

        <DashboardCard>
          <div>
            <span>Qtd. Títulos à Vencer</span>
          </div>
          <div>{<h1>{reports?.maturingTitleCount}</h1>}</div>
        </DashboardCard>
      </DashboardContent>
    </DashboardContainer>
  );
};

export { CustomerPortalDashboard };
