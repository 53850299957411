import AppError from '../errors/AppError';
import { IPaginationReturn } from '../interfaces/IPaginationReturn';
import { IPaymentCondition } from '../interfaces/IPaymentCondition';

import { useRequest } from './useRequest';

const usePaymentConditionService = () => {
  const request = useRequest<IPaymentCondition>();
  const path = 'payment-conditions';

  const listDynamically = async (queryParams: string): Promise<IPaginationReturn<IPaymentCondition[]>> => {
    return await request
      .getManyPaginated({ path: `${path}/dynamically?${queryParams}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  return { listDynamically };
};

export { usePaymentConditionService };
