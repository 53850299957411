import { Paper } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 8px;
`;

export const CardContainer = styled(Paper)`
  && {
    margin: 0px 8px 16px 8px;
    display: flex;
    flex-direction: column;
  }

  .title-card-with-switch {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;

export const CardTitle = styled.h2`
  margin: 0;
  padding: 16px;
  font-size: 1rem;
  font-weight: 500;
`;

export const CustomerContent = styled.div`
  display: flex;
  padding: 24px 16px;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-right: 8px;

  @media (max-width: 800px) {
    & + & {
      margin-top: 24px;
    }
  }
`;

export const CreditLimitCard = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;

    > span {
      font-weight: 500;
      font-size: 1rem;
      color: #757575;
    }

    div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 8px;

      span {
        line-height: 1.5;
        font-size: 1rem;
        text-overflow: ellipsis;
        width: 150px;
      }
    }
  }

  @media (max-width: 1380px) {
    flex-direction: column;
  }

  @media (max-width: 899px) {
    flex-direction: row;
  }

  @media (max-width: 590px) {
    flex-direction: column;
  }
`;

export const TitleContainerCustom = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px;
  justify-content: space-between;
  align-items: center;

  h1 {
    padding: 0;
    margin: 0;
    font-size: 1.25rem;
    color: #999999;
    font-weight: normal;
  }

  a {
    text-decoration: none;
    color: var(--main-color);
    font-size: 0.85rem;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }
`;
