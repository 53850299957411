import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 8px;
  }

  > span {
    font-weight: 500;
    font-size: 0.75rem;
    color: #757575;
  }

  > div {
    display: flex;
    margin-bottom: 12px;

    div {
      width: 16px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-right: 10px;

      svg {
        color: var(--main-color);
        width: 16px;
      }
    }

    span {
      line-height: 1.5;
      font-size: 0.85rem;
      text-overflow: ellipsis;
    }
  }
`;
