import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Switch,
  TableContainer,
  TextField,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import UnpublishedIcon from '@mui/icons-material/Unpublished';

import IPage from '../../../interfaces/IPage';
import { useCustomerPortalReceivableAccountService } from '../../../services/useCustomerPortalReceivableAccountService';
import IReceivableAccount from '../../../interfaces/IReceivableAccount';
import { useToastr } from '../../../hooks/useToastr';
import CustomTablePagination from '../../../components/table/CustomTablePagination/CustomTablePagination';
import {
  BodyCard,
  BodyCardInfo,
  Content,
  RightArea,
  HeaderCard,
  Input,
  ActionArea,
  FileDownloader,
} from './CustomerPortalReceivableAccounts.styles';
import CustomTableNoContent from '../../../components/table/CustomTableNoContent/CustomTableNoContent';
import { BackdropCustom } from '../../../components/backdrop/Backdrop';
import { PageCard } from '../../../components/page-card/PageCard';
import { Button } from '../../../components/button/Button';
import { ButtonGroup } from '../../../components/button-group/ButtonGroup';
import IFormError from '../../../interfaces/IFormError';

interface IQueryParams {
  currentPage: number;
  perPage: number;
  orderField: string;
  orderDirection: string;
  onlyOpen: boolean;
  receivableNumber: string;
  initialDate: string;
  finalDate: string;
}
const CustomerPortalReceivableAccounts: React.FC<IPage> = ({ title }) => {
  window.document.title = title;

  const { listReceivableAccount, countImpressions } = useCustomerPortalReceivableAccountService();
  const toastr = useToastr();

  const [receivableAccounts, setReceivableAccounts] = useState<IReceivableAccount[]>([]);
  const [receivableAccountsSelecteds, setReceivableAccountsSelecteds] = useState<IReceivableAccount[]>([]);

  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [orderField] = useState('dueDate');
  const [orderDirection] = useState<'desc' | 'asc'>('asc');
  const [totalRows, setTotalRow] = useState(0);
  const [receivableNumber, setReceivableNumber] = useState('');

  const [showFilters, setShowFilters] = useState(false);

  const [onlyOpen, setOnlyOpen] = useState(true);

  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');

  const [loading, setLoading] = useState(false);

  const [formErrors, setFormErros] = useState<IFormError>({});

  const handleSelectItem = (item: IReceivableAccount) => {
    if (item.pdf && item.pdf.length > 50 && !receivableAccountDisabledDownload(item.statusId)) {
      if (receivableAccountsSelecteds.some(receivableAccount => receivableAccount.id === item.id)) {
        setReceivableAccountsSelecteds(
          receivableAccountsSelecteds.filter(receivableAccount => receivableAccount.id !== item.id),
        );
      } else {
        setReceivableAccountsSelecteds([...receivableAccountsSelecteds, item]);
      }
    }
  };

  const [os] = useState(() => {
    const toMatch = [/Macintosh/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
    return toMatch.some(toMatchItem => {
      return navigator.userAgent.match(toMatchItem);
    });
  });

  const receivableAccountDisabledDownload = (receivableAccountStatusId: string) => {
    return (
      receivableAccountStatusId === 'LQ' ||
      receivableAccountStatusId === 'LC' ||
      receivableAccountStatusId === 'LP' ||
      receivableAccountStatusId === 'LS'
    );
  };

  const handleListReceivableAccounts = useCallback(
    async ({
      currentPage,
      perPage,
      orderField,
      orderDirection,
      onlyOpen,
      receivableNumber,
      initialDate,
      finalDate,
    }: IQueryParams) => {
      setFormErros({});
      setReceivableAccountsSelecteds([]);

      let queryParams = `perPage=${perPage}&currentPage=${
        currentPage + 1
      }&orderField=${orderField}&orderDirection=${orderDirection}&onlyOpen=${onlyOpen}`;

      if (receivableNumber) {
        queryParams = queryParams + `&receivableNumber=${receivableNumber}`;
      }

      if (initialDate) {
        if (initialDate.length < 10 || !moment(initialDate, 'DD/MM/YYYY').isValid()) {
          setFormErros({ initialDate: 'Data inicial inválida' });
          return;
        }
        queryParams = queryParams + `&initialDate=${moment(initialDate, 'DD/MM/YYYY').format('YYYY-MM-DD')}`;
      }

      if (finalDate) {
        if (finalDate.length < 10 || !moment(finalDate, 'DD/MM/YYYY').isValid()) {
          setFormErros({ finalDate: 'Data final inválida' });
          return;
        }
        queryParams = queryParams + `&finalDate=${moment(finalDate, 'DD/MM/YYYY').format('YYYY-MM-DD')}`;
      }

      setLoading(true);
      await listReceivableAccount(queryParams)
        .then(response => {
          if (response.data.length > 0) {
            setReceivableAccounts(response.data);
            setTotalRow(response.totalRows);
          } else {
            setReceivableAccounts([]);
            setTotalRow(0);
            setCurrentPage(0);
          }
        })
        .catch(error => {
          toastr.error(error?.message || 'Contate a equipe de suporte');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [toastr, listReceivableAccount],
  );

  const handleCountImpressions = useCallback(
    async (id: string[]) => {
      setLoading(true);
      await countImpressions(id)
        .then(() => {
          handleListReceivableAccounts({
            currentPage,
            perPage,
            orderField,
            orderDirection,
            onlyOpen,
            receivableNumber,
            initialDate,
            finalDate,
          });
        })
        .catch(error => {
          toastr.error(error.message || 'Erro ao atualizar quantidade de impressões');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [
      currentPage,
      perPage,
      orderField,
      orderDirection,
      onlyOpen,
      receivableNumber,
      initialDate,
      finalDate,
      handleListReceivableAccounts,
    ],
  );

  const downloadPDF = async (file: string | null, receivableAccountNumber: string, id: string) => {
    // Obter o base64 do arquivo PDF do estado ou de onde você o armazena
    const base64PDF = file;

    if (base64PDF) {
      // Converter o base64 em blob
      const byteCharacters = atob(base64PDF);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      // Criar um URL do blob e realizar o download
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      if (os) {
        link.target = '_blank';
        link.click();
      } else {
        link.download = `${receivableAccountNumber.replaceAll('/', '')}.pdf`;
        link.click();
      }

      // Limpar o URL do blob após o download
      URL.revokeObjectURL(url);
    }

    await handleCountImpressions([id]);
  };

  const downloadAllFiles = async () => {
    if (receivableAccountsSelecteds.length === 0) {
      toastr.info('Nenhum arquivo seleciondo');
    } else {
      receivableAccountsSelecteds.forEach(receivableAccount => {
        if (receivableAccount.pdf) {
          // Converter o base64 em blob
          const byteCharacters = atob(receivableAccount.pdf);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/pdf' });

          // Criar um URL do blob e realizar o download
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `${receivableAccount.receivableNumber.replaceAll('/', '')}.pdf`; // Define o nome do arquivo para download

          // Simular um clique no link para iniciar o download
          link.click();

          // Limpar o URL do blob após o download
          URL.revokeObjectURL(url);
        }
      });

      await handleCountImpressions(receivableAccountsSelecteds.map(receivableAccount => receivableAccount.id));
    }
  };

  const downloadCampactAllFiles = async () => {
    if (receivableAccountsSelecteds.length === 0) {
      toastr.info('Nenhum arquivo seleciondo');
    } else {
      const zip = new JSZip();

      receivableAccountsSelecteds.forEach((receivableAccount, index) => {
        if (receivableAccount.pdf) {
          // Converter o base64 em blob
          const byteCharacters = atob(receivableAccount.pdf);
          const byteNumbers = new Array(byteCharacters.length);

          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);

          const blob = new Blob([byteArray], { type: 'application/pdf' });

          // Adicionar o blob ao zip

          zip.file(`${receivableAccount.receivableNumber.replaceAll('/', '')}.pdf` || `file${index + 1}.pdf`, blob);
        }
      }); // Gerar o arquivo zip e fazer o download

      zip.generateAsync({ type: 'blob' }).then(content => {
        saveAs(content, 'boletos.zip');
      });

      await handleCountImpressions(receivableAccountsSelecteds.map(receivableAccount => receivableAccount.id));
    }
  };

  const clearFilters = useCallback(() => {
    setReceivableNumber('');
    setInitialDate('');
    setFinalDate('');
    setCurrentPage(0);

    handleListReceivableAccounts({
      currentPage: 0,
      perPage,
      orderField,
      orderDirection,
      onlyOpen,
      receivableNumber: '',
      initialDate: '',
      finalDate: '',
    });
  }, [
    currentPage,
    perPage,
    orderField,
    orderDirection,
    onlyOpen,
    receivableNumber,
    initialDate,
    finalDate,
    handleListReceivableAccounts,
  ]);

  const handleSelectedAll = useCallback(() => {
    const validAccountsReceivable = receivableAccounts.filter(
      receivableAccount => receivableAccount.statusId === 'AB' && receivableAccount.pdf.length > 10,
    );

    setReceivableAccountsSelecteds(validAccountsReceivable);
  }, [receivableAccounts]);

  useEffect(() => {
    handleListReceivableAccounts({
      currentPage,
      perPage,
      orderField,
      orderDirection,
      onlyOpen,
      receivableNumber,
      initialDate,
      finalDate,
    });
  }, [currentPage, perPage, orderField, orderDirection, onlyOpen]);

  return (
    <>
      <Paper sx={{ margin: '16px' }} elevation={6}>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ marginLeft: '2px' }}
            onClick={() => {
              setShowFilters(!showFilters);
            }}
          >
            Filtros
          </AccordionSummary>
          <AccordionDetails sx={{ marginBottom: '24px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} sm={4}>
                <TextField
                  sx={{ width: '100%' }}
                  size="small"
                  label="Número do titulo"
                  value={receivableNumber}
                  onChange={e => setReceivableNumber(e.target.value)}
                  autoComplete="off"
                />
              </Grid>

              <Grid item xs={12} md={4} sm={4}>
                <Input>
                  <InputMask
                    mask={'99/99/9999'}
                    value={initialDate}
                    onChange={e => setInitialDate(e.target.value)}
                    maskPlaceholder={null}
                  >
                    <TextField
                      fullWidth
                      size="small"
                      label="Data Inicial"
                      error={!!formErrors.initialDate}
                      helperText={formErrors.initialDate}
                    />
                  </InputMask>
                </Input>
              </Grid>

              <Grid item xs={12} md={4} sm={4}>
                <Input>
                  <InputMask
                    mask={'99/99/9999'}
                    value={finalDate}
                    onChange={e => setFinalDate(e.target.value)}
                    maskPlaceholder={null}
                  >
                    <TextField
                      fullWidth
                      size="small"
                      label="Data Final"
                      error={!!formErrors.finalDate}
                      helperText={formErrors.finalDate}
                    />
                  </InputMask>
                </Input>
              </Grid>
            </Grid>

            <ButtonGroup>
              <FormControlLabel
                control={<Switch checked={onlyOpen} onChange={() => setOnlyOpen(!onlyOpen)} />}
                label="Apenas Abertos"
              />
              <Button
                variant="outlined"
                size="small"
                onClick={async () => {
                  clearFilters();
                  await handleListReceivableAccounts({
                    currentPage,
                    perPage,
                    orderField,
                    orderDirection,
                    onlyOpen,
                    receivableNumber,
                    initialDate,
                    finalDate,
                  });
                }}
              >
                Limpar
              </Button>
              <Button
                variant="outlined"
                size="small"
                color="success"
                onClick={() =>
                  handleListReceivableAccounts({
                    currentPage,
                    perPage,
                    orderField,
                    orderDirection,
                    onlyOpen,
                    receivableNumber,
                    initialDate,
                    finalDate,
                  })
                }
              >
                Confirmar
              </Button>
            </ButtonGroup>
          </AccordionDetails>
        </Accordion>
      </Paper>

      <PageCard>
        <FileDownloader>
          <Button onClick={downloadAllFiles} size="small" variant="outlined">
            <FileDownloadIcon fontSize="small" />
            <span>Baixar</span>
          </Button>

          <Button onClick={downloadCampactAllFiles} size="small" variant="outlined">
            <FolderZipIcon fontSize="small" />
            <span>Compactar</span>
          </Button>

          <Button onClick={handleSelectedAll} size="small" variant="outlined">
            <TaskAltIcon fontSize="small" />
            <span>Selecionar todos</span>
          </Button>

          <Button
            onClick={() => {
              setReceivableAccountsSelecteds([]);
            }}
            size="small"
            variant="outlined"
          >
            <UnpublishedIcon fontSize="small" />
            <span>Desmarcar todos</span>
          </Button>
        </FileDownloader>

        <TableContainer>
          {receivableAccounts.length > 0 ? (
            <>
              {receivableAccounts.map(receivableAccount => (
                <Content
                  disable={receivableAccountDisabledDownload(receivableAccount.statusId)}
                  key={receivableAccount.id}
                >
                  <div>
                    <HeaderCard>
                      <div className="receivable-account-area">
                        <strong className="receivable-account">{receivableAccount.receivableNumber}</strong>
                      </div>
                    </HeaderCard>
                    <BodyCard>
                      <BodyCardInfo>
                        <span>{`${moment(receivableAccount.dueDate).add(1, 'days').format('DD/MM/YYYY')}`}</span>
                        <span>
                          <strong>
                            {`R$ ${(receivableAccount.originalValue
                              ? receivableAccount.originalValue
                              : 0
                            ).toLocaleString('pt-BR', {
                              style: 'decimal',
                              minimumFractionDigits: 2,
                            })}`}
                          </strong>
                        </span>
                        <span>{receivableAccount.status?.description}</span>
                      </BodyCardInfo>
                    </BodyCard>
                  </div>

                  <RightArea>
                    <span>
                      Quantidade de impressões:{' '}
                      {receivableAccount.quantityOfImpressions ? receivableAccount.quantityOfImpressions : 0}
                    </span>
                    {receivableAccount.pdf &&
                      receivableAccount.pdf.length > 50 &&
                      !receivableAccountDisabledDownload(receivableAccount.statusId) && (
                        <ActionArea
                          selected={receivableAccountsSelecteds.some(item => item.id === receivableAccount.id)}
                        >
                          <IconButton
                            id="select-button"
                            onClick={() => handleSelectItem(receivableAccount)}
                            size="large"
                          >
                            <TaskAltIcon fontSize="large" />
                          </IconButton>

                          <IconButton
                            onClick={() =>
                              downloadPDF(
                                receivableAccount.pdf,
                                receivableAccount.receivableNumber,
                                receivableAccount.id,
                              )
                            }
                            size="large"
                            color="info"
                          >
                            <DownloadForOfflineIcon fontSize="large" />
                          </IconButton>
                        </ActionArea>
                      )}
                  </RightArea>
                </Content>
              ))}
            </>
          ) : (
            <CustomTableNoContent text="Nenhum boleto para listar..." />
          )}
        </TableContainer>
        <CustomTablePagination
          totalRows={totalRows}
          currentPage={currentPage}
          perPage={perPage}
          handleRequest={() =>
            handleListReceivableAccounts({
              currentPage,
              perPage,
              orderField,
              orderDirection,
              onlyOpen,
              receivableNumber,
              initialDate,
              finalDate,
            })
          }
          setPerPage={setPerPage}
          setCurrentPage={setCurrentPage}
          orderField={orderField}
          orderDirection={orderDirection}
        />
      </PageCard>

      {loading && <BackdropCustom />}
    </>
  );
};

export { CustomerPortalReceivableAccounts };
