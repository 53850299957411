import { useCallback, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Alert, FormControl, FormControlLabel, Radio, Stack } from '@mui/material';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';

import { ButtonCustom, RadioGroupCustom, TextFieldCustom } from './AddCustomer.styles';
import IFormError from '../../../interfaces/IFormError';
import { useCustomerPortalUserService } from '../../../services/useCustomerPortalUserService';
import getValidationError from '../../../util/getValidationError';

interface IProps {
  dialogOpen: boolean;
  deny: () => void;
}

const AddCustomer = ({ dialogOpen, deny }: IProps) => {
  const { addCustomer } = useCustomerPortalUserService();
  const [customerType, setCustomerType] = useState<'J' | 'F'>('J');
  const [corporateDocument, setCorporateDocument] = useState('');
  const [responseSuccess, setResponseSuccess] = useState('');
  const [responseError, setResponseError] = useState('');

  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErros] = useState<IFormError>({});

  const handleClose = () => {
    deny();
  };

  const handleChangeCustomerType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerType((event.target as HTMLInputElement).value === 'J' ? 'J' : 'F');
  };

  const handleSubmit = useCallback(async () => {
    setFormErros({});
    setLoading(true);
    setResponseSuccess('');
    setResponseError('');

    try {
      const data = {
        corporateDocument,
      };

      const schema = Yup.object().shape({
        corporateDocument: Yup.string().required(customerType === 'J' ? 'CNPJ obrigatório' : 'CPF obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await addCustomer(corporateDocument)
        .then(response => {
          setResponseSuccess(`Cadastro realizado com sucesso. Confirme o cadastro no e-mail ${response.email}.`);
        })
        .catch(error => {
          setResponseError(error.message || 'Erro ao adicionar novo documento. Contate o suporte.');
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationError(err);

        setFormErros(errors);
        return;
      }

      setResponseError('Ocorreu um erro ao adicionar documento. Contate o suporte.');
    } finally {
      setLoading(false);
    }
  }, [handleClose]);

  useEffect(() => {
    setCorporateDocument('');
  }, [customerType]);

  useEffect(() => {
    setResponseSuccess('');
    setResponseError('');
    setCorporateDocument('');
    setCustomerType('J');
  }, [dialogOpen]);

  return (
    <Dialog open={dialogOpen}>
      <DialogContent>
        <Stack sx={{ width: '100%', marginBottom: '16px' }} spacing={2}>
          {!!responseSuccess && <Alert severity="success">{responseSuccess}</Alert>}
          {!!responseError && <Alert severity="error">{responseError}</Alert>}
        </Stack>
        <FormControl style={{ width: '100%', marginTop: '16px' }}>
          <RadioGroupCustom
            aria-labelledby="customer-type-radio-buttons-group"
            name="customer-type"
            value={customerType}
            onChange={handleChangeCustomerType}
          >
            <FormControlLabel value="J" control={<Radio />} label="Jurídica" />
            <FormControlLabel value="F" control={<Radio />} label="Física" />
          </RadioGroupCustom>
        </FormControl>

        {customerType === 'J' ? (
          <InputMask
            mask={'99.999.999/9999-99'}
            value={corporateDocument}
            onChange={e => setCorporateDocument(e.target.value)}
            maskPlaceholder={null}
          >
            <TextFieldCustom
              size="small"
              autoComplete="off"
              helperText={formErrors.corporateDocument}
              error={!!formErrors.corporateDocument}
              label="CNPJ"
            />
          </InputMask>
        ) : (
          <InputMask
            mask={'999.999.999-99'}
            value={corporateDocument}
            onChange={e => setCorporateDocument(e.target.value)}
            maskPlaceholder={null}
          >
            <TextFieldCustom
              size="small"
              autoComplete="off"
              helperText={formErrors.corporateDocument}
              error={!!formErrors.corporateDocument}
              label="CPF"
            />
          </InputMask>
        )}
      </DialogContent>
      <DialogActions>
        <ButtonCustom onClick={handleClose} disabled={loading} color="error">
          {responseSuccess ? 'Voltar' : 'Cancelar'}
        </ButtonCustom>
        <ButtonCustom onClick={handleSubmit} autoFocus loading={loading}>
          Confirmar
        </ButtonCustom>
      </DialogActions>
    </Dialog>
  );
};

export { AddCustomer };
