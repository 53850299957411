import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useAuth } from '../../hooks/auth';
import IPage from '../../interfaces/IPage';
import { Form } from '../../components/form/Form';
import { useToastr } from '../../hooks/useToastr';
import IFormError from '../../interfaces/IFormError';
import { Button } from '../../components/button/Button';
import { IPriceTable } from '../../interfaces/IPriceTable';
import { TitleContainerCustom } from './PriceTable.styled';
import { PageCard } from '../../components/page-card/PageCard';
import { BackdropCustom } from '../../components/backdrop/Backdrop';
import { ButtonGroup } from '../../components/button-group/ButtonGroup';
import { usePriceTableService } from '../../services/usePriceTableService';
import { PriceTablesListItems } from './price-tables-list-items/PriceTablesList';
import getValidationError from '../../util/getValidationError';

const PriceTable: React.FC<IPage> = ({ title }) => {
  window.document.title = title;

  const navigate = useNavigate();
  const location = useLocation();
  const toastr = useToastr();
  const { signOut } = useAuth();

  const { listPriceTable, updatePriceTable, createPriceTable } = usePriceTableService();

  const [priceTableId, setPriceTableId] = useState('');
  const [code, setCode] = useState('');
  const [description, setDescription] = useState('');
  const [isActive, setIsActive] = useState(true);

  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState<IFormError>({});

  const handleListPriceTable = useCallback(async () => {
    const id = location.pathname.replace('/price-table', '').replace('/', '');

    setPriceTableId(id);

    if (id) {
      setLoading(true);

      await listPriceTable(id)
        .then(response => {
          const priceTable: IPriceTable = response;

          setCode(priceTable?.code || '');
          setDescription(priceTable.description);
          setIsActive(priceTable.isActive);
        })
        .catch(error => {
          if (error.status === 401) {
            signOut();
            navigate('/');
          }

          toastr.error(error?.message || 'Contate a equipe de suporte');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [navigate, signOut, toastr, location.pathname]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    setFormErrors({});

    try {
      const data: IPriceTable = {
        code,
        description,
        isActive,
      };

      const schema = Yup.object().shape({
        code: Yup.string().required('Código é obrigatório'),
        description: Yup.string().required('Descrição obrigatória'),
        isActive: Yup.string().required(''),
      });

      if (priceTableId) {
        await schema.validate(data, {
          abortEarly: false,
        });

        delete data.code;

        await updatePriceTable(priceTableId, data)
          .then(async () => {
            toastr.success('Tabela de preço atualizado com sucesso');
          })
          .catch(error => {
            if (error.status === 401) {
              signOut();
              navigate('/');
            }

            toastr.error(error?.message || 'Contate a equipe de suporte');
          });
      } else {
        await schema.validate(data, {
          abortEarly: false,
        });

        await createPriceTable(data)
          .then(async () => {
            toastr.success('Tabela de preço criada com sucesso');

            navigate('/price-tables');
          })
          .catch(error => {
            if (error.status === 401) {
              signOut();
              navigate('/');
            }

            toastr.error(error?.message || 'Contate a equipe de suporte');
          });
      }
    } catch (error: Yup.ValidationError | any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationError(error);
        setFormErrors(errors);
        return;
      }

      toastr.error(error?.message || 'Contate a equipe de suporte');
    } finally {
      setLoading(false);
    }
  }, [priceTableId, code, description, isActive, navigate, signOut]);

  const handleChangeIsActive = (event: ChangeEvent<HTMLInputElement>) => {
    setIsActive(event.target.checked);
  };

  useEffect(() => {
    handleListPriceTable();
  }, []);

  return (
    <>
      <TitleContainerCustom>
        <h1>Tabela de Preço</h1>
      </TitleContainerCustom>

      <PageCard>
        <Form>
          <Grid container spacing={3} sx={{ marginBottom: '24px' }}>
            <Grid item xs={12} md={6} sm={6}>
              <TextField sx={{ width: '100%' }} size="small" label="Código ERP" value={code} required disabled />
            </Grid>

            <Grid item xs={12} md={6} sm={6}>
              <TextField
                sx={{ width: '100%' }}
                size="small"
                label="Descrição"
                value={description}
                onChange={e => setDescription(e.target.value)}
                autoFocus
                required
                error={!!formErrors.description}
                helperText={formErrors.description}
              />
            </Grid>

            <Grid item xs={12} md={6} sm={6}>
              <FormControlLabel
                label="Ativo"
                control={<Checkbox checked={isActive} onChange={handleChangeIsActive} />}
              />
            </Grid>
          </Grid>

          <ButtonGroup>
            <Button
              onClick={() => {
                navigate(`/price-tables-list`);
              }}
              disabled={loading}
              variant="contained"
              color="inherit"
            >
              Voltar
            </Button>
            <Button onClick={handleSubmit} disabled={loading} variant="contained">
              Confirmar
            </Button>
          </ButtonGroup>
        </Form>
      </PageCard>

      {priceTableId && <PriceTablesListItems priceTableId={priceTableId} />}

      {loading && <BackdropCustom />}
    </>
  );
};
export { PriceTable };
