import React, { useCallback, useEffect, useState } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  Fade,
  TableCellProps,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  DialogTitle,
} from '@mui/material';
import { MoreVertOutlined } from '@mui/icons-material';
import * as Yup from 'yup';

import { ICustomerPortalUserCustomer } from '../../../interfaces/ICustomerPOrtalUserCustomer';
import { LoadingButton } from '@mui/lab';
import IFormError from '../../../interfaces/IFormError';
import { useCustomerPortalUsersService } from '../../../services/useCustomerPortalUsersService';
import { useToastr } from '../../../hooks/useToastr';
import getValidationError from '../../../util/getValidationError';

interface IMenuItemProps extends TableCellProps {
  customerPortalUsersCustomer: ICustomerPortalUserCustomer;
  handleToEdit?: (id: string) => void;
  handleActiveCustomerPortalUser?: (id: string) => void;
}

const CustomerPortalUsersMenuItem: React.FC<IMenuItemProps> = ({
  customerPortalUsersCustomer,
  handleActiveCustomerPortalUser,
}) => {
  const { updatePasswordCustomerPortalUser } = useCustomerPortalUsersService();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const toastr = useToastr();

  const [menuOpen, setMenuOpen] = useState(true);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [formErrors, setFormErros] = useState<IFormError>({});

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleUpdatePassword = useCallback(async () => {
    setEditLoading(true);

    setFormErros({});

    try {
      const data = {
        password,
        passwordConfirmation,
      };

      const schema = Yup.object().shape({
        password: Yup.string().required('Senha obrigatória'),
        passwordConfirmation: Yup.string().required('Confirmação de senha obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await updatePasswordCustomerPortalUser({
        id: customerPortalUsersCustomer.customerPortalUserId,
        password,
        passwordConfirmation,
      })
        .then(() => {
          toastr.success('Senha alterada com sucesso.');
          setEditDialogOpen(false);
        })
        .catch(error => {
          toastr.error(error.message || 'Ocorreu um erro ao alterar a senha do usuário, Contate o suporte.');
        });
    } catch (err: Yup.ValidationError | any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationError(err);
        setFormErros(errors);
        return;
      }

      toastr.error('Ocorreu um erro ao fazer login. Contate o suporte.');
    } finally {
      setEditLoading(false);
    }
  }, [customerPortalUsersCustomer, password, passwordConfirmation, toastr]);

  useEffect(() => {
    setPassword('');
    setPasswordConfirmation('');
    setFormErros({});
  }, [editDialogOpen]);

  return (
    <TableCell size="small" id={customerPortalUsersCustomer.id} sx={{ width: '10px' }}>
      <IconButton
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpenMenu}
        size="small"
      >
        <MoreVertOutlined fontSize="small" />
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open && menuOpen}
        onClose={handleCloseMenu}
        TransitionComponent={Fade}
        onClick={() => {
          setMenuOpen(true);
        }}
      >
        {handleActiveCustomerPortalUser && !customerPortalUsersCustomer.isActive && (
          <MenuItem
            onClick={() => {
              handleActiveCustomerPortalUser(customerPortalUsersCustomer.id);
              handleCloseMenu();
            }}
            sx={{ fontSize: '0.875rem' }}
          >
            Ativar
          </MenuItem>
        )}

        <MenuItem
          sx={{ fontSize: '0.875rem' }}
          onClick={() => {
            setEditDialogOpen(true);
            handleCloseMenu();
          }}
        >
          Alterar Senha
        </MenuItem>
      </Menu>

      <Dialog
        open={editDialogOpen}
        onClose={() => {
          setEditDialogOpen(!editDialogOpen);
        }}
        sx={{
          '& .MuiDialog-container': {
            width: '100vw',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <DialogTitle>
          <span style={{ fontSize: '16px' }}>Alteação de senha</span>
        </DialogTitle>
        <DialogContent style={{ padding: '16px' }}>
          <TextField
            fullWidth
            label="Senha"
            placeholder="Senha"
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
            size="small"
            type="password"
            error={!!formErrors.password}
            helperText={formErrors.password}
          />
          <TextField
            fullWidth
            label="Confirmação de Senha"
            placeholder="Confirmação de Senha"
            value={passwordConfirmation}
            onChange={e => {
              setPasswordConfirmation(e.target.value);
            }}
            size="small"
            type="password"
            sx={{ marginTop: '16px' }}
            error={!!formErrors.passwordConfirmation}
            helperText={formErrors.passwordConfirmation}
          />
        </DialogContent>
        <DialogActions sx={{ marginRight: '10px' }}>
          <LoadingButton
            variant="text"
            color="error"
            onClick={() => {
              setEditDialogOpen(!editDialogOpen);
            }}
            disabled={editLoading}
            sx={{ textTransform: 'none' }}
          >
            Cancelar
          </LoadingButton>
          <LoadingButton
            variant="text"
            onClick={async () => {
              await handleUpdatePassword();
            }}
            loading={editLoading}
            disabled={editLoading}
            sx={{ textTransform: 'none' }}
          >
            Confirmar
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </TableCell>
  );
};

export default CustomerPortalUsersMenuItem;
