import AppError from '../errors/AppError';
import IOrderStatus from '../interfaces/IOrderStatus';
import { IPaginationReturn } from '../interfaces/IPaginationReturn';

import { useRequest } from './useRequest';

const useOrderStatusService = () => {
  const request = useRequest<IOrderStatus>();
  const path = 'order-status';

  const listOrderStatus = async (queryParams: string): Promise<IPaginationReturn<IOrderStatus[]>> => {
    return await request
      .getManyPaginated({ path: `${path}?${queryParams}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const updateOrderStatus = async (id: number, orderStatus: IOrderStatus): Promise<IOrderStatus> => {
    return await request
      .put({ path: `${path}/${id}`, sendAuthorization: true, body: orderStatus })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const listOrderStatusById = async (id: number): Promise<IOrderStatus> => {
    return await request
      .getOne({ path: `${path}/${id}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const uploadIcon = async (id: number, data: FormData): Promise<IOrderStatus> => {
    const response = await request
      .patch({
        path: `${path}/icon/${id}`,
        sendAuthorization: true,
        body: data,
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });

    return response;
  };

  return { listOrderStatus, updateOrderStatus, uploadIcon, listOrderStatusById };
};

export { useOrderStatusService };
