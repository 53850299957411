import AppError from '../errors/AppError';
import { IPaginationReturn } from '../interfaces/IPaginationReturn';
import ISalesRegion from '../interfaces/ISalesRegion';

import { useRequest } from './useRequest';

const useSalesRegionService = () => {
  const request = useRequest<ISalesRegion>();
  const path = 'sales-region';

  const findAll = async (queryParams: string): Promise<IPaginationReturn<ISalesRegion[]>> => {
    return await request
      .getManyPaginated({ path: `${path}?${queryParams}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  return { findAll };
};

export { useSalesRegionService };
