import AppError from '../errors/AppError';
import { useCustomerPortalAuth } from '../hooks/custmerPortalAuth';
import { IPaginationReturn } from '../interfaces/IPaginationReturn';
import IReceivableAccount from '../interfaces/IReceivableAccount';
import { useRequest } from './useRequest';

const useCustomerPortalReceivableAccountService = () => {
  const requestsReceivableAccounts = useRequest<IReceivableAccount>();
  const pathReceivableAccounts = 'customer-portal/receivable-accounts';

  const { customerPortalSession, customerPortalCustomerSelected } = useCustomerPortalAuth();

  const listReceivableAccount = async (queryParams: string): Promise<IPaginationReturn<IReceivableAccount[]>> => {
    const response = await requestsReceivableAccounts
      .getManyPaginated({
        path: `${pathReceivableAccounts}?${queryParams}`,
        headers: {
          Authorization: `bearer ${customerPortalSession.token.access_token}`,
          tenant: customerPortalSession.user.tenant,
          customer: customerPortalCustomerSelected.id,
        },
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });

    return response;
  };

  const countImpressions = async (ids: string[]): Promise<void> => {
    await requestsReceivableAccounts
      .patch({
        path: `${pathReceivableAccounts}/count`,
        headers: {
          Authorization: `bearer ${customerPortalSession.token.access_token}`,
          tenant: customerPortalSession.user.tenant,
          customer: customerPortalCustomerSelected.id,
        },
        body: {
          ids,
        },
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });

    return;
  };

  return { listReceivableAccount, countImpressions };
};

export { useCustomerPortalReceivableAccountService };
