import AppError from '../errors/AppError';
import { IBranchOffice } from '../interfaces/IBranchOffice';
import { IPaginationReturn } from '../interfaces/IPaginationReturn';

import { useRequest } from './useRequest';

const useBranchOfficeService = () => {
  const request = useRequest<IBranchOffice>();
  const path = 'branch-offices';
  const listAll = async (queryParams: string): Promise<IPaginationReturn<IBranchOffice[]>> => {
    return await request
      .getManyPaginated({ path: `${path}?${queryParams}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  return { listAll };
};

export { useBranchOfficeService };
