import { IImageParams } from './../interfaces/IImageParams';
import AppError from '../errors/AppError';
import { useAuth } from '../hooks/auth';
import { IImageType } from '../interfaces/IImageType';
import { IPaginationReturn } from '../interfaces/IPaginationReturn';
import { useRequest } from './useRequest';
import { ICreateImageParams } from '../interfaces/ICreateImageParams';
import { IUpdateImageParams } from '../interfaces/IUpdateImageParams';
import { IActiveImages } from '../interfaces/IActiveImages';

const useImageParamsService = () => {
  const requestImageParams = useRequest<IImageParams>();
  const requestActiveImageParams = useRequest<IActiveImages>();
  const requestImageType = useRequest<IImageType>();
  const pathImageParams = 'image-params';
  const pathImageTypes = 'image-types';

  const { state } = useAuth();

  const listAll = async (queryParams: string): Promise<IPaginationReturn<IImageParams[]>> => {
    const response = await requestImageParams
      .getManyPaginated({
        path: `${pathImageParams}?${queryParams}`,
        headers: {
          Authorization: `bearer ${state.access_token}`,
        },
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });

    return response;
  };

  const getById = async (id: string): Promise<IImageParams> => {
    const response = await requestImageParams
      .getOne({
        path: `${pathImageParams}/${id}`,
        headers: {
          Authorization: `bearer ${state.access_token}`,
        },
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });

    return response;
  };

  const listImageTypes = async (queryParams: string): Promise<IPaginationReturn<IImageType[]>> => {
    const response = await requestImageType
      .getManyPaginated({
        path: `${pathImageTypes}?${queryParams}`,
        headers: {
          Authorization: `bearer ${state.access_token}`,
        },
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });

    return response;
  };

  const listActiveImages = async (): Promise<IActiveImages> => {
    return await requestActiveImageParams
      .getOne({
        path: `${pathImageParams}/active_images`,
        headers: {
          Authorization: `bearer ${state.access_token}`,
        },
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message, error.status);
      });
  };

  const createImageParams = async (data: ICreateImageParams): Promise<IImageParams> => {
    const response = await requestImageParams
      .post({
        path: `${pathImageParams}`,
        headers: {
          Authorization: `bearer ${state.access_token}`,
        },
        body: data,
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });

    return response;
  };

  const updateImageParams = async ({
    id,
    imageTypeId,
    initialDate,
    finalDate,
    link,
  }: IUpdateImageParams): Promise<IImageParams> => {
    const response = await requestImageParams
      .put({
        path: `${pathImageParams}/${id}`,
        headers: {
          Authorization: `bearer ${state.access_token}`,
        },
        body: { imageTypeId, initialDate, finalDate, link },
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });

    return response;
  };

  const deleteImageParams = async (id: string): Promise<void> => {
    await requestImageParams
      .remove({
        path: `${pathImageParams}/${id}`,
        headers: {
          Authorization: `bearer ${state.access_token}`,
        },
      })
      .then(() => {
        return;
      })
      .catch(error => {
        throw new AppError(error.message);
      });

    return;
  };

  const uploadImageParams = async (imageParamsId: string, data: FormData): Promise<IImageParams> => {
    const response = await requestImageParams
      .patch({
        path: `${pathImageParams}/image/${imageParamsId}`,
        headers: {
          Authorization: `bearer ${state.access_token}`,
        },
        body: data,
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });

    return response;
  };

  return {
    listAll,
    getById,
    listImageTypes,
    listActiveImages,
    createImageParams,
    updateImageParams,
    deleteImageParams,
    uploadImageParams,
  };
};

export { useImageParamsService };
