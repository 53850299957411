import AppError from '../errors/AppError';
import IChannel from '../interfaces/IChannel';
import { IPaginationReturn } from '../interfaces/IPaginationReturn';

import { useRequest } from './useRequest';

const useChannelService = () => {
  const request = useRequest<IChannel>();
  const path = 'channel';
  const listAll = async (queryParams: string): Promise<IPaginationReturn<IChannel[]>> => {
    return await request
      .getManyPaginated({ path: `${path}?${queryParams}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };
  return { listAll };
};

export { useChannelService };
