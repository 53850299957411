import React, { useEffect, useState } from 'react';
import { Button, Divider, Menu, Tooltip } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { Container, MenuItemCustom } from './AppHeader.styled';

import { useAuth } from '../../hooks/auth';
import { AppMenu } from '../app-menu/AppMenu';
import { useLocation } from 'react-router-dom';

import { useSellersService } from '../../services/useSellersService';
import { ISeller } from '../../interfaces/ISeller';
import { useToastr } from '../../hooks/useToastr';
import { useSessionStorage } from '../../services/useSessionStorage';
import logoImage from '../../assets/logo-senior.png';
import { ICustomization } from '../../interfaces/ICustomization';
import { useLocalStorage } from '../../services/useLocalStorage';

const AppHeader: React.FC = () => {
  const [sellers, setSellers] = useState<ISeller[]>([]);
  const [isOrderView, setIsOrderView] = useState(false);
  const [home, setHome] = useState(false);

  const { signOut } = useAuth();
  const location = useLocation();
  const auth = useAuth();
  const toastr = useToastr();

  const sessionStorage = useSessionStorage();
  const localStorage = useLocalStorage();

  const sellerService = useSellersService();

  const [customization] = useState<ICustomization>(() => {
    return localStorage.getItem('@PEDIDO-WEB:customization');
  });

  const [anchorUserMenu, setAnchorUserMenu] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorUserMenu);

  const listSellers = async () => {
    const url = `perPage=30&currentPage=1&orderBy=name&orderDirection=asc`;

    await sellerService
      .findByEmail(`${auth.state.email}`, url)
      .then(result => {
        if (result) {
          setSellers(result.data);
        }
      })
      .catch(error => {
        toastr.error(error.message);
      });
  };

  useEffect(() => {
    setHome(location.pathname === '/');
  }, [location]);

  useEffect(() => {
    listSellers();
    setIsOrderView(!location.pathname.includes('/order-view/'));
  }, []);

  const getSubStr = (name: string, size: number): string => {
    if (name.length > size) {
      return `${name.substring(0, size)}...`;
    } else {
      return name.substring(0, size);
    }
  };

  const handleSellerSelected = (seller: ISeller) => {
    const user = auth.state;
    user.seller = seller;
    auth.setState({ ...user });
    sessionStorage.setItem('@PEDIDO-WEB:user', { ...user });
    setAnchorUserMenu(null);
  };

  return (
    <>
      {isOrderView && (
        <Container id="header" useQuery={!!auth.state.seller}>
          <AppMenu />

          <img src={customization?.logoHeader ? customization?.logoHeader : logoImage} alt="Logo" />

          <Button
            variant="text"
            color="inherit"
            id="menu-button"
            aria-controls={open ? 'menu-button' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={e => setAnchorUserMenu(e.currentTarget)}
            endIcon={anchorUserMenu ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          >
            {sellers.length > 0 ? (
              <Tooltip
                title={`${auth.state.seller?.code} - ${auth.state.seller?.name} - ${auth.state.seller?.salesRegion?.name}`}
              >
                <span style={{ color: '#fff' }}>
                  {auth.state.seller?.code} - {getSubStr(`${auth.state.seller?.name}`, 15)}{' '}
                  {getSubStr(`${auth.state.seller?.salesRegion?.name}`, 15)}
                </span>
              </Tooltip>
            ) : (
              <span style={{ color: '#fff' }}>{auth.state.name}</span>
            )}
          </Button>
          <Menu
            id="menu-button"
            anchorEl={anchorUserMenu}
            open={open}
            onClose={() => setAnchorUserMenu(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {sellers.length > 0 && home && (
              <>
                {sellers.map(seller => {
                  return (
                    <MenuItemCustom key={seller.id} onClick={() => handleSellerSelected(seller)}>
                      {seller.code} - {seller.name} - {seller.salesRegion?.name}
                    </MenuItemCustom>
                  );
                })}

                <Divider />
              </>
            )}

            <MenuItemCustom
              sx={{ minWidth: '300px' }}
              onClick={() => {
                setAnchorUserMenu(null);
                signOut();
              }}
            >
              Sair
            </MenuItemCustom>
          </Menu>
        </Container>
      )}
    </>
  );
};

export { AppHeader };
