import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AiTwotoneEdit } from 'react-icons/ai';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import { TitleContainer } from '../../components/title-container/TitleContainer';
import IPage from '../../interfaces/IPage';
import { IconButtonMui } from './OrderTypesList.styled';
import { useCallback, useEffect, useState } from 'react';
import CustomTableSortLabel from '../../components/table/CustomTableSortLabel/CustomTableSortLabel';
import { useToastr } from '../../hooks/useToastr';
import CustomTablePagination from '../../components/table/CustomTablePagination/CustomTablePagination';
import { PageCard } from '../../components/page-card/PageCard';
import { ButtonGroup } from '../../components/button-group/ButtonGroup';
import { useOrderTypesService } from '../../services/useOrderTypesService';
import IOrderType from '../../interfaces/IOrderType';
import { BackdropCustom } from '../../components/backdrop/Backdrop';

const OrderTypesList: React.FC<IPage> = ({ title }) => {
  window.document.title = title;

  const [orderTypes, setOrderTypes] = useState<IOrderType[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderField, setOrderField] = useState<string>('id');
  const [loading, setLoading] = useState(false);

  const { findAll } = useOrderTypesService();

  const toastr = useToastr();

  const navigate = useNavigate();

  const handleList = useCallback(
    async (perPage: number, currentPage: number, orderField: string, orderDirection: 'asc' | 'desc') => {
      setLoading(true);
      await findAll(
        `perPage=${perPage}&currentPage=${currentPage + 1}&orderBy=${orderField}&orderDirection=${orderDirection}`,
      )
        .then(response => {
          const imageParams = response.data;

          if (imageParams.length > 0) {
            setOrderTypes(response.data);
            setTotalRows(response.totalRows);
            setLoading(false);
          } else {
            setOrderTypes([]);
            setTotalRows(0);
            setPerPage(10);
            setCurrentPage(0);
            setLoading(false);
          }
        })
        .catch(error => {
          toastr.error(error?.message || 'Contate a equipe de suporte');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [],
  );

  useEffect(() => {
    handleList(perPage, currentPage, orderField, orderDirection);
  }, [perPage, currentPage, orderField, orderDirection, handleList]);

  return (
    <>
      <TitleContainer>
        <h1>Tipos de Pedido</h1>
      </TitleContainer>
      <PageCard>
        <ButtonGroup>
          <IconButtonMui
            onClick={() => navigate(`/order-types/${0}/new`)}
            size="small"
            color="primary"
            style={{ alignItems: 'flex-end' }}
          >
            <AddOutlinedIcon />
          </IconButtonMui>
        </ButtonGroup>

        <TableContainer sx={{ marginTop: '2px', paddingLeft: '8px', paddingRight: '8px' }}>
          {loading && <BackdropCustom />}
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': {
                    borderBottom: 1,
                    borderColor: '#E5E5E5',
                  },
                }}
              >
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="id"
                  label="ID"
                  handleRequest={handleList}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="description"
                  label="Descrição"
                  handleRequest={handleList}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="isActive"
                  label="Ativo"
                  handleRequest={handleList}
                  orderDirection={orderDirection}
                  disableOrder
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <TableCell size="small">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderTypes &&
                orderTypes.map(orderType => {
                  return (
                    <TableRow
                      key={orderType.id}
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell size="small">{orderType?.id}</TableCell>
                      <TableCell size="small">{orderType?.description}</TableCell>
                      <TableCell size="small">{orderType?.isActive ? 'Sim' : 'Não'}</TableCell>

                      <TableCell size="small">
                        <Tooltip title="Editar" placement="top">
                          <IconButton
                            disabled={loading}
                            onClick={() => navigate(`/order-types/${Number(orderType.id)}/edit`)}
                          >
                            <AiTwotoneEdit style={{ fontSize: '15px' }} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <CustomTablePagination
            totalRows={totalRows}
            currentPage={currentPage}
            perPage={perPage}
            handleRequest={handleList}
            setPerPage={setPerPage}
            setCurrentPage={setCurrentPage}
            orderField={orderField}
            orderDirection={orderDirection}
          />
        </TableContainer>
      </PageCard>
    </>
  );
};

export { OrderTypesList };
