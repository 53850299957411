import AppError from '../errors/AppError';
import ICustomerLevel from '../interfaces/ICustomerLevel';
import { IPaginationReturn } from '../interfaces/IPaginationReturn';

import { useRequest } from './useRequest';

const useCustomerLevelService = () => {
  const request = useRequest<ICustomerLevel>();
  const path = 'customer-level';
  const listAll = async (queryParams: string): Promise<IPaginationReturn<ICustomerLevel[]>> => {
    return await request
      .getManyPaginated({ path: `${path}?${queryParams}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };
  return { listAll };
};

export { useCustomerLevelService };
