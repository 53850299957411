import { useLocation, useNavigate } from 'react-router-dom';
import { PageCard } from '../../../components/page-card/PageCard';
import { TitleContainer } from '../../../components/title-container/TitleContainer';
import IPage from '../../../interfaces/IPage';
import { BackdropCustom } from '../../../components/backdrop/Backdrop';
import InputMask from 'react-input-mask';
import {
  Button,
  Checkbox,
  DialogActions,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import IFormError from '../../../interfaces/IFormError';
import { ICustomer } from '../../../interfaces/ICustomer';
import * as yup from 'yup';
import { useValidateSchema } from '../../../services/useValidateSchema';
import React from 'react';
import { useCustomerService } from '../../../services/useCustomerService';
import { Form } from '../../../components/form/Form';
import { useToastr } from '../../../hooks/useToastr';
import { IAddressType } from '../../../interfaces/IAddressType';
import { useCustomerAddressService } from '../../../services/useCustomerAddressService';
import CustomerAddressList from './CustomerAddressList';

const CustomerForm: React.FC<IPage> = ({ title }) => {
  window.document.title = title;

  const navigate = useNavigate();
  const validateSchema = useValidateSchema();
  const location = useLocation();
  const toastr = useToastr();

  const customerService = useCustomerService();
  const customerAddressService = useCustomerAddressService();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<IFormError>({});

  const [corporateDocument, setCorporateDocument] = useState('');
  const [id, setId] = useState('');
  const [code, setCode] = useState('');
  const [corporateName, setCorporateName] = useState('');
  const [tradeName, setTradeName] = useState('');
  const [stateRegistration, setStateRegistration] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [isProspect, setIsProspect] = useState(false);
  const [addressTypeId, setAddressTypeId] = useState(1);
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');

  const [addresTypes, setAddresTypes] = useState<IAddressType[]>([]);

  const schema = yup.object({
    corporateDocument: yup.string().required('Campo obrigatório'),
    corporateName: yup.string().required('Campo obrigatório'),
    tradeName: yup.string().required('Campo obrigatório'),
    stateRegistration: yup.string(),
    phoneNumber: yup.string().required('Campo obrigatório'),
    email: yup.string().required('Campo obrigatório'),
    customerAddress: yup.array().of(
      yup.object({
        addressTypeId: yup.string().required('Campo obrigatório'),
        street: yup.string().required('Campo obrigatório'),
        number: yup.string().required('Campo obrigatório'),
        neighborhood: yup.string().required('Campo obrigatório'),
        city: yup.string().required('Campo obrigatório'),
        state: yup.string().required('Campo obrigatório'),
        postalCode: yup.string().required('Campo obrigatório'),
        country: yup.string().required('Campo obrigatório'),
      }),
    ),
  });

  const createCustomer = async (customer: ICustomer) => {
    setLoading(true);
    await customerService
      .create(customer)
      .then(() => {
        toastr.success('Cliente adicionado com sucesso');
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateCustomer = async (customer: ICustomer) => {
    setLoading(true);
    await customerService
      .update(`${customer.id}`, customer)
      .then(() => {
        toastr.success('Cliente atualizado com sucesso');
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = async () => {
    const customer: ICustomer = {
      id,
      code,
      corporateName,
      tradeName,
      corporateDocument,
      stateRegistration,
      phoneNumber,
      email,
      contact,
      isProspect,
      customerAddress: [
        {
          addressTypeId,
          street,
          number,
          complement,
          neighborhood,
          city,
          state,
          postalCode,
          country,
        },
      ],
    };

    const result = await validateSchema.validate(schema, customer);

    if (!result.isValid) {
      setErrors(result.errors ? result.errors : {});
      return;
    }

    if (id) {
      await updateCustomer(customer);
    } else {
      await createCustomer(customer);
    }

    navigate('/customers-list');
  };

  const list = async () => {
    const id = location.pathname.replace('/customers', '').replace('/', '');

    if (id) {
      setLoading(true);

      setId(id);

      await customerService
        .listById(`${id}`)
        .then(customer => {
          setCorporateDocument(customer.corporateDocument);
          setCode(customer.code);
          setCorporateName(customer.corporateName);
          setTradeName(customer.tradeName);
          setStateRegistration(customer.stateRegistration);
          setPhoneNumber(customer.phoneNumber);
          setEmail(customer.email);
          setContact(customer.contact);
          setIsProspect(customer.isProspect);
          setAddressTypeId(customer.customerAddress[0]?.addressTypeId ?? 1);
          setStreet(customer.customerAddress[0]?.street);
          setNumber(customer.customerAddress[0]?.number);
          setComplement(customer.customerAddress[0]?.complement);
          setNeighborhood(customer.customerAddress[0]?.neighborhood);
          setCity(customer.customerAddress[0]?.city);
          setState(customer.customerAddress[0]?.state);
          setPostalCode(customer.customerAddress[0]?.postalCode);
          setCountry(customer.customerAddress[0]?.country);
        })
        .catch(error => {
          toastr.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const listCNPJ = async () => {
    setLoading(true);

    await customerService
      .CNPJaQuery(`${corporateDocument.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '')}`)
      .then(data => {
        setCorporateName(data?.name ?? '');
        if (data?.registrations) {
          setStateRegistration(data?.registrations[0]?.number ?? '');
        }
        if (data?.alias) {
          setTradeName(data?.alias);
        }
        if (data.phones !== undefined) {
          setPhoneNumber(data.phones[0].area + data.phones[0].number);
        }
        if (data.emails !== undefined) {
          setEmail(data.emails[0].address);
        }
        setAddressTypeId(1);
        setStreet(data.address?.street ?? '');
        setNumber(data.address?.number ?? '');
        setComplement(data.address?.details ?? '');
        setNeighborhood(data.address?.district ?? '');
        setCity(data.address?.city ?? '');
        setState(data.address?.state ?? '');
        setPostalCode(data.address?.zip ?? '');
        setCountry(data.address?.country?.name ?? '');
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const listAddressTypes = async () => {
    setLoading(true);

    await customerAddressService
      .listAllAddressTypes()
      .then(addressType => {
        setAddresTypes(addressType.data);
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    list();
    listAddressTypes();
  }, []);

  return (
    <>
      <TitleContainer>
        <h1>Cliente</h1>
      </TitleContainer>
      <PageCard>
        <Form>
          {loading && <BackdropCustom />}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} sm={12}>
              <InputMask
                mask={'99.999.999/9999-99'}
                value={corporateDocument}
                onChange={e => setCorporateDocument(e.target.value)}
                onBlur={() => listCNPJ()}
                maskPlaceholder={null}
              >
                <TextField
                  fullWidth
                  size="small"
                  label="CNPJ"
                  autoComplete="off"
                  helperText={errors?.corporateDocument}
                  error={!!errors?.corporateDocument}
                />
              </InputMask>
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <TextField
                sx={{ width: '100%' }}
                size="small"
                label="Código"
                value={code}
                onChange={e => setCode(e.target.value)}
                disabled
                autoComplete="off"
                helperText={errors.code}
                error={!!errors.usernacodeme}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <TextField
                sx={{ width: '100%' }}
                size="small"
                label="Razão Social"
                value={corporateName}
                onChange={e => setCorporateName(e.target.value)}
                autoComplete="off"
                helperText={errors.corporateName}
                error={!!errors.corporateName}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <TextField
                sx={{ width: '100%' }}
                size="small"
                label="Fantasia"
                value={tradeName}
                onChange={e => setTradeName(e.target.value)}
                autoComplete="off"
                helperText={errors.tradeName}
                error={!!errors.tradeName}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <TextField
                sx={{ width: '100%' }}
                size="small"
                label="Incrição Estadual"
                value={stateRegistration}
                onChange={e => setStateRegistration(e.target.value)}
                autoComplete="off"
                helperText={errors.stateRegistration}
                error={!!errors.stateRegistration}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <InputMask
                mask={'(99) 9999-9999'}
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
                maskPlaceholder={null}
              >
                <TextField
                  fullWidth
                  size="small"
                  label="Telefone"
                  autoComplete="off"
                  helperText={errors.phoneNumber}
                  error={!!errors.phoneNumber}
                />
              </InputMask>
            </Grid>

            <Grid item xs={12} md={6} sm={6}>
              <TextField
                sx={{ width: '100%' }}
                size="small"
                label="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                autoComplete="off"
                helperText={errors.email}
                error={!!errors.email}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <TextField
                sx={{ width: '100%' }}
                size="small"
                label="Contato"
                value={contact}
                onChange={e => setContact(e.target.value)}
                autoComplete="off"
                helperText={errors.contact}
                error={!!errors.contact}
              />
            </Grid>

            <Grid item xs={12} md={6} sm={6}>
              <FormControl sx={{ width: '100%' }} size="small">
                <InputLabel id="select-filter">Tipo de endereço</InputLabel>
                <Select
                  label="Tipo de endereço"
                  value={addressTypeId}
                  onChange={e => {
                    setAddressTypeId(Number(e.target.value));
                  }}
                >
                  {addresTypes.map(addresType => {
                    return (
                      <MenuItem key={addresType.id} value={addresType.id}>
                        {`${addresType.id} - ${addresType.description}`}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <TextField
                sx={{ width: '100%' }}
                size="small"
                label="Rua"
                value={street}
                onChange={e => setStreet(e.target.value)}
                autoComplete="off"
                helperText={errors.street}
                error={!!errors.street}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <TextField
                sx={{ width: '100%' }}
                size="small"
                label="Número"
                value={number}
                onChange={e => setNumber(e.target.value)}
                autoComplete="off"
                helperText={errors.number}
                error={!!errors.number}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <TextField
                sx={{ width: '100%' }}
                size="small"
                label="Complemento"
                value={complement}
                onChange={e => setComplement(e.target.value)}
                autoComplete="off"
                helperText={errors.complement}
                error={!!errors.complement}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <TextField
                sx={{ width: '100%' }}
                size="small"
                label="Bairro"
                value={neighborhood}
                onChange={e => setNeighborhood(e.target.value)}
                autoComplete="off"
                helperText={errors.neighborhood}
                error={!!errors.neighborhood}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <TextField
                sx={{ width: '100%' }}
                size="small"
                label="Cidade"
                value={city}
                onChange={e => setCity(e.target.value)}
                autoComplete="off"
                helperText={errors.city}
                error={!!errors.city}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <TextField
                sx={{ width: '100%' }}
                size="small"
                label="Estado"
                value={state}
                onChange={e => setState(e.target.value)}
                autoComplete="off"
                helperText={errors.state}
                error={!!errors.state}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <InputMask mask={'99999-999'} value={postalCode} onChange={e => setPostalCode(e.target.value)}>
                <TextField
                  sx={{ width: '100%' }}
                  fullWidth
                  size="small"
                  label="CEP"
                  autoComplete="off"
                  helperText={errors.postalCode}
                  error={!!errors.postalCode}
                />
              </InputMask>
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <TextField
                sx={{ width: '100%' }}
                size="small"
                label="País"
                value={country}
                onChange={e => setCountry(e.target.value)}
                autoComplete="off"
                helperText={errors.country}
                error={!!errors.country}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <FormControlLabel
                label="É Prospecto"
                control={<Checkbox onChange={e => setIsProspect(e.target.checked)} checked={isProspect} />}
              />
            </Grid>
          </Grid>

          <DialogActions>
            <Button onClick={() => navigate('/customers-list')} color="inherit" disabled={loading}>
              Cancelar
            </Button>
            <Button onClick={() => handleSubmit()}>Confirmar</Button>
          </DialogActions>
        </Form>
      </PageCard>

      {street && <CustomerAddressList />}
    </>
  );
};

export default CustomerForm;
