import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  position: relative;

  h1 {
    padding: 0;
    margin: 0;
    font-size: 1.25rem;
    color: #999999;
    font-weight: normal;
  }
`;
