import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { TitleContainer } from '../../components/title-container/TitleContainer';
import IPage from '../../interfaces/IPage';
import { useCallback, useEffect, useState } from 'react';
import CustomTableSortLabel from '../../components/table/CustomTableSortLabel/CustomTableSortLabel';
import { useToastr } from '../../hooks/useToastr';
import CustomTablePagination from '../../components/table/CustomTablePagination/CustomTablePagination';
import { PageCard } from '../../components/page-card/PageCard';
import { useSellersService } from '../../services/useSellersService';
import { ISeller } from '../../interfaces/ISeller';
import { formatPhone } from '../../util/formatPhone';
import { BackdropCustom } from '../../components/backdrop/Backdrop';

const SellersList: React.FC<IPage> = ({ title }) => {
  window.document.title = title;

  const [selles, setSelles] = useState<ISeller[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderField, setOrderField] = useState<string>('code');
  const [loading, setLoading] = useState(false);

  const { findAll } = useSellersService();

  const toastr = useToastr();

  const handleList = useCallback(
    async (perPage: number, currentPage: number, orderField: string, orderDirection: 'asc' | 'desc') => {
      setLoading(true);
      await findAll(
        `perPage=${perPage}&currentPage=${currentPage + 1}&orderBy=${orderField}&orderDirection=${orderDirection}`,
      )
        .then(response => {
          const imageParams = response.data;

          if (imageParams.length > 0) {
            setSelles(response.data);
            setTotalRows(response.totalRows);
            setLoading(false);
          } else {
            setSelles([]);
            setTotalRows(0);
            setPerPage(10);
            setCurrentPage(0);
            setLoading(false);
          }
        })
        .catch(error => {
          toastr.error(error?.message || 'Contate a equipe de suporte');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [],
  );

  useEffect(() => {
    handleList(perPage, currentPage, orderField, orderDirection);
  }, [perPage, currentPage, orderField, orderDirection, handleList]);

  return (
    <>
      <TitleContainer>
        <h1>Listagem de Representantes</h1>
      </TitleContainer>
      <PageCard>
        <TableContainer sx={{ marginTop: '2px', paddingLeft: '8px', paddingRight: '8px' }}>
          {loading && <BackdropCustom />}
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': {
                    borderBottom: 1,
                    borderColor: '#E5E5E5',
                  },
                }}
              >
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="code"
                  label="Código"
                  handleRequest={handleList}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="name"
                  label="Nome"
                  handleRequest={handleList}
                  orderDirection={orderDirection}
                  disableOrder
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="email"
                  label="E-mail"
                  handleRequest={handleList}
                  orderDirection={orderDirection}
                  disableOrder
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="phoneNumber"
                  label="Número de Telefone"
                  handleRequest={handleList}
                  orderDirection={orderDirection}
                  disableOrder
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {selles &&
                selles.map(selle => {
                  return (
                    <TableRow
                      key={selle.id}
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell size="small">{selle?.code}</TableCell>
                      <TableCell size="small">{selle?.name}</TableCell>
                      <TableCell size="small">{selle?.email}</TableCell>
                      <TableCell size="small">{formatPhone(selle?.phoneNumber)}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <CustomTablePagination
            totalRows={totalRows}
            currentPage={currentPage}
            perPage={perPage}
            handleRequest={handleList}
            setPerPage={setPerPage}
            setCurrentPage={setCurrentPage}
            orderField={orderField}
            orderDirection={orderDirection}
          />
        </TableContainer>
      </PageCard>
    </>
  );
};

export { SellersList };
