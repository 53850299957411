import { HTMLAttributes, useCallback, useState } from 'react';
import {
  BodyCard,
  BodyCardInfo,
  BottonCustom,
  Container,
  Content,
  DownloadArea,
  HeaderCard,
} from './CustomerReceivableAccounts.styles';
import { FiFileText } from 'react-icons/fi';
import moment from 'moment';

import CustomTableNoContent from '../../../components/table/CustomTableNoContent/CustomTableNoContent';
import IReceivableAccount from '../../../interfaces/IReceivableAccount';

interface ICustomerReceivableAccountsProps extends HTMLAttributes<HTMLDivElement> {
  receivableAccounts: IReceivableAccount[];
}

const CustomerReceivableAccounts: React.FC<ICustomerReceivableAccountsProps> = ({ receivableAccounts }) => {
  const [os] = useState(() => {
    const toMatch = [/Macintosh/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
    return toMatch.some(toMatchItem => {
      return navigator.userAgent.match(toMatchItem);
    });
  });

  const receivableAccountDisabledDownload = (receivableAccountStatusId: string) => {
    return (
      receivableAccountStatusId === 'LQ' ||
      receivableAccountStatusId === 'LC' ||
      receivableAccountStatusId === 'LP' ||
      receivableAccountStatusId === 'LS'
    );
  };

  const downloadPDF = useCallback(
    (file: string | null, receivableAccountNumber: string) => {
      // Obter o base64 do arquivo PDF do estado ou de onde você o armazena
      const base64PDF = file;

      if (base64PDF) {
        // Converter o base64 em blob
        const byteCharacters = atob(base64PDF);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        // Criar um URL do blob e realizar o download
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        if (os) {
          link.target = '_blank';
          link.click();
        } else {
          link.download = `${receivableAccountNumber.replaceAll('/', '')}.pdf`;
          link.click();
        }

        // Limpar o URL do blob após o download
        URL.revokeObjectURL(url);
      }
    },
    [os],
  );

  return (
    <Container>
      {receivableAccounts.length > 0 ? (
        <>
          {receivableAccounts.map(receivableAccount => (
            <Content key={receivableAccount.id}>
              <div>
                <HeaderCard>
                  <div className="receivable-account-area">
                    <strong className="receivable-account">{receivableAccount.receivableNumber}</strong>
                  </div>
                </HeaderCard>
                <BodyCard>
                  <BodyCardInfo>
                    <span>{`${moment(receivableAccount.dueDate).add(1, 'days').format('DD/MM/YYYY')}`}</span>
                    <span>
                      <strong>
                        {`R$ ${(receivableAccount.originalValue ? receivableAccount.originalValue : 0).toLocaleString(
                          'pt-BR',
                          {
                            style: 'decimal',
                            minimumFractionDigits: 2,
                          },
                        )}`}
                      </strong>
                    </span>
                    <span>{receivableAccount.status?.description}</span>
                  </BodyCardInfo>
                </BodyCard>
              </div>

              <DownloadArea>
                {receivableAccount.pdf &&
                  receivableAccount.pdf.length > 50 &&
                  !receivableAccountDisabledDownload(receivableAccount.statusId) && (
                    <BottonCustom
                      onClick={() => {
                        downloadPDF(receivableAccount.pdf, receivableAccount.receivableNumber);
                      }}
                    >
                      <FiFileText size={24} />
                    </BottonCustom>
                  )}
              </DownloadArea>
            </Content>
          ))}
        </>
      ) : (
        <CustomTableNoContent text="Nenhum título para listar..." />
      )}
    </Container>
  );
};

export default CustomerReceivableAccounts;
