import React, { useEffect, useRef, useState } from 'react';
import IPage from '../../interfaces/IPage';
import { useMeasurementUnitService } from '../../services/useMeasurementUnitService';
import { useToastr } from '../../hooks/useToastr';
import IMeasurementUnit from '../../interfaces/IMeasurementUnit';
import { TitleContainer } from '../../components/title-container/TitleContainer';
import { PageCard } from '../../components/page-card/PageCard';
import { AiTwotoneEdit } from 'react-icons/ai';
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import CustomTableSortLabel from '../../components/table/CustomTableSortLabel/CustomTableSortLabel';
import CustomTablePagination from '../../components/table/CustomTablePagination/CustomTablePagination';
import MeasurementUniForm from './MeasurementUniForm';
import { BackdropCustom } from '../../components/backdrop/Backdrop';

const fields = [
  {
    field: 'id',
    label: 'Código',
  },
  {
    field: 'description',
    label: 'Descrição',
  },
];

interface IParams {
  perPage: number;
  currentPage: number;
  orderField: string;
  orderDirection: 'asc' | 'desc';
  field?: string;
  value?: string;
  precision?: string;
  delay?: number;
}

const MeasurementUnitList: React.FC<IPage> = ({ title }) => {
  window.document.title = title;
  const { listAll } = useMeasurementUnitService();
  const toastr = useToastr();

  const timeout = useRef<any>(null);
  const [measurementUnits, setMeasurementUnits] = useState<IMeasurementUnit[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderField, setOrderField] = useState<string>('description');
  const [loading, setLoading] = useState(false);

  const [filterField, setFilterField] = useState<string>('');
  const [filterPrecision, setFilterPrecision] = useState<string>('');
  const [filterValue, setFilterValue] = useState<string>('');
  const [delay, setDelay] = useState(0);
  const [formIsOpen, setFormIsOpen] = useState<boolean>(false);
  const [id, setId] = useState<string | null>(null);

  const handlePagination = async (
    perPage: number,
    currentPage: number,
    orderField: string,
    orderDirection: 'asc' | 'desc',
  ) => {
    handleListAll({
      perPage,
      currentPage,
      orderField,
      orderDirection,
      field: filterField,
      value: filterValue,
      precision: filterPrecision,
      delay: 0,
    });
  };

  const handleListAll = async ({
    perPage,
    currentPage,
    orderField,
    orderDirection,
    field,
    value,
    precision,
    delay,
  }: IParams) => {
    let url = `perPage=${perPage}&currentPage=${
      currentPage + 1
    }&orderBy=${orderField}&orderDirection=${orderDirection}`;

    if (field && value && precision) {
      url = url + `&filterField=${field}&filterValue=${value}&precision=${precision}`;
    }

    clearInterval(timeout.current);
    timeout.current = setTimeout(async () => {
      setLoading(true);

      await listAll(url)
        .then(response => {
          if (response?.data?.length > 0) {
            setMeasurementUnits(response.data);
            setTotalRows(response.totalRows);
            setLoading(false);
          } else {
            setMeasurementUnits([]);
            setTotalRows(0);
            setPerPage(10);
            setCurrentPage(0);
            setLoading(false);
          }
        })
        .catch(error => {
          toastr.error(error?.message || 'Contate a equipe de suporte');
        })
        .finally(() => {
          setLoading(false);
        });
    }, delay || 0);
  };

  useEffect(() => {
    const field = filterField;
    const value = filterValue;
    const precision = filterPrecision;

    handleListAll({ perPage, currentPage, orderField, orderDirection, field, value, precision, delay });
  }, [filterField, filterPrecision, filterValue, delay, formIsOpen]);

  const handleTextFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
    setTotalRows(0);
    setCurrentPage(0);
    setDelay(500);
  };

  return (
    <>
      <TitleContainer>
        <h1>Unidades de Medida</h1>
      </TitleContainer>
      <PageCard>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel id="select-filter">Filtrar por</InputLabel>
              <Select
                label="Filtrar Por"
                value={`${filterField}`}
                onChange={e => {
                  setFilterField(`${e.target.value}`);
                }}
              >
                {fields.map(filter => {
                  return (
                    <MenuItem key={filter.field} value={filter.field}>
                      {filter.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Precisão</InputLabel>
              <Select
                label="Precisão"
                value={`${filterPrecision}`}
                onChange={e => {
                  setFilterPrecision(`${e.target.value}`);
                }}
              >
                <MenuItem key={'equal'} value={'equal'}>
                  Igual
                </MenuItem>
                <MenuItem key={'containing'} value={'containing'}>
                  Contendo
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={7}>
            <TextField
              fullWidth
              label="Valor a filtrar"
              placeholder="Valor a filtrar"
              value={filterValue}
              onChange={handleTextFilterChange}
              size="small"
            />
          </Grid>
        </Grid>
        <TableContainer sx={{ marginTop: '2px', paddingLeft: '8px', paddingRight: '8px' }}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': {
                    borderBottom: 1,
                    borderColor: '#E5E5E5',
                  },
                }}
              >
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="id"
                  label="Código"
                  orderDirection={orderDirection}
                  handleRequest={handlePagination}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="description"
                  label="Descrição"
                  handleRequest={handlePagination}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="precision"
                  label="Precisão"
                  handleRequest={handlePagination}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {measurementUnits &&
                measurementUnits.map(measurementUnit => {
                  return (
                    <TableRow
                      key={measurementUnit.id}
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell size="small">{measurementUnit.id}</TableCell>
                      <TableCell size="small">{measurementUnit.description}</TableCell>
                      <TableCell size="small">{measurementUnit.precision}</TableCell>
                      <TableCell size="small">
                        <Tooltip title="Editar" placement="top">
                          <IconButton
                            disabled={loading}
                            onClick={() => {
                              setId(measurementUnit.id);
                              setFormIsOpen(true);
                            }}
                          >
                            <AiTwotoneEdit style={{ fontSize: '15px' }} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <CustomTablePagination
            totalRows={totalRows}
            currentPage={currentPage}
            perPage={perPage}
            handleRequest={handlePagination}
            setPerPage={setPerPage}
            setCurrentPage={setCurrentPage}
            orderField={orderField}
            orderDirection={orderDirection}
          />
        </TableContainer>
      </PageCard>

      {loading && <BackdropCustom />}
      <MeasurementUniForm isOpen={formIsOpen} setIsOpen={setFormIsOpen} id={id} />
    </>
  );
};

export default MeasurementUnitList;
