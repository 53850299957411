import React, { useCallback, useEffect, useState } from 'react';
import { Button, DialogActions, DialogContent } from '@mui/material';

import { Container, EventCard, NoneEvents, Title } from './OrderEventsDialog.styled';
import { IEvent } from '../../../interfaces/IEvent';
import { useToastr } from '../../../hooks/useToastr';
import { useOrderEventsService } from '../../../services/useOrderEventService';
import moment from 'moment';
import { BackdropCustom } from '../../../components/backdrop/Backdrop';

interface IOrderEventsDialogProps {
  dialogOpen: boolean;
  orderId: string;
  handleCloseDialog: () => void;
  handleOpenDialog: () => void;
}

const OrderEventsDialog: React.FC<IOrderEventsDialogProps> = ({
  dialogOpen,
  orderId,
  handleCloseDialog,
  handleOpenDialog,
}) => {
  const [events, setEvents] = useState<IEvent[]>([]);
  const [loading, setLoading] = useState(false);

  const toastr = useToastr();

  const { listOrderEvent } = useOrderEventsService();

  const handleListEvents = useCallback(async () => {
    setLoading(true);
    await listOrderEvent(orderId, true)
      .then(response => {
        setEvents(response);
      })
      .catch(error => {
        toastr.error(error?.message || 'Contate a equipe de suporte');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [orderId]);

  useEffect(() => {
    if (dialogOpen) {
      handleListEvents();
    }
  }, [dialogOpen]);

  return (
    <>
      {loading ? (
        <BackdropCustom />
      ) : (
        <Container
          open={dialogOpen}
          onClose={handleOpenDialog}
          sx={{
            '& .MuiDialog-container': {
              width: '100vw',
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}
        >
          <DialogContent>
            <Title>Histórico de alteração dos status</Title>

            {events && events.length > 0 ? (
              events.map(event => {
                return (
                  <EventCard key={event.id}>
                    <div>
                      <strong>
                        {event.orderStatus && event.orderStatus?.description
                          ? event.orderStatus.description
                          : 'Status não informado'}
                      </strong>
                      <span>{moment(event.created_at).format('DD/MM/YYYY HH:mm')}</span>
                    </div>
                    <span>{event.message}</span>
                  </EventCard>
                );
              })
            ) : (
              <NoneEvents>
                <span>Nenhum evento para listar...</span>
              </NoneEvents>
            )}
          </DialogContent>
          <DialogActions sx={{ marginRight: '10px' }}>
            <Button color="info" onClick={handleCloseDialog} sx={{ textTransform: 'none' }}>
              Fechar
            </Button>
          </DialogActions>
        </Container>
      )}
    </>
  );
};

export { OrderEventsDialog };
