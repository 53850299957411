import AppError from '../errors/AppError';
import { useCustomerPortalAuth } from '../hooks/custmerPortalAuth';
import IDashboardReport from '../interfaces/IDashboardReport';
import { useRequest } from './useRequest';

const useCustomerPortalDashboardService = () => {
  const requestsDashboard = useRequest<IDashboardReport>();
  const pathDashboard = 'customer-portal/dashboard';
  const { customerPortalSession, customerPortalCustomerSelected } = useCustomerPortalAuth();

  const listDashboard = async (): Promise<IDashboardReport> => {
    const response = await requestsDashboard
      .getOne({
        path: `${pathDashboard}`,
        headers: {
          Authorization: `bearer ${customerPortalSession.token.access_token}`,
          tenant: customerPortalSession.user.tenant,
          customer: customerPortalCustomerSelected.id,
        },
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });

    return response;
  };

  return { listDashboard };
};

export { useCustomerPortalDashboardService };
