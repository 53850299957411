import AppError from '../errors/AppError';
import { useAuth } from '../hooks/auth';
import IInvoice from '../interfaces/IInvoice';
import { IPaginationReturn } from '../interfaces/IPaginationReturn';
import { useRequest } from './useRequest';

const useOrderInvoicesService = () => {
  const requestOrdersInvoices = useRequest<IInvoice>();
  const pathInvoices = 'invoices';
  const { state } = useAuth();

  const listOrderInvoice = async (orderId: string, useAuth = false): Promise<IInvoice[]> => {
    return await requestOrdersInvoices
      .getMany({ path: `${pathInvoices}/order/${orderId}`, sendAuthorization: useAuth })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message, error.status);
      });
  };

  const listInvoiceByCustomerId = async (
    customerId: string,
    queryParams: string,
  ): Promise<IPaginationReturn<IInvoice[]>> => {
    const response = await requestOrdersInvoices
      .getManyPaginated({
        path: `${pathInvoices}/customer/${customerId}?${queryParams}`,
        headers: {
          Authorization: `bearer ${state.access_token}`,
        },
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });

    return response;
  };

  return { listOrderInvoice, listInvoiceByCustomerId };
};

export { useOrderInvoicesService };
