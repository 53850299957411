import React from 'react';
import { IActiveImages } from '../../interfaces/IActiveImages';
import { ImageLink } from './Order.styled';

interface IProps {
  activeImages: IActiveImages;
}

const OrderFooter = (props: IProps) => {
  return (
    <ImageLink href={props.activeImages?.footerLink} target="blank">
      <img src={props.activeImages.footerImage} width="100%" alt="Cabeçalho" style={{ height: '30px' }} />
    </ImageLink>
  );
};

export default OrderFooter;
