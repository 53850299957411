import { Box } from '@mui/material';

import styled from 'styled-components';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@mui/material/Button';

export const Container = styled.div``;

export const ButtonMenu = styled(Button)`
  && {
    margin-left: 10px;
    svg {
      color: #fff;
      height: 40px;
    }
  }
`;

export const BoxMui = styled(Box)`
  && {
    background: var(--main-color);
    flex: 1;
    color: #fff;
    width: 300px;
  }
`;

export const ListItemIconMui = styled(ListItemIcon)`
  && {
    svg {
      color: #fff;
    }
  }
`;

export const SubItemMenu = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonMui = styled(Button)`
  && {
    background: var(--main-color);
    height: 50px;
    display: flex;
    justify-content: flex-start;
    padding-left: 73px;

    svg {
      color: #fff;
      height: 40px;
    }

    span {
      font-weight: 400;
      text-transform: none;
      color: #fff;
    }

    :hover {
      background: var(--hover-color);
    }
  }
`;
