import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import {
  Container,
  Content,
  LogoImg,
  ButtonCustom,
  Input,
  SignInLink,
  TextFieldCustom,
} from './CustomerPortalForgotPassword.styles';
import logoLoginDefault from '../../../assets/logoLogin.svg';
import IFormError from '../../../interfaces/IFormError';
import { useToastr } from '../../../hooks/useToastr';
import getValidationError from '../../../util/getValidationError';
import IPage from '../../../interfaces/IPage';
import { useCustomerPortalUserPasswordService } from '../../../services/useCustomerPortalUserPasswordService';
import { useOpenedRoutesService } from '../../../services/useOpenedRoutesService';
import { BackdropCustom } from '../../../components/backdrop/Backdrop';

const CustomerPortalForgotPassword: React.FC<IPage> = ({ title }) => {
  window.document.title = title;

  const navigate = useNavigate();
  const toastr = useToastr();
  const location = useLocation();

  const customerPortalUserPasswordService = useCustomerPortalUserPasswordService();
  const { getCustomization } = useOpenedRoutesService();

  const [tenant] = useState(() => {
    return process.env.REACT_APP_CLIENT_TENANT_DOMAIN
      ? process.env.REACT_APP_CLIENT_TENANT_DOMAIN
      : location.search.replaceAll('?tenant=', '');
  });

  const [email, setEmail] = useState('');

  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErros] = useState<IFormError>({});

  const [loadingCustom, setLoadingCustom] = useState(false);
  const [logoLogin, setLogoLogin] = useState<string | null>(null);

  const handleSubmit = useCallback(async (): Promise<void> => {
    setLoading(true);
    setFormErros({});

    try {
      const data = {
        email,
        tenant,
      };

      const schema = Yup.object().shape({
        email: Yup.string().required('E-mail obrigatório').email('Informe um e-mail válido'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await customerPortalUserPasswordService
        .forgotPassword(data)
        .then(() => {
          toastr.success(`Email enviado com sucesso`);
          navigate(`/customer-portal/signin?tenant=${tenant}`);
        })
        .catch(error => {
          toastr.error(error.message || 'Ocorreu um erro ao recuperar a senha.');
        });
    } catch (err: Yup.ValidationError | any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationError(err);
        setFormErros(errors);
        return;
      }

      toastr.error('Ocorreu um erro ao fazer login, cheque as credênciais.');
    } finally {
      setLoading(false);
    }
  }, [email, tenant, navigate, toastr]);

  const handleGetCustomization = useCallback(async () => {
    setLoadingCustom(true);
    await getCustomization(btoa(tenant))
      .then(customization => {
        localStorage.setItem('@PEDIDO-WEB:customization', JSON.stringify(customization));

        setLogoLogin(customization.logoLogin);

        if (customization) {
          document.documentElement.style.setProperty('--main-color', `${customization?.mainColor}` || '#088671');
          document.documentElement.style.setProperty('--hover-color', `${customization?.hoverColor}` || '#08816d');
        }
      })
      .catch(error => {
        toastr.error(`${error.message}. Contate o suporte.` || 'Erro ao obter domínio. Contate o suporte.');
      })
      .finally(() => {
        setLoadingCustom(false);
      });
  }, [tenant]);

  useEffect(() => {
    if (tenant) {
      handleGetCustomization();
    }
  }, [tenant]);

  return (
    <Container>
      {loadingCustom ? (
        <BackdropCustom />
      ) : (
        <Content elevation={5}>
          <LogoImg>
            <img src={logoLogin || logoLoginDefault} alt="Portal de Boletos" />
          </LogoImg>

          <form>
            <Input>
              <label>Endereço de e-mail</label>
              <TextFieldCustom
                size="small"
                value={email}
                onChange={e => setEmail(e.target.value.toLowerCase())}
                autoComplete="off"
                helperText={formErrors.email}
                error={!!formErrors.email}
                autoFocus
                id="email"
              />
            </Input>

            <ButtonCustom onClick={handleSubmit} loading={loading} variant="contained" id="submit">
              Confirmar
            </ButtonCustom>
          </form>

          <SignInLink>
            <Link to={`/customer-portal/signin?tenant=${tenant}`}>
              <KeyboardBackspaceIcon />
              Voltar para tela de login
            </Link>
          </SignInLink>
        </Content>
      )}
    </Container>
  );
};

export { CustomerPortalForgotPassword };
