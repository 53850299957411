import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, TextField } from '@mui/material';
import { ChangeEvent, forwardRef, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useAuth } from '../../hooks/auth';
import IPage from '../../interfaces/IPage';
import { Form } from '../../components/form/Form';
import { useToastr } from '../../hooks/useToastr';
import IFormError from '../../interfaces/IFormError';
import { Button } from '../../components/button/Button';
import { IPriceTableItem } from '../../interfaces/IPriceTableItem';
import { TitleContainerCustom } from './PriceTableItem.styled';
import { PageCard } from '../../components/page-card/PageCard';
import { BackdropCustom } from '../../components/backdrop/Backdrop';
import { ButtonGroup } from '../../components/button-group/ButtonGroup';
import getValidationError from '../../util/getValidationError';
import { usePriceTableItemsService } from '../../services/usePriceTableItemsService';
import { IProduct } from '../../interfaces/IProduct';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={'.'}
      decimalScale={2}
      decimalSeparator=","
      valueIsNumericString
      fixedDecimalScale
    />
  );
});

const PriceTableItem: React.FC<IPage> = ({ title }) => {
  window.document.title = title;

  const navigate = useNavigate();
  const location = useLocation();
  const toastr = useToastr();
  const { signOut } = useAuth();

  const { listPriceTableItem, updatePriceTableItem } = usePriceTableItemsService();

  const [priceTableItemId, setPriceTableItemId] = useState('');
  const [priceTableId, setPriceTableId] = useState('');
  const [product, setProduct] = useState<IProduct>({});
  const [basePrice, setBasePrice] = useState('');
  const [isActive, setIsActive] = useState(true);

  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState<IFormError>({});

  const handleListPriceTableItem = useCallback(async () => {
    const id = location.pathname.replace('/price-table-item', '').replace('/', '');

    setPriceTableItemId(id);

    if (id) {
      setLoading(true);

      await listPriceTableItem(id)
        .then(response => {
          const priceTableItem: IPriceTableItem = response;

          setPriceTableId(priceTableItem.priceTableId);
          setProduct(priceTableItem.product || {});
          setIsActive(priceTableItem.isActive);
          setBasePrice(priceTableItem.basePrice > 0 ? String(priceTableItem.basePrice) : '');
        })
        .catch(error => {
          if (error.status === 401) {
            signOut();
            navigate('/');
          }

          toastr.error(error?.message || 'Contate a equipe de suporte');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [navigate, signOut, toastr, location.pathname]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    setFormErrors({});
    try {
      const data = {
        priceTableId,
        productId: product.id || '',
        basePrice: Number(basePrice),
        isActive,
      };

      const schema = Yup.object().shape({
        basePrice: Yup.number()
          .required('Campo obrigatório')
          .test('len', 'Deve ser informado um maior que 0,00', val => !!val && !!(Number(val) > 0.0)),
        isActive: Yup.string().required(''),
      });

      if (priceTableItemId) {
        await schema.validate(data, {
          abortEarly: false,
        });

        await updatePriceTableItem(priceTableItemId, data)
          .then(async () => {
            toastr.success('Tabela de preço atualizado com sucesso');
          })
          .catch(error => {
            if (error.status === 401) {
              signOut();
              navigate('/');
            }
            toastr.error(error?.message || 'Contate a equipe de suporte');
          });
      }
    } catch (error: Yup.ValidationError | any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationError(error);
        setFormErrors(errors);
        return;
      }
      toastr.error(error?.message || 'Contate a equipe de suporte');
    } finally {
      setLoading(false);
    }
  }, [priceTableId, product, basePrice, isActive, navigate, signOut]);

  const handleChangeIsActive = (event: ChangeEvent<HTMLInputElement>) => {
    setIsActive(event.target.checked);
  };

  useEffect(() => {
    handleListPriceTableItem();
  }, []);

  return (
    <>
      <TitleContainerCustom>
        <h1>Item Tabela de Preço</h1>
      </TitleContainerCustom>

      <PageCard>
        <Form>
          <Grid container spacing={3} sx={{ marginBottom: '24px' }}>
            <Grid item xs={12} md={6} sm={6}>
              <TextField
                sx={{ width: '100%' }}
                size="small"
                label="Descrição"
                value={`${product.sku} - ${product.description} - ${product.derivation}`}
                autoFocus
                required
                disabled
              />
            </Grid>

            <Grid item xs={12} md={6} sm={6}>
              <FormControl sx={{ width: '100%' }} size="small" error={!!formErrors.basePrice}>
                <TextField
                  style={{ width: '100%' }}
                  label="Preço Base"
                  value={basePrice}
                  onChange={event => setBasePrice(event.target.value)}
                  name="value"
                  size="small"
                  id="base-price-input"
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                  }}
                  variant="outlined"
                  error={!!formErrors.basePrice}
                />
                <FormHelperText>{formErrors.basePrice}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} sm={6}>
              <FormControlLabel
                label="Ativo"
                control={<Checkbox checked={isActive} onChange={handleChangeIsActive} />}
              />
            </Grid>
          </Grid>

          <ButtonGroup>
            <Button
              onClick={() => {
                navigate(`/price-table/${priceTableId}`);
              }}
              disabled={loading}
              variant="contained"
              color="inherit"
            >
              Voltar
            </Button>
            <Button onClick={handleSubmit} disabled={loading} variant="contained">
              Confirmar
            </Button>
          </ButtonGroup>
        </Form>
      </PageCard>

      {loading && <BackdropCustom />}
    </>
  );
};
export { PriceTableItem };
