import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const IconButtonMui = styled(IconButton)`
  && {
    span {
      text-transform: none;
    }
  }
`;
