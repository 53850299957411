import React, { HtmlHTMLAttributes } from 'react';

import { UserContextProvider } from './auth';
import { TenantDomainContextProvider } from './TenantDomainProvider';
import { CustomerPortalAuthProvider } from './custmerPortalAuth';

interface IAppProvider extends HtmlHTMLAttributes<HTMLElement> {}

const AppProvider: React.FC<IAppProvider> = ({ children }) => {
  return (
    <TenantDomainContextProvider>
      <CustomerPortalAuthProvider>
        <UserContextProvider>{children}</UserContextProvider>
      </CustomerPortalAuthProvider>
    </TenantDomainContextProvider>
  );
};

export { AppProvider };
