import AppError from '../errors/AppError';
import { IAddressType } from '../interfaces/IAddressType';
import { ICustomerAddress } from '../interfaces/ICustomerAddress';
import { IPaginationReturn } from '../interfaces/IPaginationReturn';

import { useRequest } from './useRequest';

const useCustomerAddressService = () => {
  const request = useRequest<ICustomerAddress>();
  const requestAddressTypes = useRequest<IAddressType>();

  const path = 'customer-addresses';

  const listAll = async (queryParams: string): Promise<IPaginationReturn<ICustomerAddress[]>> => {
    return await request
      .getManyPaginated({ path: `${path}?${queryParams}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const listById = async (id: string): Promise<ICustomerAddress> => {
    return await request
      .getOne({ path: `${path}/${id}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const create = async (customer: ICustomerAddress): Promise<ICustomerAddress> => {
    return await request
      .post({ path, sendAuthorization: true, body: customer })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const update = async (id: string, customer: ICustomerAddress): Promise<ICustomerAddress> => {
    return await request
      .put({ path: `${path}/${id}`, sendAuthorization: true, body: customer })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const remove = async (id: string): Promise<void> => {
    await request
      .remove({ path: `${path}/${id}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });

    return;
  };

  const listAllAddressTypes = async (): Promise<IPaginationReturn<IAddressType[]>> => {
    return await requestAddressTypes
      .getManyPaginated({ path: `${path}/address-types`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  return { listAll, listById, create, update, listAllAddressTypes, remove };
};

export { useCustomerAddressService };
