import React from 'react';
import { IOrder } from '../../interfaces/IOrder';
import {
  ContentFlexColumn,
  ContentRedValue,
  OrderContentContainer,
  TBody,
  THead,
  Table,
  TableImageLink,
  Td,
  Th,
  Tr,
} from './Order.styled';
import './OrderContent.css';
import { IOrderItem } from '../../interfaces/IOrderItem';
import noneImage from '../../assets/none-image.json';

interface IProps {
  order: IOrder;
}

const OrderContent = (props: IProps) => {
  const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value);
  };

  const formatNumber = (value: number, precision: number | undefined): string => {
    return new Intl.NumberFormat('pt-br', { minimumFractionDigits: precision || 0 }).format(value);
  };

  return (
    <OrderContentContainer>
      <Table>
        <THead>
          <Tr>
            <Th>Item</Th>
            <Th></Th>
            <Th minWidth="300px">Descrição</Th>
            <Th>Código</Th>
            <Th>
              <span style={{ fontFamily: 'Roboto', marginRight: '-4px' }}>Q</span> td
            </Th>
            <Th>Unitário</Th>
            <Th>Total</Th>
            <Th>IPI</Th>
            <Th>ICMS ST</Th>
            <Th>ICMS</Th>
            <Th>Total Final</Th>
            <Th style={{ display: 'flex', flexDirection: 'column' }}>
              <span>Caixa</span> <span>Master</span>
            </Th>
          </Tr>
        </THead>
        <TBody>
          {props.order?.items?.map((item: IOrderItem, index: number) => {
            return (
              <Tr key={index} style={{ lineHeight: '50px' }}>
                <Td>{item.sequence}</Td>
                <Td>
                  <TableImageLink href={item.product?.url} target="blank">
                    {item.product?.image ? (
                      <img src={`data:image/png;base64,${item.product?.image}`} alt={item.product.sku} />
                    ) : (
                      <img src={`data:image/png;base64,${noneImage.image}`} alt={item.product?.sku} />
                    )}
                  </TableImageLink>
                </Td>
                <Td minWidth="300px">{item.product?.description}</Td>
                <Td>{item.product?.sku}</Td>
                <Td>{formatNumber(item.quantity, item?.product?.measurementUnit?.precision)}</Td>
                <Td>{formatCurrency(item.price)}</Td>
                <Td>{formatCurrency(item.price * item.quantity)}</Td>
                <Td>
                  <ContentFlexColumn>
                    <span>{formatCurrency(item.IPI_Value)}</span>
                    <ContentRedValue>{formatNumber(item.IPI_Percent, 0)} %</ContentRedValue>
                  </ContentFlexColumn>
                </Td>
                <Td>{formatCurrency(item.ICMSST_Value)}</Td>
                <Td> {item.ICMS_Percent} %</Td>
                <Td> {formatCurrency(item.amount)} </Td>
                <Td>
                  {' '}
                  <ContentFlexColumn>
                    <span>{item.product?.masterPacking}</span>
                    <ContentRedValue>{item.product?.measurementUnitId}</ContentRedValue>
                  </ContentFlexColumn>
                </Td>
              </Tr>
            );
          })}
        </TBody>
      </Table>
    </OrderContentContainer>
  );
};

export default OrderContent;
