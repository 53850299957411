import { IconButton, Table, TableCell } from '@mui/material';
import styled, { css } from 'styled-components';

export const TotalsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;

  div {
    margin-left: 10px;
  }

  flex-wrap: wrap;
  @media (max-width: 1180px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 10px;
    padding: 8px;
  }
`;

export const TotalsValues = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;

  @media (max-width: 1180px) {
    justify-content: space-between;
    width: 100%;
  }
`;

export const RemoveContent = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 500;

  h3 {
    padding: 0px;
    margin: 0px;
  }
`;

export const TotalValue = styled.span`
  min-width: 120px;
  text-align: 'left';
`;

export const RemoveContentActions = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  margin-top: 15px;
`;

export const TableItems = styled(Table)`
  && {
    margin: 24px 0;

    @media (max-width: 1180px) {
      display: none;
    }
  }
`;

export const TableBodyCard = styled.div`
  padding: 8px;
  display: block;

  @media (min-width: 1180px) {
    margin-top: 16px;
    display: none;
  }
`;

export const TableCard = styled.div`
  border-radius: 8px;
  box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.1);
  padding: 16px;

  & + & {
    margin: 20px 0;
  }
`;

export const HeaderCard = styled.div`
  border-radius: 8px 8px 0 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .order {
    font-size: 16px;
    text-decoration: none;
    color: var(--main-color);
    margin-right: 10px;
    line-height: 16px;
  }

  .client-name {
    text-align: left;
    width: 410px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const TableCardItem = styled.div`
  display: flex;
  justify-content: space-between;

  & + & {
    margin-top: 24px;
  }
`;

export const TableCardRow = styled.div`
  display: flex;
  justify-content: space-between;

  & + & {
    margin-top: 24px;
  }
`;

interface ITableCellProps {
  statusid?: number;
}

export const TableCellCustom = styled(TableCell)<ITableCellProps>`
  && {
    height: 40px;
    font-size: 12px;

    strong {
      font-weight: bold;
    }

    ${props =>
      props.statusid === 1 &&
      css`
        color: #fbb001 !important;
      `}

    ${props =>
      props.statusid === 2 &&
      css`
        color: #00ba00 !important;
      `}

    ${props =>
      props.statusid === 3 &&
      css`
        color: #f90303 !important;
      `}

    ${props =>
      props.statusid &&
      props.statusid > 0 &&
      css`
        font-weight: 600 !important;
      `}
  }
`;

export const TableCardTitle = styled.strong`
  font-size: 14px;
  margin-right: 16px;
`;

export const TableCardDescription = styled.span<ITableCellProps>`
  text-align: right;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${props =>
    props.statusid === 1 &&
    css`
      color: #fbb001 !important;
    `}

  ${props =>
    props.statusid === 2 &&
    css`
      color: #00ba00 !important;
    `}

  ${props =>
    props.statusid === 3 &&
    css`
      color: #f90303 !important;
    `}


  ${props =>
    props.statusid &&
    props.statusid > 0 &&
    css`
      font-weight: 600 !important;
    `}
`;

export const IconButtonAdd = styled(IconButton)`
  svg {
    color: var(--main-color);
  }
  @media (min-width: 1180px) {
    && {
      display: none;
    }
  }

  @media (max-width: 1180px) {
    && {
      position: fixed;
      right: -5px;
      bottom: 50px;
    }
  }
`;

export const ButtonSaveContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-right: 20px;
  @media (max-width: 1180px) {
    && {
      display: none;
    }
  }
`;

export const OrderItemListContainer = styled.div`
  position: relative;
`;

interface IDataInfoPropos {
  size?: number;
}

export const DataInfo = styled.div<IDataInfoPropos>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${props =>
    props.size && props.size > 0
      ? css`
          width: ${props.size}px;
        `
      : css`
          width: 50px;
        `}
`;
