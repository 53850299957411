import { Box, Button, CardContent, Collapse } from '@mui/material';

import styled, { css } from 'styled-components';

export const Container = styled(CardContent)`
  && {
    padding: 8px 0px !important;
    border: 1px solid #f0f0f0;
    border-radius: 8px;

    & + & {
      margin-top: 8px;
    }

    transition: background-color 0.2s;

    &:hover {
      background-color: #f4f4f4;
    }
  }
`;

export const Content = styled.div`
  && {
    display: flex;
    flex-direction: column;
    padding: 8px;
    font-size: 0.75rem;
    cursor: default;

    & + & {
      margin: 20px 0 0 0;
    }

    @media (max-width: 900px) {
      cursor: pointer;
    }
  }
`;

export const HeaderCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .order-client-name-area {
    display: flex;
  }

  .order {
    font-size: 0.875rem;
    text-decoration: none;
    color: var(--main-color);
    margin-right: 10px;
    line-height: 16px;
  }

  .client-name {
    text-align: left;
    width: 47vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 500px) {
    .order-client-name-area {
      flex-direction: column;

      .client-name {
        margin-top: 8px;
      }
    }
  }
`;

export const BodyCard = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;

  span + span {
    margin-top: 12px;
  }
`;

export const BodyCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const BodyCardActions = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 900px) {
    display: none;
  }
`;

interface IBodyActionsCustom {
  buttonDisable?: boolean;
}

export const BodyActionsCustom = styled.button<IBodyActionsCustom>`
  background: transparent;
  border: none;
  padding: 0;
  margin: 8px;

  svg {
    color: var(--main-color);
  }

  cursor: pointer;

  ${props =>
    props.disabled &&
    css`
      svg {
        color: #b5b5b5;
      }

      cursor: default;
    `}
`;

export const Date = styled.span``;

export const OrderStatus = styled.span`
  padding: 8px;
  font-weight: bold;
  margin-left: 8px;
  border-radius: 16px;
  color: #fff;
  cursor: pointer;

  white-space: nowrap;
`;

export const CollapseStyled = styled(Collapse)`
  @media (min-width: 900px) {
    display: none;
  }
`;

export const BoxStyled = styled(Box)`
  display: flex;
`;

export const ButtonStyled = styled(Button)`
  && {
    width: 100%;
    margin: 0;
    padding: 0;
    height: 48px;
  }
`;
