import { useEffect, useState } from 'react';
import { PageCard } from '../../components/page-card/PageCard';
import { useAuth } from '../../hooks/auth';
import IPage from '../../interfaces/IPage';
import { useLocalStorage } from '../../services/useLocalStorage';
import Iframe from 'react-iframe';

const Home = (props: IPage) => {
  window.document.title = props.title;

  const { state } = useAuth();

  const localStorage = useLocalStorage();

  const [iFrameUrl, setIFrameUrl] = useState('');
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    const customization = localStorage.getItem('@PEDIDO-WEB:customization');
    setIFrameUrl(customization.iFrame);
  }, []);

  return (
    <PageCard>
      <div style={{ height: '500px' }}>
        Seja bem vindo, <strong>{state.name}</strong>
      </div>
      {iFrameUrl && (
        <>
          <div style={{ height: '500px' }}>
            <Iframe url={iFrameUrl} height="100%" width="100%" title="iFrame" frameBorder={0}></Iframe>
          </div>
        </>
      )}
    </PageCard>
  );
};

export default Home;
