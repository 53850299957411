import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import { Alert, FormControl, FormControlLabel, Radio, Stack } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import {
  Container,
  Content,
  LogoImg,
  ButtonCustom,
  Input,
  SignInLink,
  TextFieldCustom,
  RadioGroupCustom,
} from './CustomerPortalSignup.styles';
import logoLoginDefault from '../../../assets/logoLogin.svg';
import IFormError from '../../../interfaces/IFormError';
import getValidationError from '../../../util/getValidationError';
import IPage from '../../../interfaces/IPage';
import { useCustomerPortalUserService } from '../../../services/useCustomerPortalUserService';
import { useOpenedRoutesService } from '../../../services/useOpenedRoutesService';
import { BackdropCustom } from '../../../components/backdrop/Backdrop';
import { useToastr } from '../../../hooks/useToastr';

const CustomerPortalSignup: React.FC<IPage> = ({ title }) => {
  window.document.title = title;

  const navigate = useNavigate();
  const location = useLocation();
  const { createUser } = useCustomerPortalUserService();
  const { getCustomization } = useOpenedRoutesService();
  const toastr = useToastr();

  const [tenant] = useState(() => {
    return process.env.REACT_APP_CLIENT_TENANT_DOMAIN
      ? process.env.REACT_APP_CLIENT_TENANT_DOMAIN
      : location.search.replaceAll('?tenant=', '');
  });
  const [corporateDocument, setCorporateDocument] = useState('');
  const [name, setName] = useState('');
  const [loginType, setLoginType] = useState<'EMAIL' | 'PHONE'>('EMAIL');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [customerType, setCustomerType] = useState<'J' | 'F'>('J');

  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErros] = useState<IFormError>({});

  const [logoLogin, setLogoLogin] = useState<string | null>(null);
  const [loadingCustom, setLoadingCustom] = useState(false);
  const [responseError, setResponseError] = useState<string | null>(null);
  const [responseSuccess, setResponseSuccess] = useState<string | null>(null);

  const clearForm = () => {
    setCorporateDocument('');
    setName('');
    setEmail('');
    setPhoneNumber('');
    setPassword('');
    setPasswordConfirmation('');
  };

  const handleSubmit = useCallback(async (): Promise<void> => {
    setLoading(true);
    setFormErros({});
    setResponseError(null);
    setResponseSuccess(null);

    try {
      const data = {
        corporateDocument,
        email: loginType === 'EMAIL' ? email : '',
        phoneNumber: loginType === 'PHONE' ? phoneNumber.replace(/\D/g, '') : '',
        name,
        password,
        passwordConfirmation,
        tenant,
      };

      let schema;

      if (loginType === 'EMAIL') {
        schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          corporateDocument: Yup.string().required(customerType === 'J' ? 'CNPJ obrigatório' : 'CPF obrigatório'),
          email: Yup.string().required('E-mail obrigatório').email('Informe um e-mail válido'),
          password: Yup.string().required('Senha obrigatória'),
          passwordConfirmation: Yup.string().required('Confirmação de senha obrigatória'),
        });
      } else {
        schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          corporateDocument: Yup.string().required(customerType === 'J' ? 'CNPJ obrigatório' : 'CPF obrigatório'),
          phoneNumber: Yup.string().required('Telefone obrigatório').min(10, 'O número de telefone não é válido'),
          password: Yup.string().required('Senha obrigatória'),
          passwordConfirmation: Yup.string().required('Confirmação de senha obrigatória'),
        });
      }

      await schema.validate(data, {
        abortEarly: false,
      });

      await createUser(data)
        .then(response => {
          setResponseSuccess(`Cadastro realizado com sucesso. Confirme o cadastro no e-mail ${response.email}.`);

          clearForm();
        })
        .catch(error => {
          setResponseError(error.message || 'Ocorreu um erro ao criar usuário.');
        });
    } catch (err: Yup.ValidationError | any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationError(err);

        setFormErros(errors);
        return;
      }

      setResponseError('Ocorreu um erro ao criar usuário. Contate o suporte.');
    } finally {
      setLoading(false);
    }
  }, [
    name,
    corporateDocument,
    loginType,
    email,
    phoneNumber,
    password,
    passwordConfirmation,
    customerType,
    tenant,
    navigate,
  ]);

  const handleChangeCustomerType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerType((event.target as HTMLInputElement).value === 'J' ? 'J' : 'F');
  };

  const handleChangeLoginType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber('');
    setEmail('');
    setLoginType((event.target as HTMLInputElement).value === 'EMAIL' ? 'EMAIL' : 'PHONE');
  };

  const handleGetCustomization = useCallback(async () => {
    setLoadingCustom(true);
    await getCustomization(btoa(tenant))
      .then(customization => {
        localStorage.setItem('@PEDIDO-WEB:customization', JSON.stringify(customization));

        setLogoLogin(customization.logoLogin);

        if (customization) {
          document.documentElement.style.setProperty('--main-color', `${customization?.mainColor}` || '#088671');
          document.documentElement.style.setProperty('--hover-color', `${customization?.hoverColor}` || '#08816d');
        }
      })
      .catch(error => {
        toastr.error(`${error.message}. Contate o suporte.` || 'Erro ao obter domínio. Contate o suporte.');
      })
      .finally(() => {
        setLoadingCustom(false);
      });
  }, [tenant, toastr]);

  useEffect(() => {
    if (tenant) {
      handleGetCustomization();
    }
  }, [tenant]);

  return (
    <Container>
      {loadingCustom ? (
        <BackdropCustom />
      ) : (
        <>
          {loading && <BackdropCustom />}
          <Content elevation={5}>
            {(!!responseError || !!responseSuccess) && (
              <Stack sx={{ width: '100%', marginBottom: '16px' }} spacing={2}>
                {!!responseSuccess && <Alert severity="success">{responseSuccess}</Alert>}
                {!!responseError && <Alert severity="error">{responseError}</Alert>}
              </Stack>
            )}
            <LogoImg>
              <img src={logoLogin || logoLoginDefault} alt="Portal de Boletos" />
            </LogoImg>

            <form>
              <Input>
                <label>Nome</label>
                <TextFieldCustom
                  size="small"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  helperText={formErrors.name}
                  error={!!formErrors.name}
                  type="text"
                  id="name"
                />
              </Input>

              <FormControl style={{ width: '100%', marginTop: '16px' }}>
                <RadioGroupCustom
                  aria-labelledby="customer-type-radio-buttons-group"
                  name="customer-type"
                  value={customerType}
                  onChange={handleChangeCustomerType}
                >
                  <FormControlLabel value="J" control={<Radio />} label="Jurídica" />
                  <FormControlLabel value="F" control={<Radio />} label="Física" />
                </RadioGroupCustom>
              </FormControl>

              {customerType === 'J' ? (
                <Input>
                  <label>CNPJ</label>
                  <InputMask
                    mask={'99.999.999/9999-99'}
                    value={corporateDocument}
                    onChange={e => setCorporateDocument(e.target.value)}
                    maskPlaceholder={null}
                  >
                    <TextFieldCustom
                      size="small"
                      autoComplete="off"
                      helperText={formErrors.corporateDocument}
                      error={!!formErrors.corporateDocument}
                    />
                  </InputMask>
                </Input>
              ) : (
                <Input>
                  <label>CPF</label>
                  <InputMask
                    mask={'999.999.999-99'}
                    value={corporateDocument}
                    onChange={e => setCorporateDocument(e.target.value)}
                    maskPlaceholder={null}
                  >
                    <TextFieldCustom
                      size="small"
                      autoComplete="off"
                      helperText={formErrors.corporateDocument}
                      error={!!formErrors.corporateDocument}
                    />
                  </InputMask>
                </Input>
              )}

              <FormControl style={{ width: '100%', marginTop: '16px' }}>
                <RadioGroupCustom
                  aria-labelledby="login-type-radio-buttons-group"
                  name="login-type"
                  value={loginType}
                  onChange={handleChangeLoginType}
                >
                  <FormControlLabel value="EMAIL" control={<Radio />} label="E-mail" />
                  <FormControlLabel value="PHONE" control={<Radio />} label="Telefone" />
                </RadioGroupCustom>
              </FormControl>

              {loginType === 'EMAIL' ? (
                <Input>
                  <label>Endereço de e-mail</label>
                  <TextFieldCustom
                    size="small"
                    value={email}
                    onChange={e => setEmail(e.target.value.toLowerCase())}
                    autoComplete="off"
                    helperText={formErrors.email}
                    error={!!formErrors.email}
                    id="email"
                  />
                </Input>
              ) : (
                <Input>
                  <label>Número de telefone</label>

                  <InputMask
                    mask={'(99) 999999999'}
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)}
                    maskPlaceholder={null}
                  >
                    <TextFieldCustom
                      fullWidth
                      size="small"
                      helperText={formErrors.phoneNumber}
                      error={!!formErrors.phoneNumber}
                    />
                  </InputMask>
                </Input>
              )}

              <Input>
                <div>
                  <label>Senha</label>
                </div>
                <TextFieldCustom
                  size="small"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  type="password"
                  autoComplete="off"
                  helperText={formErrors.password}
                  error={!!formErrors.password}
                  id="password"
                  placeholder="Exemplo: Mudar@123"
                />
              </Input>
              <Input>
                <div>
                  <label>Confirmar senha</label>
                </div>
                <TextFieldCustom
                  size="small"
                  value={passwordConfirmation}
                  onChange={e => setPasswordConfirmation(e.target.value)}
                  type="password"
                  autoComplete="off"
                  helperText={formErrors.passwordConfirmation}
                  error={!!formErrors.passwordConfirmation}
                  id="passwordConfirmation"
                />
              </Input>

              <ButtonCustom onClick={handleSubmit} disabled={loading} variant="contained" id="submit">
                Confirmar
              </ButtonCustom>
            </form>

            <SignInLink>
              <Link to={`/customer-portal/signin?tenant=${tenant}`}>
                <KeyboardBackspaceIcon />
                Voltar para tela de login
              </Link>
            </SignInLink>
          </Content>
        </>
      )}
    </Container>
  );
};

export { CustomerPortalSignup };
