import { HTMLAttributes, ReactNode } from 'react';
import { Content } from './CardLabel.styles';

interface ICardLaberProps extends HTMLAttributes<HTMLDivElement> {
  icon?: ReactNode;
  label: string;
  title?: string;
  noneIcon?: boolean;
}

const CardLabel: React.FC<ICardLaberProps> = ({ icon, label, title, noneIcon }) => {
  return (
    <Content>
      {title && <span>{title}</span>}
      <div>
        {!noneIcon && <div>{icon}</div>}
        <span>{label}</span>
      </div>
    </Content>
  );
};

export default CardLabel;
