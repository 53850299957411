import { Socket, io } from 'socket.io-client';
import IUser from './interfaces/IUser';
import { IOrderItem } from './interfaces/IOrderItem';

class SocketioService {
  socket: Socket | undefined = undefined;

  connect(user: IUser) {
    this.socket = io(`${process.env.REACT_APP_BASE_API_URL}`, {
      path: process.env.REACT_APP_SOCKETIOPATH ? process.env.REACT_APP_SOCKETIOPATH : '/',
      auth: {
        user,
      },
    });
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  subscribeToCalculateItem(cb: (orderItem: IOrderItem) => void) {
    if (!this.socket) {
      return true;
    }

    this.socket.on('item-calculated', data => {
      return cb(data);
    });
  }
}

export default SocketioService;
