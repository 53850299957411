import { Container } from './CustomTableNoContent.styled';
import React, { HtmlHTMLAttributes } from 'react';

interface ICustomTableNoContentProps extends HtmlHTMLAttributes<HTMLDivElement> {
  text: string;
}

const CustomTableNoContent: React.FC<ICustomTableNoContentProps> = ({ text }) => {
  return (
    <Container>
      <span>{text}</span>
    </Container>
  );
};

export default CustomTableNoContent;
