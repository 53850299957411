import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button } from '../../../components/button/Button';

interface IProps {
  orderId: string;
  open: boolean;
  deny: () => void;
  cloneOrder: (id: string) => void;
}

const OrderCloneDialog = ({ orderId, open, cloneOrder, deny }: IProps) => {
  return (
    <Dialog open={open}>
      <DialogContent sx={{ width: '250px' }}>
        <DialogContentText id="alert-dialog-description">Deseja realmente duplicar o pedido?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={deny} color="error">
          Cancelar
        </Button>
        <Button onClick={() => cloneOrder(orderId)} autoFocus>
          Duplicar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderCloneDialog;
