import React, { forwardRef, useRef, useState } from 'react';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DialogEditItem, FormControlMui, ProductInfo, ProductsSelected, FlexContainer } from './OrderItemEdit.style';
import { Button } from '../../components/button/Button';
import AsyncSearch, { IOption } from '../../components/async-search/AsyncSearch';
import { IProduct } from '../../interfaces/IProduct';
import { useProductService } from '../../services/useProductService';
import { FormHelperText, Grid, TextField } from '@mui/material';
import IFormError from '../../interfaces/IFormError';
import { useValidateSchema } from '../../services/useValidateSchema';
import { useToastr } from '../../hooks/useToastr';
import * as yup from 'yup';
import { IOrderItem } from '../../interfaces/IOrderItem';
import { useOrderItemService } from '../../services/useOrderItemService';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
interface IProps {
  openDialog: boolean;
  orderId: string;
  id?: string;
  handleClose: () => void;
  itemsUpdated?(): void;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={'.'}
      decimalScale={2}
      decimalSeparator=","
      valueIsNumericString
    />
  );
});

const OrdemItemEdit: React.FC<IProps> = ({ openDialog, orderId, id, handleClose, itemsUpdated }) => {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState<IProduct | null>(null);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [productOptions, setProductOptions] = useState<IOption[]>([]);
  const [productOption, setProductOption] = useState<IOption | null>(null);
  const [quantity, setQuantity] = useState('');
  const [offerPercentage, setOfferPercentage] = useState('');
  const [additionPercentage, setAdditionPercentage] = useState('');
  const [errors, setErrors] = useState<IFormError>({});
  const [orderItem, setOrderItem] = useState<IOrderItem | null>(null);
  const [price, setPrice] = useState(0);
  const [totalValue, setTotalValue] = useState(0);

  const [perPage] = useState(30);
  const [currentPage] = useState(0);
  const [orderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderField] = useState<string>('description');
  const [decimalScale, setDecimalScale] = useState(0);

  const timeout = useRef<any>(100);

  const productService = useProductService();
  const orderItemService = useOrderItemService();

  const validateSchema = useValidateSchema();
  const toastr = useToastr();

  const schema = yup.object({
    productId: yup.string().required('Campo obrigatório'),
    quantity: yup
      .string()
      .required('Campo obrigatório')
      .test('len', 'Deve ser informado uma quantidade maior que 0,01', val => !!val && !!(Number(val) > 0.01)),
    offerPercentage: yup
      .string()
      .test('len', 'Deve ser informado deve ser menor que 100', val => !!val && !!(Number(val) < 100)),
  });

  React.useEffect(() => {
    if (openDialog && id) {
      loadOrderItem();
    }
  }, [openDialog]);

  React.useEffect(() => {
    const discount = parseFloat(offerPercentage) || 0;
    const addition = parseFloat(additionPercentage) || 0;

    let price = orderItem?.grossPrice || 0;
    if (discount > 0 && !addition) {
      price = price * (1 - discount / 100);
    }
    if (addition > 0 && !discount) {
      price = price * (1 + addition / 100);
    }
    if (discount && addition) {
      price = price * (1 + addition / 100 - 1 / discount);
    }
    const itemQuantity = parseFloat(quantity) || 0;
    const taxBase = orderItem?.price ? orderItem.price * itemQuantity : 0;
    const ICMSST_Value = orderItem?.ICMSST_Value || 0;
    const ICMSST_Percent = taxBase ? ICMSST_Value / taxBase : 0;
    const IPIPercent = orderItem?.IPI_Percent ? orderItem?.IPI_Percent / 100 : 0;
    price = price * (1 + (IPIPercent + ICMSST_Percent));

    setPrice(price);
    setTotalValue(price * itemQuantity);
  }, [quantity, offerPercentage, additionPercentage]);

  const closeDialog = () => {
    setProduct(null);
    setProductOption(null);
    setQuantity('');
    setOrderItem(null);
    setOfferPercentage('');
    setAdditionPercentage('');
    setErrors({});
    handleClose();
  };

  const loadOrderItem = async () => {
    setLoading(true);

    await orderItemService
      .listById(`${id}`)
      .then(item => {
        item.product && setProduct(item.product);
        item.product && setProductOption(productToOption(item.product));
        setQuantity(item.quantity > 0 ? String(item.quantity) : '');
        setDecimalScale(item.product?.measurementUnit?.precision || 0);
        setOfferPercentage(item.offerPercentage > 0 ? String(item.offerPercentage) : '');
        setAdditionPercentage(
          item?.additionPercentage && item?.additionPercentage > 0 ? String(item?.additionPercentage) : '',
        );
        setOrderItem(item);
        if (item.price) {
          setPrice(item.price);
          setTotalValue(item.price * item.quantity);
        }
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSetProduct = (option: IOption | null) => {
    if (option) {
      setProductOption(option);
      const selected = products.find(product => {
        return product.id === option.value;
      });
      if (selected?.sku) {
        setDecimalScale(selected?.measurementUnit?.precision || 0);
        setProduct(selected);
      } else {
        setProduct(null);
      }
    }
  };

  const productToOption = (product: IProduct): IOption => {
    return {
      value: `${product.id}`,
      description: `${product.sku} ${product.description} ${product.derivation}`,
    };
  };

  const listProducts = async (filter = '') => {
    clearInterval(timeout.current);
    timeout.current = setTimeout(async () => {
      setLoading(true);
      const url = `perPage=${perPage}&currentPage=${
        currentPage + 1
      }&orderBy=${orderField}&orderDirection=${orderDirection}&filter=${filter}`;

      await productService
        .listDynamically(url)
        .then(result => {
          if (result) {
            setProducts(result.data);

            const options: IOption[] = result.data.map(productToOption);
            const isProductOnArray = options.find(opt => opt.value === product?.id);
            if (product?.id && !isProductOnArray) {
              options.push(productToOption(product));
            }
            setProductOptions(options);
          } else {
            setProductOptions([]);
          }
        })
        .catch(error => {
          setProductOptions([]);
          toastr.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }, timeout.current);
  };

  const createOrder = async (item: IOrderItem) => {
    setLoading(true);
    await orderItemService
      .create(item)
      .then(() => {
        toastr.success('Item adicionado com sucesso');
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateOrder = async (item: IOrderItem) => {
    setLoading(true);
    await orderItemService
      .update(`${item.id}`, item)
      .then(() => {
        toastr.success('Item atualizado com sucesso');
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleConfirmation = async () => {
    const item: IOrderItem = {
      id,
      orderId,
      sequence: orderItem?.sequence || 0,
      productId: product?.id || undefined,
      product: product || undefined,
      quantity: Number(quantity),
      price: orderItem?.price || 0,
      freightValue: orderItem?.freightValue || 0,
      discountFTZ: orderItem?.discountFTZ || 0,
      IPI_Percent: orderItem?.IPI_Percent || 0,
      IPI_Value: orderItem?.IPI_Value || 0,
      ICMSST_Value: orderItem?.ICMSST_Value || 0,
      ICMS_Percent: orderItem?.ICMS_Percent || 0,
      amount: orderItem?.amount || 0,
      offerPercentage: Number(offerPercentage) || 0,
      additionPercentage: Number(additionPercentage) || 0,
      offerValue: orderItem?.offerValue || 0,
      status: orderItem?.status || undefined,
      errorMessage: orderItem?.errorMessage || '',
    };

    const result = await validateSchema.validate(schema, item);
    if (!result.isValid) {
      setErrors(result.errors ? result.errors : {});
      return;
    }
    if (id) {
      await updateOrder(item);
    } else {
      await createOrder(item);
    }

    itemsUpdated?.();
    closeDialog();
  };

  const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
  };

  return (
    <DialogEditItem open={openDialog} onClose={closeDialog}>
      <DialogContent>
        <div className="product-area">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <AsyncSearch
                options={productOptions}
                setOptions={setProductOptions}
                option={productOption}
                setOption={handleSetProduct}
                asyncSearch={listProducts}
                loading={loading}
                error={Boolean(errors?.productId)}
                errorMessage={errors?.productId || ''}
                label="Produto"
              />

              <Grid item xs={12} md={12} sx={{ marginBottom: '10px' }}>
                {product ? (
                  <ProductsSelected>
                    <div>
                      <img src={`data:image/png;base64,${product?.image}`} alt={product?.sku} width="80px" />
                      <p>
                        {`${product?.sku} - ${product?.description} ${
                          product?.derivation && product?.derivation?.length >= 1 ? `- ${product?.derivation}` : ''
                        } `}{' '}
                      </p>
                    </div>
                  </ProductsSelected>
                ) : (
                  <ProductsSelected>
                    <p>Nenhum produto selecionado</p>
                  </ProductsSelected>
                )}
                {product && (
                  <ProductInfo>
                    <div>
                      <span>
                        <strong>Múltiplo: </strong> <span>{product?.masterPacking || 0}</span>
                      </span>
                      <span>
                        <strong>NCM: </strong> <span>{product?.ncm || ''}</span>
                      </span>
                      <span>
                        <strong>Código de Barras: </strong> <span>{product?.barcode || ''}</span>
                      </span>
                    </div>
                    <div>
                      <span>
                        <strong>Estoque Disponível: </strong>{' '}
                        <span>
                          {product?.stockQuantity && product?.stockQuantity > 0
                            ? formatCurrency(product?.stockQuantity)
                            : '0,00'}
                        </span>
                      </span>
                    </div>
                  </ProductInfo>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} style={{ padding: '8px' }}>
              <FormControlMui sx={{ m: 1, width: '100%' }} size="small" error={!!errors.quantity}>
                <NumericFormat
                  customInput={TextField}
                  label="Quantidade"
                  style={{ width: '100%' }}
                  value={quantity}
                  onValueChange={values => {
                    setQuantity(values.value);
                  }}
                  decimalScale={decimalScale}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale
                  valueIsNumericString
                />
                <FormHelperText>{errors.quantity}</FormHelperText>
              </FormControlMui>
            </Grid>
            <Grid item xs={12} md={12} style={{ padding: '8px' }}>
              <FormControlMui sx={{ m: 1, width: '100%' }} size="small" error={!!errors.offerPercentage}>
                <TextField
                  style={{ width: '100%' }}
                  label="% Oferta"
                  value={offerPercentage}
                  onChange={event => setOfferPercentage(event.target.value)}
                  name="value"
                  size="small"
                  id="distance-miles-input"
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                  }}
                  variant="outlined"
                  error={!!errors.offerPercentage}
                />
                <FormHelperText>{errors.offerPercentage}</FormHelperText>
              </FormControlMui>
            </Grid>
            <Grid item xs={12} md={12} style={{ padding: '8px' }}>
              <FormControlMui sx={{ m: 1, width: '100%' }} size="small">
                <TextField
                  style={{ width: '100%' }}
                  label="% Acréscimo"
                  value={additionPercentage}
                  onChange={event => setAdditionPercentage(event.target.value)}
                  name="value"
                  size="small"
                  id="distance-miles-input"
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                  }}
                  variant="outlined"
                />
                <FormHelperText>{errors.offerPercentage}</FormHelperText>
              </FormControlMui>
            </Grid>
          </Grid>
          {orderItem?.price && orderItem?.price > 0 && (
            <FlexContainer>
              <FlexContainer>
                <strong>Preço Unit. Estimado: </strong> <span>{formatCurrency(price)}</span>
              </FlexContainer>
              <FlexContainer>
                <strong>Total Estimado: </strong> <span>{formatCurrency(totalValue)}</span>
              </FlexContainer>
            </FlexContainer>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="inherit">
          Cancelar
        </Button>
        <Button onClick={handleConfirmation}>Confirmar</Button>
      </DialogActions>
    </DialogEditItem>
  );
};

export { OrdemItemEdit };
