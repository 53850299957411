import styled from 'styled-components';

export const TitleContainerCustom = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px;
  justify-content: space-between;
  align-items: center;

  h1 {
    padding: 0;
    margin: 0;
    font-size: 1.25rem;
    color: #999999;
    font-weight: normal;
  }

  a {
    text-decoration: none;
    color: var(--main-color);
    font-size: 0.85rem;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }
`;
