import AppError from '../errors/AppError';
import { IPaginationReturn } from '../interfaces/IPaginationReturn';
import { ISeller } from '../interfaces/ISeller';
import IUser from '../interfaces/IUser';

import { useRequest } from './useRequest';

const useSellersService = () => {
  const request = useRequest<ISeller>();
  const path = 'sellers';

  const findAll = async (queryParams: string): Promise<IPaginationReturn<ISeller[]>> => {
    return await request
      .getManyPaginated({ path: `${path}?${queryParams}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const findByEmail = async (email: string, queryParams: string): Promise<IPaginationReturn<ISeller[]>> => {
    return await request
      .getManyPaginated({ path: `${path}/email/${email}?${queryParams}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const listDynamically = async (queryParams: string): Promise<IPaginationReturn<ISeller[]>> => {
    const userSession = sessionStorage.getItem('@PEDIDO-WEB:user');

    const user: IUser = userSession ? JSON.parse(userSession) : null;

    const idSalesRegion = user.seller?.salesRegion?.id ? user.seller?.salesRegion?.id : null;

    return await request
      .getManyPaginated({
        path: `${path}/dynamically?${queryParams}&idSalesRegion=${idSalesRegion}`,
        sendAuthorization: true,
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  return { findAll, listDynamically, findByEmail };
};

export { useSellersService };
