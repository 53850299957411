import AppError from '../errors/AppError';
import { IPaginationReturn } from '../interfaces/IPaginationReturn';
import { IPriceTableItem } from '../interfaces/IPriceTableItem';
import { useRequest } from './useRequest';

const usePriceTableItemsService = () => {
  const requestPriceTableItems = useRequest<IPriceTableItem>();
  const pathPriceTableItems = 'price-table-items';

  const createPriceTableItem = async (priceTable: IPriceTableItem): Promise<IPriceTableItem> => {
    return await requestPriceTableItems
      .post({ path: `${pathPriceTableItems}/`, sendAuthorization: true, body: priceTable })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message, error.status);
      });
  };

  const updatePriceTableItem = async (id: string, priceTable: IPriceTableItem): Promise<IPriceTableItem> => {
    return await requestPriceTableItems
      .put({ path: `${pathPriceTableItems}/${id}`, sendAuthorization: true, body: priceTable })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message, error.status);
      });
  };

  const listPriceTableItem = async (id: string): Promise<IPriceTableItem> => {
    return await requestPriceTableItems
      .getOne({ path: `${pathPriceTableItems}/${id}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message, error.status);
      });
  };

  const listAllPriceTableItems = async (
    priceTableId: string,
    url: string,
  ): Promise<IPaginationReturn<IPriceTableItem[]>> => {
    const response = await requestPriceTableItems
      .getManyPaginated({
        path: `${pathPriceTableItems}/list/${priceTableId}?${url}`,
        sendAuthorization: true,
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });

    return response;
  };

  const deletePriceTableItem = async (id: string): Promise<void> => {
    await requestPriceTableItems
      .remove({ path: `${pathPriceTableItems}/${id}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message, error.status);
      });
  };

  return {
    listPriceTableItem,
    listAllPriceTableItems,
    createPriceTableItem,
    updatePriceTableItem,
    deletePriceTableItem,
  };
};

export { usePriceTableItemsService };
