import AppError from '../errors/AppError';
import { ICustomerPortalSession } from '../interfaces/ICustomerPortalSession';
import { useRequest } from './useRequest';

const useCustomerPortalLoginService = () => {
  const request = useRequest<ICustomerPortalSession>();
  const path = 'customer-portal/session';

  const createSession = async (
    email: string,
    phoneNumber: string,
    password: string,
    tenant: string,
  ): Promise<ICustomerPortalSession> => {
    return await request
      .post({ path: `${path}`, body: { email, phoneNumber, password, tenant }, sendAuthorization: false })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };
  return { createSession };
};

export { useCustomerPortalLoginService };
