import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { MuiColorInput, MuiColorInputFormat } from 'mui-color-input';

import { TitleContainer } from '../../../components/title-container/TitleContainer';
import { PageCard } from '../../../components/page-card/PageCard';
import { Button, Dialog, FormControl, Grid, TextField } from '@mui/material';
import IOrderStatusProps from './IOrderStatusProps';
import styled from 'styled-components';
import { useOrderStatusService } from '../../../services/useOrderStatusService';
import IOrderStatus from '../../../interfaces/IOrderStatus';
import { useToastr } from '../../../hooks/useToastr';
import { Button as CustomButtom } from '../../../components/button/Button';
import * as yup from 'yup';
import IFormError from '../../../interfaces/IFormError';
import { validateSchema } from '../../../util/validateSchema';
import { BackdropCustom } from '../../../components/backdrop/Backdrop';

const ButtonStyled = styled(Button)`
  background-color: var(--main-color);
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Img = styled.img`
  margin-left: 80px;
`;

const InputFile = styled.input`
  display: none;
`;

const initialOrderStatus: IOrderStatus = {
  id: 0,
};

interface IProps {
  dialogClosed?(): void;
}

const OrderStatusForm = forwardRef<IOrderStatusProps, IProps>(({ dialogClosed }, ref) => {
  const { listOrderStatusById, uploadIcon, updateOrderStatus } = useOrderStatusService();
  const toastr = useToastr();

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState<IOrderStatus>({ ...initialOrderStatus });
  const [errors, setErrors] = useState<IFormError | null>(null);

  const inputFileRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    async openDialog(id) {
      await listStatus(id);
      setIsOpen(true);
    },
  }));

  const handleCloseDialog = () => {
    setOrderStatus({ ...initialOrderStatus });
    dialogClosed && dialogClosed();
    setIsOpen(false);
  };

  const handleColorChange = (newValue: string) => {
    setOrderStatus({ ...orderStatus, color: newValue });
  };

  const handleInputClick = () => {
    inputFileRef.current?.click();
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await handleUploadIcon(e.target.files?.item(0) as File);
  };

  const listStatus = async (id: number) => {
    setLoading(true);
    await listOrderStatusById(id)
      .then(status => {
        if (!status.comments) {
          status.comments = '';
        }
        setOrderStatus(status);
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUploadIcon = async (file: File) => {
    if (file && orderStatus?.id) {
      const data = new FormData();
      data.append('image', file, file?.name);
      setLoading(true);
      await uploadIcon(orderStatus.id, data)
        .then(async () => {
          await listStatus(orderStatus.id);
          toastr.success('Ícone alterado com sucesso');
        })
        .catch(error => {
          toastr.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleUpdateOrderStatus = async () => {
    const validation = validateSchema();

    const schema = yup.object({
      order: yup
        .number()
        .min(1, 'A ordenação deve ser maior que zero')
        .required('A ordem é obrigatória')
        .typeError('A ordenação é obrigatória'),
      color: yup.string().required('A cor é obrigatória').typeError('A cor é obrigatória'),
    });

    const result = await validation.validate(schema, orderStatus);
    setErrors(result.errors);
    if (!result.isValid) {
      return;
    }

    setLoading(true);
    await updateOrderStatus(orderStatus.id, orderStatus)
      .then(() => {
        toastr.success('Alteração efetuada com sucesso.');
        setIsOpen(false);
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        dialogClosed && dialogClosed();
        setLoading(false);
      });
  };

  const format: MuiColorInputFormat = 'hex8';

  return (
    <Dialog fullWidth open={isOpen} maxWidth="xl">
      <TitleContainer>
        <h1>Status Pedidos</h1>
      </TitleContainer>
      {loading && <BackdropCustom />}
      <PageCard>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <TextField label="Descrição do Status" value={`${orderStatus?.description}`} fullWidth disabled />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              label="Ordenação"
              type="number"
              value={`${orderStatus?.order}`}
              fullWidth
              onChange={e => setOrderStatus({ ...orderStatus, order: parseInt(e.target.value) })}
              error={Boolean(errors?.order)}
              helperText={Boolean(errors?.order) ? errors?.order : ''}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              label="Observação para Pedido"
              value={`${orderStatus?.comments}`}
              fullWidth
              onChange={e => setOrderStatus({ ...orderStatus, comments: e.target.value })}
              error={Boolean(errors?.order)}
              helperText={Boolean(errors?.order) ? errors?.order : ''}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth size="small">
              <MuiColorInput
                value={`${orderStatus.color}`}
                onChange={handleColorChange}
                format={format}
                variant="outlined"
                style={{ width: '100%' }}
                label="Cor do Status"
                error={Boolean(errors?.color)}
                helperText={Boolean(errors?.color) ? errors?.color : ''}
              />
            </FormControl>
          </Grid>
        </Grid>
        <ButtonContainer>
          <CustomButtom onClick={handleCloseDialog} variant="contained" color="inherit" sx={{ textTransform: 'none' }}>
            Fechar
          </CustomButtom>
          <CustomButtom loading={loading} variant="contained" color="success" onClick={handleUpdateOrderStatus}>
            Confirmar
          </CustomButtom>
        </ButtonContainer>
      </PageCard>
      <PageCard>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} style={{ marginTop: 50 }}>
            <IconContainer>
              <ButtonStyled variant="contained" onClick={handleInputClick} size="large">
                Alterar Icone
              </ButtonStyled>
              <InputFile type="file" ref={inputFileRef} onChange={onFileChange} accept="image/*" />
              <Img src={orderStatus?.icon} width="100" alt="Ícone" />
            </IconContainer>
          </Grid>
        </Grid>
      </PageCard>
    </Dialog>
  );
});
export default OrderStatusForm;
