import { useLocation } from 'react-router-dom';
import { PageCard } from '../../../components/page-card/PageCard';
import { TitleContainer } from '../../../components/title-container/TitleContainer';

import { useCallback, useState } from 'react';
import { useCustomerAddressService } from '../../../services/useCustomerAddressService';
import React from 'react';
import {
  ButtonGroup,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { IconButtonMui } from '../../image-params-list/ImageParamsList.styled';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { BackdropCustom } from '../../../components/backdrop/Backdrop';
import CustomTableSortLabel from '../../../components/table/CustomTableSortLabel/CustomTableSortLabel';
import { AiTwotoneEdit } from 'react-icons/ai';
import CustomTablePagination from '../../../components/table/CustomTablePagination/CustomTablePagination';
import { ICustomerAddress } from '../../../interfaces/ICustomerAddress';
import { useToastr } from '../../../hooks/useToastr';
import { BsTrash } from 'react-icons/bs';
import CustomerAddressFormDialog from './CustomerAddressFormDialog';
import { TitleAreaContainer } from '../../product-list/ProductList.styled';
import { ElementNoContent } from '../../../components/element-no-content/ElementNoContent';

const CustomerAddressList = () => {
  const customerAddressService = useCustomerAddressService();

  const location = useLocation();
  const toastr = useToastr();

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderField, setOrderField] = useState<string>('street');
  const [loading, setLoading] = useState(false);
  const [customerId, setCustomerId] = useState('');

  const [customerAddresses, setCustomerAddresses] = useState<ICustomerAddress[]>([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogId, setDialogId] = useState('');

  const handleClose = () => {
    setOpenDialog(false);
    listCustomersAddress(perPage, currentPage, orderField, orderDirection, customerId);
  };
  const handleOpen = () => {
    setOpenDialog(true);
  };

  const listCustomersAddress = useCallback(
    async (perPage: number, currentPage: number, orderField: string, orderDirection: 'asc' | 'desc', filter = '') => {
      setLoading(true);

      await customerAddressService
        .listAll(
          `perPage=${perPage}&currentPage=${
            currentPage + 1
          }&orderBy=${orderField}&orderDirection=${orderDirection}&filter=${filter}`,
        )
        .then(response => {
          const addressTypes = response.data;

          if (addressTypes.length > 0) {
            setCustomerAddresses(response.data);
            setTotalRows(response.totalRows);
            setLoading(false);
          } else {
            setCustomerAddresses([]);
            setTotalRows(0);
            setPerPage(10);
            setCurrentPage(0);
            setLoading(false);
          }
        })
        .catch(error => {
          toastr.error(error?.message || 'Contate a equipe de suporte');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [],
  );

  const handleToRemove = async (id: string): Promise<void> => {
    setLoading(true);
    await customerAddressService
      .remove(id)
      .then(() => {
        setLoading(false);
        listCustomersAddress(perPage, currentPage, orderField, orderDirection, customerId);
        toastr.success('Endereço deletado com sucesso!');
      })
      .catch(error => {
        setLoading(false);
        toastr.error(error.message);
      });
  };

  React.useEffect(() => {
    const id = location.pathname.replace('/customers', '').replace('/', '');
    setCustomerId(id);
    if (id) {
      listCustomersAddress(perPage, currentPage, orderField, orderDirection, id);
    }
  }, [perPage, currentPage, orderField, orderDirection]);

  return (
    <>
      <TitleContainer>
        <h1>Endereços</h1>
      </TitleContainer>
      <PageCard>
        <TitleAreaContainer>
          <ElementNoContent />
          <ButtonGroup>
            <IconButtonMui
              onClick={() => {
                setDialogId('');
                handleOpen();
              }}
              size="small"
              color="primary"
              style={{ alignItems: 'flex-end' }}
            >
              <AddOutlinedIcon />
            </IconButtonMui>
          </ButtonGroup>
        </TitleAreaContainer>

        <TableContainer sx={{ marginTop: '2px', paddingLeft: '8px', paddingRight: '8px' }}>
          {loading && <BackdropCustom />}
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': {
                    borderBottom: 1,
                    borderColor: '#E5E5E5',
                  },
                }}
              >
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="addressTypeId"
                  label="Tipo de endereço"
                  handleRequest={listCustomersAddress}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  disableOrder
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="street"
                  label="Rua"
                  handleRequest={listCustomersAddress}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  disableOrder
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="number"
                  label="Número"
                  handleRequest={listCustomersAddress}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  disableOrder
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="complement"
                  label="Complemento"
                  handleRequest={listCustomersAddress}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  disableOrder
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="street"
                  label="Rua"
                  handleRequest={listCustomersAddress}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  disableOrder
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="neighborhood"
                  label="Bairro"
                  handleRequest={listCustomersAddress}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  disableOrder
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="city"
                  label="Cidade"
                  handleRequest={listCustomersAddress}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  disableOrder
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="state"
                  label="Estado"
                  handleRequest={listCustomersAddress}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  disableOrder
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="postalCode"
                  label="country"
                  handleRequest={listCustomersAddress}
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  disableOrder
                  setDirection={setOrderDirection}
                />

                <TableCell size="small">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerAddresses &&
                customerAddresses.map(customerAddress => {
                  return (
                    <>
                      <TableRow
                        key={customerAddress.id}
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell size="small">{customerAddress?.addressType?.description}</TableCell>
                        <TableCell size="small">{customerAddress?.street}</TableCell>
                        <TableCell size="small">{customerAddress?.number}</TableCell>
                        <TableCell size="small">{customerAddress?.complement}</TableCell>
                        <TableCell size="small">{customerAddress?.neighborhood}</TableCell>
                        <TableCell size="small">{customerAddress?.city}</TableCell>
                        <TableCell size="small">{customerAddress?.state}</TableCell>
                        <TableCell size="small">{customerAddress?.postalCode}</TableCell>
                        <TableCell size="small">{customerAddress?.country}</TableCell>

                        <TableCell size="small">
                          <Tooltip title="Editar" placement="top">
                            <IconButton
                              disabled={loading}
                              onClick={async () => {
                                setDialogId(customerAddress?.id || '');
                                handleOpen();
                              }}
                            >
                              <AiTwotoneEdit style={{ fontSize: '15px' }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Excluir" placement="top">
                            <IconButton disabled={loading} onClick={() => handleToRemove(customerAddress?.id || '')}>
                              <BsTrash style={{ fontSize: '15px' }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
            </TableBody>
          </Table>
          <CustomTablePagination
            totalRows={totalRows}
            currentPage={currentPage}
            perPage={perPage}
            handleRequest={listCustomersAddress}
            setPerPage={setPerPage}
            setCurrentPage={setCurrentPage}
            orderField={orderField}
            orderDirection={orderDirection}
          />
        </TableContainer>
      </PageCard>
      <CustomerAddressFormDialog open={openDialog} closeDialog={handleClose} id={dialogId} customerId={customerId} />
    </>
  );
};

export default CustomerAddressList;
