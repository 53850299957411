import React, { createContext, HtmlHTMLAttributes, useCallback, useContext, useState } from 'react';
import { ICustomerPortalSession } from '../interfaces/ICustomerPortalSession';
import { useCustomerPortalLoginService } from '../services/useCustomerPortalLoginService';
import { useNavigate } from 'react-router-dom';
import { ICustomization } from '../interfaces/ICustomization';
import { useLocalStorage } from '../services/useLocalStorage';
import { useSessionStorage } from '../services/useSessionStorage';
import { ICustomerPortalCustomerSelected } from '../interfaces/ICustomerPortalCustomerSelected';
import CustomerPortalAppMenu from '../components/customer-portal/customer-portal-app-menu/CustomerPortalAppMenu';

interface ICustomerPortalAuthContextData {
  customerPortalSession: ICustomerPortalSession;
  customerPortalCustomerSelected: ICustomerPortalCustomerSelected;
  customerPortalSignIn({ email, phoneNumber, password, tenant }: ISignInCredentials): Promise<void>;
  customerPortalSignOut(): void;
  customerPortalSetData(customerPortalSession: ICustomerPortalSession): void;
  customerPortalCustomization: ICustomization;
  customerPortalUpdateTheme(customization: ICustomization): void;
  customerPortalUpdateCustomerSelected(customerId: string): void;
}

interface ISignInCredentials {
  email: string;
  phoneNumber: string;
  password: string;
  tenant: string;
}

interface ICustomerPortalAuthProvider extends HtmlHTMLAttributes<HTMLElement> {}

const CustomerPortalAuthContext = createContext<ICustomerPortalAuthContextData>({} as ICustomerPortalAuthContextData);

const CustomerPortalAuthProvider: React.FC<ICustomerPortalAuthProvider> = ({ children }) => {
  const localStorage = useLocalStorage();
  const sessionStorage = useSessionStorage();

  const navigate = useNavigate();
  const { createSession } = useCustomerPortalLoginService();

  const setTheme = () => {
    const customization: ICustomization = localStorage.getItem('@PEDIDO-WEB:customization');

    if (customization) {
      document.documentElement.style.setProperty('--main-color', `${customization?.mainColor}` || '#088671');
      document.documentElement.style.setProperty('--hover-color', `${customization?.hoverColor}` || '#08816d');
    }
  };

  const customerPortalUpdateTheme = (customization: ICustomization) => {
    if (customization) {
      localStorage.setItem('@PEDIDO-WEB:customization', customization);

      document.documentElement.style.setProperty('--main-color', `${customization?.mainColor}` || '#088671');
      document.documentElement.style.setProperty('--hover-color', `${customization?.hoverColor}` || '#08816d');
    }
  };

  const [sessionData, setCustomerPortalSessionData] = useState<ICustomerPortalSession>(() => {
    const session = sessionStorage.getItem('@PEDIDO-WEB:session');

    if (session) {
      const sessionFormated: ICustomerPortalSession = session;
      return sessionFormated;
    }

    return {} as ICustomerPortalSession;
  });

  const [customerSelected, setCustomerSelected] = useState<ICustomerPortalCustomerSelected>(() => {
    const customer = sessionStorage.getItem('@PEDIDO-WEB:customer');

    if (customer) {
      const customerFormated: ICustomerPortalCustomerSelected = customer;
      return customerFormated;
    }

    return {} as ICustomerPortalCustomerSelected;
  });

  const [customizationData] = useState<ICustomization>(() => {
    const customization = localStorage.getItem('@PEDIDO-WEB:customization');

    if (customization) {
      const customizationPased: ICustomization = customization;
      return customizationPased;
    }

    return {} as ICustomization;
  });

  const customerPortalSignIn = useCallback(async ({ email, phoneNumber, password, tenant }: ISignInCredentials) => {
    const response = await createSession(email, phoneNumber, password, tenant);

    const session = response;

    session.user.tenant = tenant;

    localStorage.setItem('@PEDIDO-WEB:customization', session.user.customization);
    delete session.user.customization;

    sessionStorage.setItem('@PEDIDO-WEB:session', session);

    setCustomerPortalSessionData(session);

    sessionStorage.setItem('@PEDIDO-WEB:customer', session.user.customers[0]);

    setCustomerSelected(session.user.customers[0]);

    setTheme();
  }, []);

  const customerPortalSignOut = useCallback(() => {
    const session = sessionStorage.getItem('@PEDIDO-WEB:session');

    let tenant = '';
    if (session) {
      const sessionFormated: ICustomerPortalSession = session;

      tenant = sessionFormated.user.tenant || '';
    } else {
      tenant = '';
    }

    sessionStorage.removeItem('@PEDIDO-WEB:session');

    setCustomerPortalSessionData({} as ICustomerPortalSession);

    navigate(`/customer-portal/signin?tenant=${tenant}`);
  }, []);

  const customerPortalSetData = useCallback((session: ICustomerPortalSession) => {
    setCustomerPortalSessionData(session);
  }, []);

  const customerPortalUpdateCustomerSelected = useCallback(
    (customerId: string) => {
      const newCustomer = sessionData.user.customers.find(customer => customer.id === customerId);

      if (newCustomer) {
        setCustomerSelected(newCustomer);
        sessionStorage.setItem('@PEDIDO-WEB:customer', newCustomer);
      }

      window.location.reload();
    },
    [sessionData],
  );

  return (
    <CustomerPortalAuthContext.Provider
      value={{
        customerPortalSession: sessionData,
        customerPortalCustomerSelected: customerSelected,
        customerPortalSignIn,
        customerPortalSignOut,
        customerPortalSetData,
        customerPortalCustomization: customizationData,
        customerPortalUpdateTheme,
        customerPortalUpdateCustomerSelected,
      }}
    >
      {sessionData && customizationData && <CustomerPortalAppMenu logoHeader={customizationData.logoHeader} />}
      {children}
    </CustomerPortalAuthContext.Provider>
  );
};

function useCustomerPortalAuth(): ICustomerPortalAuthContextData {
  const context = useContext(CustomerPortalAuthContext);

  if (!context) {
    throw new Error('useCustomerPortalAuth deve ser usado dentro de um CustomerPortalAuthProvider');
  }

  return context;
}

export { CustomerPortalAuthProvider, useCustomerPortalAuth };
