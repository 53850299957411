import React, { createContext, ReactNode, useContext, useState } from 'react';

type DomainProps = {
  state: {
    tenantDomain: string;
  };
};

const TenantDomainContext = createContext<DomainProps>({
  state: {
    tenantDomain: '',
  },
});

const TenantDomainContextProvider = ({ children }: { children: ReactNode }) => {
  const [state] = useState({
    tenantDomain: 'aquario.com.br',
  });

  return (
    <TenantDomainContext.Provider
      value={{
        state,
      }}
    >
      {children}
    </TenantDomainContext.Provider>
  );
};

function useTenantDomain(): DomainProps {
  const context = useContext(TenantDomainContext);

  if (!context) {
    throw new Error('useAuth deve ser usado dentro de um AuthProvider');
  }

  return context;
}

export { TenantDomainContextProvider, useTenantDomain };
