import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Container, Content, LogoImg, ButtonCustom, Input, TextFieldCustom } from './CustomerPortalTenant.styles';
import logoLogin from '../../../assets/logoLogin.svg';
import IFormError from '../../../interfaces/IFormError';
import { useToastr } from '../../../hooks/useToastr';
import getValidationError from '../../../util/getValidationError';
import IPage from '../../../interfaces/IPage';

const CustomerPortalTenant: React.FC<IPage> = ({ title }) => {
  window.document.title = title;

  const navigate = useNavigate();
  const toastr = useToastr();

  const [tenant, setTenant] = useState('');

  const [formErrors, setFormErros] = useState<IFormError>({});

  const handleSubmit = useCallback(async (): Promise<void> => {
    setFormErros({});

    try {
      const data = {
        tenant,
      };

      const schema = Yup.object().shape({
        tenant: Yup.string().required('Domínio é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      navigate(`/customer-portal/signin?tenant=${tenant}`);
    } catch (err: Yup.ValidationError | any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationError(err);
        setFormErros(errors);
        return;
      }
    }
  }, [tenant, navigate, toastr]);

  return (
    <Container>
      <Content elevation={5}>
        <LogoImg>
          <img src={logoLogin} alt="Portal de Boletos" />
        </LogoImg>

        <form>
          <Input>
            <label>Informe o domínio</label>
            <TextFieldCustom
              size="small"
              value={tenant}
              onChange={e => setTenant(e.target.value)}
              autoComplete="off"
              helperText={formErrors.tenant}
              error={!!formErrors.tenant}
              autoFocus
              id="email"
              placeholder="exemplo.com.br"
            />
          </Input>

          <ButtonCustom onClick={handleSubmit} variant="contained" id="submit">
            Confirmar
          </ButtonCustom>
        </form>
      </Content>
    </Container>
  );
};

export { CustomerPortalTenant };
