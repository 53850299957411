import AppError from '../errors/AppError';
import IOrderParam from '../interfaces/IOrderParam';

import { useRequest } from './useRequest';

const useOrderParamsService = () => {
  const request = useRequest<IOrderParam>();
  const path = 'order-params';

  const list = async (): Promise<IOrderParam> => {
    const params = await request
      .getMany({ path: `${path}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
    return params.find(() => true) || {};
  };

  const update = async (id: string, param: IOrderParam): Promise<IOrderParam> => {
    return await request
      .put({ path: `${path}/${id}`, sendAuthorization: true, body: param })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  return { list, update };
};

export { useOrderParamsService };
