const formatPhone = (phone: string) => {
  phone = phone
    ?.replaceAll(' ', '')
    ?.replaceAll('+', '')
    ?.replaceAll('(', '')
    ?.replaceAll(')', '')
    ?.replaceAll('-', '');
  if (!phone) {
    return '';
  } else if (phone.length === 11) {
    return `(${phone.substr(0, 2)}) ${phone.substr(2, 5)} - ${phone.substr(7, 4)}`;
  } else if (phone.length === 10) {
    return `(${phone.substr(0, 2)}) ${phone.substr(2, 4)} - ${phone.substr(6, 4)}`;
  } else if (phone.length === 9) {
    return `${phone.substr(0, 5)} - ${phone.substr(5, 4)}`;
  } else if (phone.length === 8) {
    return `${phone.substr(0, 4)} - ${phone.substr(4, 4)} `;
  } else {
    return phone;
  }
};
export { formatPhone };
