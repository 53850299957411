import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'dayjs/locale/pt-br';
import * as yup from 'yup';

import { ButtonGroup } from '../../components/button-group/ButtonGroup';
import { PageCard } from '../../components/page-card/PageCard';
import { TitleContainer } from '../../components/title-container/TitleContainer';
import IPage from '../../interfaces/IPage';
import { Button } from '../../components/button/Button';
import { Form } from '../../components/form/Form';
import { useToastr } from '../../hooks/useToastr';
import IFormError from '../../interfaces/IFormError';
import { validateSchema } from '../../util/validateSchema';
import IOrderType from '../../interfaces/IOrderType';
import { useOrderTypesService } from '../../services/useOrderTypesService';
import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import { BackdropCustom } from '../../components/backdrop/Backdrop';

const initialOrderTypess: IOrderType = {
  description: '',
};

const OrderTypes: React.FC<IPage> = ({ title }) => {
  window.document.title = title;

  const [orderTypes, setOrderTypes] = useState<IOrderType>({ ...initialOrderTypess });

  const [errors, setErrors] = useState<IFormError | null>(null);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState<'edit' | 'new'>('edit');
  const [actionLabel, setActionLabel] = useState('Inclusão');
  const [id, setId] = useState(0);

  const navigate = useNavigate();

  const request = useOrderTypesService();
  const toastr = useToastr();

  const location = useLocation();

  const handleToOrderTypesList = useCallback(() => {
    navigate('/order-types-list');
  }, []);

  useEffect(() => {
    const action = location.pathname.split('/')[location.pathname.split('/').length - 1] as 'edit' | 'new';
    const id = parseInt(location.pathname.split('/')[location.pathname.split('/').length - 2]);
    setAction(action);
    if (action === 'edit') {
      setActionLabel('Edição');
    } else {
      setActionLabel('Inclusão');
    }
    setId(id);
    if (id !== 0) {
      listOrderTypesById(id);
    }
  }, []);

  const listOrderTypesById = async (id: number): Promise<void> => {
    setLoading(true);
    await request
      .find(id)
      .then(result => {
        setLoading(false);
        setOrderTypes({ ...result });
      })
      .catch(error => {
        setLoading(false);
        toastr.error(error.message);
      });
  };

  const createOrderTypes = async (orderTypes: IOrderType): Promise<void> => {
    setLoading(true);
    await request
      .create(orderTypes)
      .then(result => {
        setLoading(false);
        setOrderTypes({ ...result });
        toastr.success('Tipo de Pedido cadastrado com sucesso!');
        navigate(`/order-types-list`);
      })
      .catch(error => {
        setLoading(false);
        toastr.error(error.message);
      });
  };

  const updateOrderTypes = async (id: number, orderTypes: IOrderType): Promise<void> => {
    setLoading(true);
    await request
      .update(id, { ...orderTypes })
      .then(result => {
        setLoading(false);
        setOrderTypes({ ...result });
        toastr.success('Tipo de Pedido atualizado com sucesso!');
        navigate(`/order-types-list`);
      })
      .catch(error => {
        setLoading(false);
        toastr.error(error.message);
      });
  };

  const removeOrderTypes = async (): Promise<void> => {
    setLoading(true);
    await request
      .remove(id)
      .then(() => {
        setLoading(false);
        navigate(`/order-types-list`);
        toastr.success('Tipo de Pedido deletado com sucesso!');
      })
      .catch(error => {
        setLoading(false);
        toastr.error(error.message);
      });
  };

  const handleSubmit = async () => {
    const validation = validateSchema();

    const schema = yup.object({
      description: yup.string().required('Descrição é obrigatório'),
    });

    const result = await validation.validate(schema, orderTypes);
    setErrors(result.errors);
    if (!result.isValid) {
      return;
    }
    if (action === 'edit') {
      await updateOrderTypes(id, orderTypes);
    } else {
      await createOrderTypes(orderTypes);
    }
  };

  return (
    <>
      <TitleContainer>
        <h1>{`${actionLabel} de Tipos de Pedido`}</h1>
      </TitleContainer>
      <PageCard>
        <Form>
          {loading && <BackdropCustom />}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} sm={6}>
              <TextField
                sx={{ width: '100%' }}
                size="small"
                label="Descrição"
                value={orderTypes?.description}
                onChange={e => setOrderTypes({ ...orderTypes, description: e.target.value })}
                autoComplete="off"
                error={Boolean(errors?.link)}
                helperText={Boolean(errors?.link) ? errors?.link : ''}
              />
            </Grid>

            <Grid item xs={12} md={6} sm={6}>
              <FormControlLabel
                label="Ativo"
                control={
                  <Checkbox
                    onChange={e => setOrderTypes({ ...orderTypes, isActive: e.target.checked })}
                    checked={Boolean(orderTypes.isActive)}
                  />
                }
              />
            </Grid>
          </Grid>

          <ButtonGroup>
            <Button onClick={handleToOrderTypesList} variant="contained" color="inherit" disabled={loading}>
              Voltar
            </Button>
            {id !== 0 && (
              <Button onClick={removeOrderTypes} variant="contained" disabled={loading} color="error">
                Excluir
              </Button>
            )}

            <Button onClick={handleSubmit} loading={loading} variant="contained" color="success">
              Confirmar
            </Button>
          </ButtonGroup>
        </Form>
      </PageCard>
    </>
  );
};

export { OrderTypes };
