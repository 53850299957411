import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  FormControlLabel,
  Switch,
} from '@mui/material';

import IPage from '../../interfaces/IPage';
import { useToastr } from '../../hooks/useToastr';
import { PageCard } from '../../components/page-card/PageCard';
import { BackdropCustom } from '../../components/backdrop/Backdrop';
import { TitleContainer } from '../../components/title-container/TitleContainer';
import { useCustomerPortalUsersService } from '../../services/useCustomerPortalUsersService';
import CustomTableSortLabel from '../../components/table/CustomTableSortLabel/CustomTableSortLabel';
import CustomTablePagination from '../../components/table/CustomTablePagination/CustomTablePagination';
import { ICustomerPortalUserCustomer } from '../../interfaces/ICustomerPOrtalUserCustomer';
import { FilterArea } from './CustomerPortalUsersList.styles';
import useCpfCnpjFormat from '../../util/useCpfCnpjFormat';
import CustomerPortalUsersMenuItem from './CustomerPortalUsersMenuItem/CustomerPortalUsersMenuItem';
import { formatPhoneNumber } from '../../util/formatPhoneNumber';

interface IParams {
  perPage: number;
  currentPage: number;
  orderField: string;
  orderDirection: 'asc' | 'desc';
  filterField?: string;
  filterValue?: string;
  precision?: string;
  onlyIntives?: boolean;
  delay?: number;
}

const fields = [
  {
    field: 'name',
    label: 'Nome',
  },
  {
    field: 'email',
    label: 'E-mail',
  },
  {
    field: 'phoneNumber',
    label: 'Telefone',
  },
  {
    field: 'corporateName',
    label: 'Nome Cliente',
  },
  {
    field: 'corporateDocument',
    label: 'Documento Cliente',
  },
];

const CustomerPortalUsersList: React.FC<IPage> = ({ title }) => {
  window.document.title = title;

  const { findAllCustomerPortalUser, activeCustomerPortalUser } = useCustomerPortalUsersService();
  const toastr = useToastr();
  const timeout = useRef<any>(null);
  const { formatCnpj, formatCpf } = useCpfCnpjFormat();

  const [customerPortalUsersCustomers, setCustomerPortalUsersCustomers] = useState<ICustomerPortalUserCustomer[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderField, setOrderField] = useState<string>('name');
  const [filterField, setFilterField] = useState<string>('name');
  const [filterValue, setFilterValue] = useState<string>('');
  const [precision, setPrecision] = useState('containing');
  const [onlyIntives, setOnlyIntives] = useState(false);
  const [delay, setDelay] = useState(0);

  const [loading, setLoading] = useState(false);

  const handlePagination = async (
    perPage: number,
    currentPage: number,
    orderField: string,
    orderDirection: 'asc' | 'desc',
  ) => {
    handleListAll({
      perPage,
      currentPage,
      orderField,
      orderDirection,
      filterField,
      filterValue,
      precision,
      onlyIntives,
      delay: 0,
    });
  };

  const handleListAll = async ({
    perPage,
    currentPage,
    orderField,
    orderDirection,
    filterField,
    filterValue,
    precision,
    onlyIntives,
    delay,
  }: IParams) => {
    clearInterval(timeout.current);
    timeout.current = setTimeout(async () => {
      setLoading(true);
      let query = `perPage=${perPage}&currentPage=${
        currentPage + 1
      }&orderBy=${orderField}&orderDirection=${orderDirection}`;

      query =
        query +
        `&filterField=${filterField}&filterValue=${filterValue}&precision=${
          precision || 'containing'
        }&onlyIntives=${onlyIntives}`;

      await findAllCustomerPortalUser(query)
        .then(response => {
          if (response.data.length > 0) {
            setCustomerPortalUsersCustomers(response.data);
            setTotalRows(response.totalRows);
            setLoading(false);
          } else {
            setCustomerPortalUsersCustomers([]);
            setTotalRows(0);
            setPerPage(10);
            setCurrentPage(0);
            setLoading(false);
          }
        })
        .catch(error => {
          toastr.error(error?.message || 'Contate a equipe de suporte');
        })
        .finally(() => {
          setLoading(false);
        });
    }, delay || 0);
  };

  const handleActiveCustomerPortalUser = useCallback(
    async (userCustomerId: string) => {
      setLoading(true);
      await activeCustomerPortalUser(userCustomerId)
        .then(() => {
          toastr.success('Usuário ativado com sucesso');

          handlePagination(perPage, currentPage, orderField, orderDirection);
        })
        .catch(error => {
          toastr.error(error?.message || 'Contate a equipe de suporte');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [perPage, currentPage, orderField, orderDirection, toastr, setLoading],
  );

  useEffect(() => {
    handleListAll({
      perPage,
      currentPage,
      orderField,
      orderDirection,
      filterField,
      filterValue,
      precision,
      onlyIntives,
      delay,
    });
  }, [perPage, currentPage, orderField, orderDirection, filterField, precision, filterValue, onlyIntives, delay]);

  const handleTextFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
    setTotalRows(0);
    setCurrentPage(0);
    setDelay(500);
  };

  return (
    <>
      <TitleContainer>
        <h1>Usuários portal boletos</h1>
      </TitleContainer>

      <PageCard>
        <FilterArea container spacing={2} style={{ marginBottom: '36px' }}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel id="select-filter">Filtrar por</InputLabel>
              <Select
                label="Filtrar Por"
                value={`${filterField}`}
                onChange={e => {
                  setFilterField(`${e.target.value}`);
                }}
              >
                {fields.map(filter => {
                  return (
                    <MenuItem key={filter.field} value={filter.field}>
                      {filter.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Precisão</InputLabel>
              <Select
                label="Precisão"
                value={`${precision}`}
                onChange={e => {
                  setPrecision(`${e.target.value}` === 'equal' ? 'equal' : 'containing');
                }}
              >
                <MenuItem key={'equal'} value={'equal'}>
                  Igual
                </MenuItem>
                <MenuItem key={'containing'} value={'containing'}>
                  Contendo
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              fullWidth
              label="Valor a filtrar"
              placeholder="Valor a filtrar"
              value={filterValue}
              onChange={handleTextFilterChange}
              size="small"
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <FormControlLabel
              control={<Switch checked={onlyIntives} onChange={() => setOnlyIntives(!onlyIntives)} />}
              label="Apenas Inativos"
            />
          </Grid>
        </FilterArea>

        <TableContainer sx={{ marginTop: '2px', paddingLeft: '8px', paddingRight: '8px' }}>
          {loading && <BackdropCustom />}
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': {
                    borderBottom: 1,
                    borderColor: '#E5E5E5',
                  },
                }}
              >
                <TableCell size="small">Ações</TableCell>
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="name"
                  label="Nome"
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                  handleRequest={handlePagination}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="email"
                  label="Email"
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                  handleRequest={handlePagination}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="phoneNumber"
                  label="Telefone"
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                  handleRequest={handlePagination}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="isActive"
                  label="Ativo"
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                  handleRequest={handlePagination}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="corporateName"
                  label="Nome Cliente"
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                  handleRequest={handlePagination}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="corporateDocument"
                  label="Documento Cliente"
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                  handleRequest={handlePagination}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {customerPortalUsersCustomers &&
                customerPortalUsersCustomers.map(customerPortalUsersCustomer => {
                  return (
                    <TableRow
                      key={customerPortalUsersCustomer.id}
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}
                    >
                      <CustomerPortalUsersMenuItem
                        customerPortalUsersCustomer={customerPortalUsersCustomer}
                        handleActiveCustomerPortalUser={handleActiveCustomerPortalUser}
                      />

                      <TableCell size="small">{customerPortalUsersCustomer.customerPortalUser.name}</TableCell>
                      <TableCell size="small">{customerPortalUsersCustomer.customerPortalUser.email}</TableCell>
                      <TableCell size="small">
                        {customerPortalUsersCustomer.customerPortalUser.phoneNumber
                          ? formatPhoneNumber(customerPortalUsersCustomer.customerPortalUser.phoneNumber)
                          : ''}
                      </TableCell>
                      <TableCell size="small">{customerPortalUsersCustomer.isActive ? 'Sim' : 'Não'}</TableCell>
                      <TableCell size="small">{customerPortalUsersCustomer.customer.corporateName}</TableCell>
                      <TableCell size="small">
                        {customerPortalUsersCustomer.customer.corporateDocument.length <= 1
                          ? formatCpf(customerPortalUsersCustomer.customer.corporateDocument)
                          : formatCnpj(customerPortalUsersCustomer.customer.corporateDocument)}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <CustomTablePagination
            totalRows={totalRows}
            currentPage={currentPage}
            perPage={perPage}
            setPerPage={setPerPage}
            setCurrentPage={setCurrentPage}
            orderField={orderField}
            orderDirection={orderDirection}
          />
        </TableContainer>
      </PageCard>
    </>
  );
};

export default CustomerPortalUsersList;
