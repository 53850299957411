import {
  HeaderContentLeft,
  HeaderContentRow,
  HeaderContentColumn,
  HeaderContentRight,
  HeaderContent,
  HeaderBold,
  HeaderFlex,
  HeaderTitle,
  HeaderComment,
  HeaderNumberArea,
  HeaderNumberInternal,
  HeaderNumberLine,
  ImageLink,
} from './Order.styled';
import { IOrder } from '../../interfaces/IOrder';
import { IActiveImages } from '../../interfaces/IActiveImages';
import moment from 'moment';
import useCpfCnpjFormat from '../../util/useCpfCnpjFormat';
import { ICustomerAddress } from '../../interfaces/ICustomerAddress';

interface IProps {
  order: IOrder;
  invoicingAddress?: ICustomerAddress | null;
  activeImages?: IActiveImages | null;
}

const OrderHead = (props: IProps) => {
  const { formatCnpj } = useCpfCnpjFormat();

  function formatPhoneNumber(v: string) {
    var r = v.replace(/\D/g, '');
    r = r.replace(/^0/, '');
    if (r.length > 10) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (r.length > 5) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3');
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else {
      r = r.replace(/^(\d*)/, '($1');
    }
    return r;
  }
  return (
    <>
      <ImageLink href={props.activeImages?.headerLink} target="blank">
        <img src={props.activeImages?.headerImage} width="100%" alt="Cabeçalho" />
      </ImageLink>
      <HeaderContent>
        <HeaderContentLeft style={{ flex: 2 }}>
          <HeaderTitle>Pedido de Venda</HeaderTitle>

          <div style={{ flex: 1 }}>
            <HeaderComment>
              <HeaderBold>Cliente: </HeaderBold>
              <span>
                {props.order.customer?.code} - {props.order.customer?.tradeName}
              </span>
            </HeaderComment>
          </div>

          <HeaderContentRow>
            <HeaderContentColumn style={{ flex: 2, width: '100%' }}>
              <HeaderFlex>
                <HeaderBold>CNPJ: </HeaderBold>
                <span>{formatCnpj(`${props.order.customer?.corporateDocument}`)}</span>
              </HeaderFlex>

              <HeaderFlex>
                <HeaderBold>Telefone: </HeaderBold>
                <span> +55 {formatPhoneNumber(`${props.order.customer?.phoneNumber}`)}</span>
              </HeaderFlex>

              <HeaderFlex>
                <HeaderBold>E-mail: </HeaderBold>
                <span>{props.order.customer?.email}</span>
              </HeaderFlex>
            </HeaderContentColumn>

            <HeaderContentColumn style={{ flex: 5, marginLeft: '8x' }}>
              <HeaderFlex>
                <HeaderBold>Município: </HeaderBold>
                <span>{props.invoicingAddress?.city}</span>
              </HeaderFlex>
              <HeaderFlex>
                <HeaderBold>UF: </HeaderBold>
                <span>{props.invoicingAddress?.state}</span>
              </HeaderFlex>
              <HeaderFlex>
                <HeaderBold>Contato: </HeaderBold>
                <span>{props.order.customer?.contact}</span>
              </HeaderFlex>
            </HeaderContentColumn>
          </HeaderContentRow>
        </HeaderContentLeft>

        <HeaderContentRight style={{ flex: 2 }}>
          <HeaderNumberArea>
            <div style={{ width: '100%' }}>
              <HeaderNumberInternal>Pedido:</HeaderNumberInternal>
              <HeaderNumberInternal>Emissão:</HeaderNumberInternal>
            </div>
            <HeaderNumberLine></HeaderNumberLine>
            <div style={{ width: '100%' }}>
              <HeaderNumberInternal>
                {new Intl.NumberFormat('pt-br', {}).format(parseInt(`${props.order.orderNumber}`))}
              </HeaderNumberInternal>
              <HeaderNumberInternal>{moment(props.order.orderDate).format('DD/MM/yyyy')}</HeaderNumberInternal>
            </div>
          </HeaderNumberArea>
        </HeaderContentRight>
      </HeaderContent>
    </>
  );
};

export default OrderHead;
