import React, { useCallback, useEffect, useState } from 'react';
import { FormControlLabel, Grid, Switch, Tooltip } from '@mui/material';
import Divider from '@mui/material/Divider';
import PhoneIcon from '@mui/icons-material/Phone';
import PlaceIcon from '@mui/icons-material/Place';
import { FiArrowLeft } from 'react-icons/fi';

import IPage from '../../interfaces/IPage';
import {
  Container,
  CardContainer,
  CardTitle,
  CustomerContent,
  Card,
  CreditLimitCard,
  TitleContainerCustom,
} from './CustomerDetails.styles';
import { ICustomer } from '../../interfaces/ICustomer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useCustomerService } from '../../services/useCustomerService';
import { ICustomerAddress } from '../../interfaces/ICustomerAddress';
import { useAuth } from '../../hooks/auth';
import { useToastr } from '../../hooks/useToastr';
import { formatPhone } from '../../util/formatPhone';
import { Email } from '@mui/icons-material';
import CardLabel from '../../components/card-label/CardLabel';
import useCpfCnpjFormat from '../../util/useCpfCnpjFormat';
import { ISegment } from '../../interfaces/ISegment';
import { ICustomerCreditLimit } from '../../interfaces/ICustomerCreditLimit';
import { IRequestPostParams } from '../../interfaces/IRequestPostParams';
import { useOrderService } from '../../services/useOrderService';
import { IOrder } from '../../interfaces/IOrder';
import CustomerOrders from './CustomerOrders/CustomerOrders';
import CustomTablePagination from '../../components/table/CustomTablePagination/CustomTablePagination';
import CustomerInvoices from './CustomerInvoices/CustomerInvoices';
import IInvoice from '../../interfaces/IInvoice';
import { useOrderInvoicesService } from '../../services/useOrderInvoiceService';
import IReceivableAccount from '../../interfaces/IReceivableAccount';
import { useReceivableAccountsService } from '../../services/useReceivableAccountService';
import CustomerReceivableAccounts from './CustomerReceivableAccounts/CustomerReceivableAccounts';
import { BackdropCustom } from '../../components/backdrop/Backdrop';

const CustomerDetails: React.FC<IPage> = ({ title }) => {
  window.document.title = title;
  const location = useLocation();

  const { listById } = useCustomerService();
  const { formatCnpj } = useCpfCnpjFormat();

  const { signOut } = useAuth();
  const navigate = useNavigate();
  const toastr = useToastr();

  const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value);
  };

  const { listAll } = useOrderService();
  const { listInvoiceByCustomerId } = useOrderInvoicesService();
  const { listReceivableAccountByCustomerId } = useReceivableAccountsService();

  const [customerId, setCustomerId] = useState('');
  const [code, setCode] = useState('');
  const [corporateName, setCorporateName] = useState('');
  const [tradeName, setTradeName] = useState('');
  const [corporateDocument, setCorporateDocument] = useState('');
  const [stateRegistration, setStateRegistration] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [, setContact] = useState('');
  const [segment, setSegment] = useState<ISegment | null>(null);
  const [, setDefaultPaymentConditionId] = useState<string | null>('');
  const [, setDefaultPaymentFormId] = useState<string | null>('');
  const [, setDefaultCarrierId] = useState<string | null>('');
  const [, setRedeliveryCarrierId] = useState<string | null>('');
  const [customerAddress, setCustomerAddress] = useState<ICustomerAddress | null>(null);
  const [customerCreditLimit, setCustomerCreditLimit] = useState<ICustomerCreditLimit | null>(null);

  const [orders, setOrders] = useState<IOrder[]>([]);
  const [totalRowsOrders, setTotalRowsOrders] = useState(0);
  const [currentPageOrders, setCurrentPageOrders] = useState(0);
  const [perPageOrders, setPerPageOrders] = useState(4);

  const [invoices, setInvoices] = useState<IInvoice[]>([]);
  const [totalRowsInvoices, setTotalRowsInvoices] = useState(0);
  const [currentPageInvoices, setCurrentPageInvoices] = useState(0);
  const [perPageInvoices, setPerPageInvoices] = useState(4);

  const [receivableAccounts, setReceivableAccounts] = useState<IReceivableAccount[]>([]);
  const [totalRowsReceivableAccounts, setTotalRowsReceivableAccounts] = useState(0);
  const [currentPageReceivableAccounts, setCurrentPageReceivableAccounts] = useState(0);
  const [perPageReceivableAccounts, setPerPageReceivableAccounts] = useState(4);
  const [onlyOpenReceivableAccounts, setOnlyOpenReceivableAccounts] = useState(true);

  const [loading, setLoading] = useState(false);

  const handleListOrders = useCallback(async () => {
    const filter: IRequestPostParams = {
      currentPage: currentPageOrders + 1,
      filterField: 'customerCode',
      filterValue: code,
      finalDate: null,
      initialDate: null,
      orderBy: 'orderDate',
      orderDirection: 'desc',
      perPage: 4,
      precision: 'equal',
      status: [],
    };

    setLoading(true);
    await listAll(filter)
      .then(response => {
        if (response?.data?.length > 0) {
          setOrders(response.data);
          setTotalRowsOrders(response.totalRows);
        } else {
          setOrders([]);
          setTotalRowsOrders(0);
          setCurrentPageOrders(0);
        }
      })
      .catch(error => {
        toastr.error(error?.message || 'Contate a equipe de suporte');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentPageOrders, code]);

  const handlePaginationOrders = async () => {};

  const handleListInvoices = useCallback(async () => {
    const queryParams = `perPage=4&currentPage=${currentPageInvoices + 1}&orderBy=emissionDate&orderDirection=DESC`;

    setLoading(true);
    await listInvoiceByCustomerId(customerId, queryParams)
      .then(response => {
        if (response.data.length > 0) {
          setInvoices(response.data);
          setTotalRowsInvoices(response.totalRows);
        } else {
          setInvoices([]);
          setTotalRowsInvoices(0);
          setCurrentPageInvoices(0);
        }
      })
      .catch(error => {
        toastr.error(error?.message || 'Contate a equipe de suporte');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [customerId, currentPageInvoices]);

  const handlePaginationInvoices = async () => {};

  const handleListReceivableAccounts = useCallback(async () => {
    const queryParams = `perPage=4&currentPage=${
      currentPageReceivableAccounts + 1
    }&orderBy=dueDate&orderDirection=DESC&onlyOpen=${onlyOpenReceivableAccounts}`;

    setLoading(true);
    await listReceivableAccountByCustomerId(customerId, queryParams)
      .then(response => {
        if (response.data.length > 0) {
          setReceivableAccounts(response.data);
          setTotalRowsReceivableAccounts(response.totalRows);
        } else {
          setReceivableAccounts([]);
          setTotalRowsReceivableAccounts(0);
          setCurrentPageReceivableAccounts(0);
        }
      })
      .catch(error => {
        toastr.error(error?.message || 'Contate a equipe de suporte');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [customerId, currentPageReceivableAccounts, onlyOpenReceivableAccounts]);

  const handlePaginationReceivableAccounts = async () => {};

  const handleListCustomer = useCallback(async () => {
    const id = location.pathname.replace('/customer-details', '').replace('/', '');

    setCustomerId(id);

    if (id) {
      await listById(id)
        .then(async response => {
          const customer: ICustomer = response;

          setCode(customer.code ? customer.code : '');
          setCorporateName(customer.corporateName);
          setTradeName(customer.tradeName);
          setCorporateDocument(customer.corporateDocument);
          setStateRegistration(customer.stateRegistration);
          setCustomerAddress(customer.customerAddress[0]);
          setPhoneNumber(customer.phoneNumber);
          setEmail(customer.email);
          setContact(customer.contact);
          setSegment(customer.segment ? customer.segment : null);
          setDefaultPaymentConditionId(customer.defaultPaymentConditionId || null);
          setDefaultPaymentFormId(customer.defaultPaymentFormId || null);
          setDefaultCarrierId(customer.defaultCarrierId || null);
          setRedeliveryCarrierId(customer.redeliveryCarrierId || null);
          setCustomerCreditLimit(customer?.customerCreditLimit ? customer?.customerCreditLimit[0] : null);
        })
        .catch(error => {
          if (error.status === 401) {
            signOut();
            navigate('/');
          }

          toastr.error(error?.message || 'Contate a equipe de suporte');
        });
    }
  }, [navigate, signOut, toastr, location.pathname, handleListOrders]);

  useEffect(() => {
    handleListCustomer();
  }, []);

  useEffect(() => {
    if (code) {
      handleListOrders();
    }
  }, [code, currentPageOrders]);

  useEffect(() => {
    if (customerId) {
      handleListInvoices();
    }
  }, [customerId, currentPageInvoices]);

  useEffect(() => {
    if (customerId) {
      handleListReceivableAccounts();
    }
  }, [customerId, currentPageReceivableAccounts, onlyOpenReceivableAccounts]);

  return (
    <>
      <TitleContainerCustom>
        <h1>Cliente</h1>

        <Link to={'/customers-list'}>
          <FiArrowLeft /> Voltar
        </Link>
      </TitleContainerCustom>
      <Container>
        <Grid container>
          <Grid item md={7} sm={12} xs={12}>
            <CardContainer elevation={6} sx={{ minHeight: '622px' }}>
              <Tooltip title={`${code} - ${corporateName}`}>
                <CardTitle>{`${code} - ${corporateName}`}</CardTitle>
              </Tooltip>
              <Divider />

              <CustomerContent>
                <Card>
                  <CardLabel
                    icon={<PhoneIcon />}
                    label={phoneNumber ? formatPhone(phoneNumber) : 'NÃO INFORMADO'}
                  ></CardLabel>
                  <CardLabel icon={<Email />} label={email ? email : 'NÃO INFORMADO'}></CardLabel>
                </Card>

                <Card>
                  <CardLabel
                    icon={<PlaceIcon />}
                    label={
                      customerAddress
                        ? `${customerAddress.street || 'NÃO INFORMADO'}, ${
                            customerAddress.number || 'NÃO INFORMADO'
                          } - ${customerAddress.neighborhood || 'NÃO INFORMADO'}`
                        : ''
                    }
                  />

                  <CardLabel label={customerAddress ? `CEP ${customerAddress.postalCode || 'NÃO INFORMADO'}` : ''} />
                  <CardLabel label={`${customerAddress?.city || 'NÃO INFORMADO'} - ${customerAddress?.state}`} />
                </Card>
              </CustomerContent>

              <Divider />

              <CustomerContent>
                <Card>
                  <CardLabel title="Razão social" label={corporateName} noneIcon={true}></CardLabel>
                  <CardLabel title="CNPJ" label={formatCnpj(corporateDocument)} noneIcon={true}></CardLabel>

                  {segment?.name && (
                    <CardLabel title="Segmento" label={segment?.name ? segment?.name : ''} noneIcon={true}></CardLabel>
                  )}
                </Card>

                <Card>
                  <CardLabel title="Nome fantasia" label={tradeName} noneIcon={true}></CardLabel>
                  <CardLabel title="Insc. Estadual" label={stateRegistration} noneIcon={true}></CardLabel>
                </Card>
              </CustomerContent>

              <Divider />

              <CreditLimitCard>
                <div>
                  <span>Limite total</span>

                  <div>
                    <span>{formatCurrency(customerCreditLimit?.creditLimit || 0)}</span>
                  </div>
                </div>
                <div>
                  <span>Limite utilizado</span>

                  <div>
                    <span>{formatCurrency(customerCreditLimit?.usedValue || 0)}</span>
                  </div>
                </div>
                <div>
                  <span>Limite disponível</span>

                  <div>
                    <span>{formatCurrency(customerCreditLimit?.availableValue || 0)}</span>
                  </div>
                </div>
              </CreditLimitCard>
            </CardContainer>
          </Grid>

          <Grid item md={5} sm={12} xs={12}>
            <CardContainer elevation={6} sx={{ minHeight: '520px' }}>
              <div className="title-card-with-switch">
                <CardTitle>Títulos</CardTitle>

                <FormControlLabel
                  control={
                    <Switch
                      checked={onlyOpenReceivableAccounts}
                      onChange={() => setOnlyOpenReceivableAccounts(!onlyOpenReceivableAccounts)}
                    />
                  }
                  label="Apenas abertos"
                />
              </div>
              <Divider />

              <CustomerReceivableAccounts receivableAccounts={receivableAccounts} />

              <CustomTablePagination
                totalRows={totalRowsReceivableAccounts}
                currentPage={currentPageReceivableAccounts}
                perPage={perPageReceivableAccounts}
                handleRequest={handlePaginationReceivableAccounts}
                setPerPage={setPerPageReceivableAccounts}
                setCurrentPage={setCurrentPageReceivableAccounts}
                orderField="dueDate"
                orderDirection="desc"
                changePerPageDisable={true}
              />
            </CardContainer>
          </Grid>

          <Grid item md={7} sm={12} xs={12}>
            <CardContainer elevation={6} sx={{ minHeight: '520px' }}>
              <CardTitle>Pedidos</CardTitle>
              <Divider />

              <CustomerOrders orders={orders} />

              <CustomTablePagination
                totalRows={totalRowsOrders}
                currentPage={currentPageOrders}
                perPage={perPageOrders}
                handleRequest={handlePaginationOrders}
                setPerPage={setPerPageOrders}
                setCurrentPage={setCurrentPageOrders}
                orderField="orderDate"
                orderDirection="desc"
                changePerPageDisable={true}
              />
            </CardContainer>
          </Grid>
          <Grid item md={5} sm={12} xs={12}>
            <CardContainer elevation={6} sx={{ minHeight: '520px' }}>
              <CardTitle>Notas Fiscais</CardTitle>
              <Divider />

              <CustomerInvoices invoices={invoices} />

              <CustomTablePagination
                totalRows={totalRowsInvoices}
                currentPage={currentPageInvoices}
                perPage={perPageInvoices}
                handleRequest={handlePaginationInvoices}
                setPerPage={setPerPageInvoices}
                setCurrentPage={setCurrentPageInvoices}
                orderField="emissionDate"
                orderDirection="desc"
                changePerPageDisable={true}
              />
            </CardContainer>
          </Grid>
        </Grid>
      </Container>

      {loading && <BackdropCustom />}
    </>
  );
};

export default CustomerDetails;
