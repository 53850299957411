import AppError from '../errors/AppError';
import { useAuth } from '../hooks/auth';
import { IOrder } from '../interfaces/IOrder';
import { IPaginationReturn } from '../interfaces/IPaginationReturn';
import { IRequestPostParams } from '../interfaces/IRequestPostParams';
import IUser from '../interfaces/IUser';
import { useRequest } from './useRequest';

const useOrderService = () => {
  const requestOrders = useRequest<IOrder>();
  const pathOrders = 'orders';
  const { state } = useAuth();

  const createOrder = async (order: IOrder): Promise<IOrder> => {
    return await requestOrders
      .post({ path: `${pathOrders}/`, sendAuthorization: true, body: order })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message, error.status);
      });
  };

  const updateOrder = async (id: string, order: IOrder): Promise<IOrder> => {
    return await requestOrders
      .put({ path: `${pathOrders}/${id}`, sendAuthorization: true, body: order })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message, error.status);
      });
  };

  const openOrder = async (id: string): Promise<IOrder> => {
    return await requestOrders
      .put({ path: `${pathOrders}/open-order/${id}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message, error.status);
      });
  };

  const cloneOrder = async (id: string): Promise<IOrder> => {
    return await requestOrders
      .put({ path: `${pathOrders}/clone/${id}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message, error.status);
      });
  };

  const listOrder = async (id: string, useAuth = false): Promise<IOrder> => {
    return await requestOrders
      .getOne({ path: `${pathOrders}/${id}`, sendAuthorization: useAuth })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message, error.status);
      });
  };

  const listAll = async (filter: IRequestPostParams): Promise<IPaginationReturn<IOrder[]>> => {
    const userSession = sessionStorage.getItem('@PEDIDO-WEB:user');

    const user: IUser = userSession ? JSON.parse(userSession) : null;

    filter.idSalesRegion = user.seller?.salesRegion?.id ? user.seller?.salesRegion?.id : null;

    const response = await requestOrders
      .postAndGetManyPaginated({
        path: `${pathOrders}/list`,
        headers: {
          Authorization: `bearer ${state.access_token}`,
        },
        body: filter,
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });

    return response;
  };

  const deleteOrder = async (id: string): Promise<void> => {
    await requestOrders
      .remove({ path: `${pathOrders}/${id}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message, error.status);
      });
  };

  return { listOrder, listAll, createOrder, updateOrder, deleteOrder, cloneOrder, openOrder };
};

export { useOrderService };
