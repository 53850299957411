import React, { useState } from 'react';
import IPage from '../../interfaces/IPage';
import IFormError from '../../interfaces/IFormError';
import { IconButton, InputAdornment } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import * as yup from 'yup';

import { useToastr } from '../../hooks/useToastr';
import { validateSchema } from '../../util/validateSchema';

import ILogin from '../../interfaces/ILogin';
import { useAuth } from '../../hooks/auth';
import logoLogin from '../../assets/logoLogin.svg';

import {
  AccessMessage,
  EmailTextFiled,
  Form,
  LoginButton,
  LoginFormContainer,
  LoginStyled,
  PasswordTextFiled,
  SecondaryTitle,
} from './Login.styles';
import { useLocalStorage } from '../../services/useLocalStorage';

const Login = (props: IPage) => {
  window.document.title = props.title;

  const toastr = useToastr();
  const [errors, setErrors] = useState<IFormError | null>(null);
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const { signIn } = useAuth();

  const localStorage = useLocalStorage();

  const customization = localStorage.getItem('@PEDIDO-WEB:customization');

  const schema = yup.object({
    username: yup
      .string()
      .required('E-mail não informado')
      .matches(/.+@.+\..+/, 'Informe um e-mail válido'),
    password: yup.string().required('Senha não informada'),
  });
  const [login, setLogin] = useState<ILogin>({ username: '', password: '' });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    const validation = validateSchema();
    event.preventDefault();
    const result = await validation.validate(schema, login).then(result => {
      return result;
    });
    if (!result.isValid) {
      setErrors(result.errors);
      return;
    } else {
      setErrors(null);
    }

    setLoading(true);
    await signIn(login.username, login.password)
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        toastr.error(error.message);
        setLoading(false);
      });
  };

  return (
    <LoginStyled isDark={false}>
      <LoginFormContainer>
        <img src={customization?.logoLogin ? customization?.logoLogin : logoLogin} alt="logo" />

        <SecondaryTitle>Pedido WEB</SecondaryTitle>
        <Form onSubmit={e => handleSubmit(e)}>
          <AccessMessage>Login</AccessMessage>
          <EmailTextFiled
            variant="outlined"
            value={login.username}
            onChange={e => setLogin({ ...login, username: e.target.value.toLowerCase() })}
            label="E-mail"
            size="small"
            placeholder="usuario@dominio.com.br"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
            error={Boolean(errors?.username)}
            helperText={Boolean(errors?.username) ? errors?.username : ''}
          />
          <PasswordTextFiled
            variant="outlined"
            value={login.password}
            onChange={e => setLogin({ ...login, password: e.target.value })}
            label="Senha"
            size="small"
            type={showPassword ? 'text' : 'password'}
            placeholder="Senha"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {!showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(errors?.password)}
            helperText={Boolean(errors?.password) ? errors?.password : ''}
          />

          <LoginButton type="submit" variant="contained" loading={loading} loadingPosition="start" startIcon={<i />}>
            Entrar
          </LoginButton>
        </Form>
      </LoginFormContainer>
    </LoginStyled>
  );
};

export default Login;
