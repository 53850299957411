import { Backdrop, CircularProgress } from '@mui/material';

const BackdropCustom: React.FC = () => {
  return (
    <Backdrop sx={{ color: '#0054B0', zIndex: theme => theme.zIndex.drawer + 1 }} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export { BackdropCustom };
