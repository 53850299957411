import styled from 'styled-components';
import LoadingButton from '@mui/lab/LoadingButton';
import { Card, TextField } from '@mui/material';

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: #fff;

  @media (max-width: 400px) {
    background: #fff;
  }
`;

export const Content = styled(Card)`
  width: 23.75rem;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 1.25rem;
  margin: 24px;

  form {
    margin-top: 2rem;
  }

  > strong {
    font-size: 0.75rem;
    font-weight: 600;
    margin-top: 0.25rem;
  }

  @media (max-width: 900px) {
    width: 20rem;
  }
`;

export const LogoImg = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 10rem;
  }
`;

export const Input = styled.div`
  font-size: 0.75rem;
  font-weight: 600;

  > div {
    display: flex;
    justify-content: space-between;
  }

  a {
    text-decoration: none;
    color: #1876d2;

    &:hover {
      color: #0054b0;
    }
  }

  & + & {
    margin-top: 16px;
  }
`;

export const TextFieldCustom = styled(TextField)`
  && {
    width: 100%;
    margin-top: 0.35rem;
  }
`;

export const ButtonCustom = styled(LoadingButton)`
  && {
    width: 100%;
    padding: 0.51rem;
    margin-top: 1rem;
    font-weight: 700;
    text-transform: none;
  }
`;

export const SignInLink = styled.div`
  margin: 1.5rem 0 0.5rem 0;

  strong {
    font-size: 0.75rem;
    font-weight: 600;
    margin-top: 0.25rem;
    margin-right: 0.25rem;
  }

  a {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    text-decoration: none;
    font-weight: 700;

    color: #1876d2;

    &:hover {
      color: #0054b0;
    }

    svg {
      margin-right: 16px;
    }
  }
`;
