import { MoreVertOutlined } from '@mui/icons-material';
import { Fade, IconButton, Menu, MenuItem, TableCell, TableCellProps } from '@mui/material';
import { useState } from 'react';

interface IMenuItemProps extends TableCellProps {
  id: string;
  handleToView?: (id: string) => void;
  handleToEdit?: (id: string) => void;
}

const CustomMenuItem: React.FC<IMenuItemProps> = ({ id, handleToView, handleToEdit }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableCell size="small" id={id} sx={{ width: '10px' }}>
      <IconButton
        disabled={!handleToView && !handleToEdit}
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size="small"
      >
        <MoreVertOutlined fontSize="small" />
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {handleToView && (
          <MenuItem
            onClick={() => {
              handleToView(id);
            }}
            sx={{ fontSize: '0.875rem' }}
          >
            Visualizar
          </MenuItem>
        )}
        {handleToEdit && (
          <MenuItem
            onClick={() => {
              handleToEdit(id);
            }}
            sx={{ fontSize: '0.875rem' }}
          >
            Editar
          </MenuItem>
        )}
      </Menu>
    </TableCell>
  );
};

export { CustomMenuItem };
