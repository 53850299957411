import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 8px 8px 12px;

  @media (max-width: 900px) {
    display: none;
  }
`;
