import React from 'react';
import { Container } from './Buton.styled';
import { LoadingButtonProps } from '@mui/lab';

interface ILoadingButtonProps extends LoadingButtonProps {
  defaultColor?: boolean;
  buttonSize?: 'small' | 'medium' | 'large';
}

const Button: React.FC<ILoadingButtonProps> = ({
  children,
  disabled = false,
  defaultColor = false,
  buttonSize = 'small',
  ...rest
}) => {
  return (
    <Container {...rest} disabled={disabled} default={defaultColor} size={buttonSize}>
      {children}
    </Container>
  );
};

export { Button };
