import { IOrder } from '../../interfaces/IOrder';
// import { Bold, Container, Content, Flex, FlexColumn } from './OrderTradeConditions.styled';
import {
  Button,
  Email,
  HeaderBold,
  HeaderContent,
  HeaderContentLeft,
  HeaderContentRow,
  HeaderContentColumn,
  HeaderContentRight,
  HeaderContentTotal,
  HeaderFlex,
  HeaderTitle,
  HeaderTitleValue,
  HeaderValue,
  Whatsapp,
  HeaderComment,
  OrderComments,
} from './Order.styled';
import { ICustomerAddress } from '../../interfaces/ICustomerAddress';
import IOrderParam from '../../interfaces/IOrderParam';

interface IProps {
  order: IOrder;
  orderParams: IOrderParam;
  invoicingAddress?: ICustomerAddress | null;
}

const OrderTradeConditions = (props: IProps) => {
  const getInvoicingAddress = (address: ICustomerAddress): string => {
    let str = `${address.street}, ${address.number} `;

    if (address.complement) {
      str = str + `, ${address.complement}`;
    }

    str = str + `${address.neighborhood}, ${address.city} - ${address.state}`;

    return str;
  };

  const formatCurrency = (value: number): string => {
    return new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value);
  };

  const getPhoNumber = (phoneNumber: string): string => {
    const test = phoneNumber.split('55').length > 1;
    return test ? phoneNumber.split('55')[1] : phoneNumber;
  };

  return (
    <HeaderContent style={{ marginBottom: '15px' }}>
      <HeaderContentLeft style={{ flex: 2 }}>
        <HeaderTitle>Condições Comerciais</HeaderTitle>

        <HeaderContentRow>
          <HeaderContentColumn>
            <HeaderFlex>
              <HeaderBold>Vendedor: </HeaderBold>
              <span>{props.order.seller?.name}</span>
            </HeaderFlex>
            <HeaderFlex>
              <HeaderBold>Condição de Pagamento: </HeaderBold>
              <span>{props.order?.paymentCondition?.name}</span>
            </HeaderFlex>
            <HeaderFlex>
              <HeaderBold>Forma de Pagamento: </HeaderBold>
              <span>{props.order?.paymentForm?.name}</span>
            </HeaderFlex>
          </HeaderContentColumn>

          <HeaderContentColumn style={{ flex: 5 }}>
            <HeaderFlex>
              <HeaderBold style={{ whiteSpace: 'nowrap' }}>Endereço de Faturamento: </HeaderBold>
              <span>{props.invoicingAddress && getInvoicingAddress(props.invoicingAddress)}</span>
            </HeaderFlex>
            <HeaderFlex>
              <HeaderBold>Tipo Frete: </HeaderBold>
              <span>{props.order.shippingTerms}</span>
            </HeaderFlex>
            <HeaderFlex>
              <HeaderBold>Transportadora: </HeaderBold>
              <span>{props.order.carrier?.name}</span>
            </HeaderFlex>
          </HeaderContentColumn>
        </HeaderContentRow>

        <HeaderComment>
          <HeaderBold style={{ height: '35px' }}>Observação: </HeaderBold>
          <span>{props.order?.comments}</span>
        </HeaderComment>

        <HeaderFlex>
          <HeaderBold>Confirme Seu Pedido Agora Mesmo </HeaderBold>
        </HeaderFlex>

        <HeaderFlex>
          <Button href={`mailto:${props.order?.seller?.email}`}>
            <Email />
            E-mail
          </Button>
          <Button href={`https://wa.me/${getPhoNumber(`${props.order?.seller?.phoneNumber}`)}`} target="blank">
            <Whatsapp /> <span style={{ marginLeft: '24px' }}>WhatsApp</span>
          </Button>
          <OrderComments>
            {props.order?.orderStatus?.comments ? ` ${props.order?.orderStatus?.comments} ` : ' '}
            {props.orderParams?.comments ? ` ${props.orderParams?.comments} ` : ' '}
          </OrderComments>
        </HeaderFlex>
      </HeaderContentLeft>
      <HeaderContentRight style={{ flex: 2 }}>
        <HeaderContentTotal>
          <HeaderFlex>
            <HeaderBold>Total:</HeaderBold>
          </HeaderFlex>
          <HeaderBold style={{ fontSize: '25PX', marginBottom: '5px' }}>
            {formatCurrency(props.order.totalValue || 0)}
          </HeaderBold>
          <HeaderFlex>
            <HeaderTitleValue>Valor Frete:</HeaderTitleValue>
            <HeaderValue>{formatCurrency(props.order.freightTotalValue || 0)}</HeaderValue>
          </HeaderFlex>
          <HeaderFlex>
            <HeaderTitleValue>Valor IPI:</HeaderTitleValue>
            <HeaderValue>{formatCurrency(props.order.IPI_TotalValue || 0)}</HeaderValue>
          </HeaderFlex>
          <HeaderFlex>
            <HeaderTitleValue>Valor ICMS ST:</HeaderTitleValue>
            <HeaderValue>{formatCurrency(props.order.ICMSST_TotalValue || 0)}</HeaderValue>
          </HeaderFlex>
          <HeaderFlex>
            <HeaderTitleValue>Desconto Suframa:</HeaderTitleValue>
            <HeaderValue>{formatCurrency(props.order.discountFTZTotalValue || 0)}</HeaderValue>
          </HeaderFlex>
          <HeaderFlex>
            <HeaderTitleValue>Total Produtos:</HeaderTitleValue>
            <HeaderValue>{formatCurrency(props.order.grossTotalValue || 0)}</HeaderValue>
          </HeaderFlex>
        </HeaderContentTotal>
      </HeaderContentRight>
    </HeaderContent>
  );
};

export default OrderTradeConditions;
