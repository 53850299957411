import AppError from '../errors/AppError';
import IMeasurementUnit from '../interfaces/IMeasurementUnit';

import { IPaginationReturn } from '../interfaces/IPaginationReturn';

import { useRequest } from './useRequest';

const useMeasurementUnitService = () => {
  const request = useRequest<IMeasurementUnit>();
  const path = 'measurement-units';
  const listAll = async (queryParams: string): Promise<IPaginationReturn<IMeasurementUnit[]>> => {
    return await request
      .getManyPaginated({ path: `${path}?${queryParams}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const listById = async (id: string): Promise<IMeasurementUnit> => {
    return await request
      .getOne({ path: `${path}/${id}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const update = async (id: string, resource: IMeasurementUnit): Promise<IMeasurementUnit> => {
    return await request
      .put({ path: `${path}/${id}`, sendAuthorization: true, body: resource })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  return { listAll, listById, update };
};

export { useMeasurementUnitService };
