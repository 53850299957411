import AppError from '../errors/AppError';
import IUser from '../interfaces/IUser';
import { useRequest } from './useRequest';

const useLoginService = () => {
  const request = useRequest<IUser>();
  const path = 'session';
  const login = async (username: string, password: string): Promise<IUser> => {
    return await request
      .post({ path: `${path}`, body: { username, password }, sendAuthorization: false })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };
  return { login };
};

export { useLoginService };
