import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const TitleAreaContainer = styled.div`
  && {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
  }
`;

export const ButtonMui = styled(IconButton)`
  && {
    span {
      text-transform: none;
    }

    border-radius: 0;

    & + & {
      border-radius: 50%;
    }
  }
`;

export const IconButtonMui = styled(IconButton)`
  && {
    span {
      text-transform: none;
    }
  }
`;

export const ImageContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    border-radius: 4px;
    height: auto;
    width: 50px;

    border-style: 2px;
  }
`;
