import { Grid } from "@mui/material";
import styled from "styled-components";

export const ButtonGroupFilter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const CustomNoContentTableBody = styled.div`
  display: flex;
  font-size: 1rem;
  padding: 0 16px;
  justify-content: center;
`;

export const FilterArea = styled(Grid)`
  && {
    padding: 0px 8px;
    margin-bottom: 20px;
  }
`;
