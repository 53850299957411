import React, { useEffect, useRef, useState } from 'react';
import IPage from '../../interfaces/IPage';
import { TitleContainer } from '../../components/title-container/TitleContainer';
import { PageCard } from '../../components/page-card/PageCard';
import { useOrderStatusService } from '../../services/useOrderStatusService';
import IOrderStatus from '../../interfaces/IOrderStatus';
import EditIcon from '@mui/icons-material/Edit';

import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import CustomTableSortLabel from '../../components/table/CustomTableSortLabel/CustomTableSortLabel';
import CustomTablePagination from '../../components/table/CustomTablePagination/CustomTablePagination';
import { useToastr } from '../../hooks/useToastr';
import IOrderStatusProps from './components/IOrderStatusProps';
import OrderStatusForm from './components/OrderStatusForm';
import styled from 'styled-components';
import { BackdropCustom } from '../../components/backdrop/Backdrop';

const Color = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

const OrderStatusList: React.FC<IPage> = ({ title }) => {
  window.document.title = title;

  const [status, setStatus] = useState<IOrderStatus[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderField, setOrderField] = useState<string>('id');
  const [loading, setLoading] = useState(false);

  const { listOrderStatus } = useOrderStatusService();
  const orderStatusForm = useRef<IOrderStatusProps>(null);

  const toastr = useToastr();

  const handleListOrderStatus = async (
    perPage: number,
    currentPage: number,
    orderField: string,
    orderDirection: 'asc' | 'desc',
  ) => {
    setLoading(true);
    await listOrderStatus(
      `perPage=${perPage}&currentPage=${currentPage + 1}&orderBy=${orderField}&orderDirection=${orderDirection}`,
    )
      .then(response => {
        const orders = response.data;

        if (orders.length > 0) {
          setStatus(response.data);
          setTotalRows(response.totalRows);
          setLoading(false);
        } else {
          setStatus([]);
          setTotalRows(0);
          setPerPage(10);
          setCurrentPage(0);
          setLoading(false);
        }
      })
      .catch(error => {
        toastr.error(error?.message || 'Contate a equipe de suporte');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleListOrderStatus(perPage, currentPage, orderField, orderDirection);
  }, [perPage, currentPage, orderField, orderDirection]);

  const handleDialogIsClosed = () => {
    handleListOrderStatus(perPage, currentPage, orderField, orderDirection);
  };
  return (
    <>
      <TitleContainer>
        <h1>Status de Pedidos</h1>
      </TitleContainer>
      <OrderStatusForm ref={orderStatusForm} dialogClosed={handleDialogIsClosed} />
      <PageCard>
        <TableContainer sx={{ marginTop: '2px', paddingLeft: '8px', paddingRight: '8px' }}>
          {loading && <BackdropCustom />}
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': {
                    borderBottom: 1,
                    borderColor: '#E5E5E5',
                  },
                }}
              >
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="id"
                  label="Código"
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="description"
                  label="Descrição"
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="color"
                  label="Cor"
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="icon"
                  label="Ícone"
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                  disableOrder={true}
                />
                <CustomTableSortLabel
                  perPage={perPage}
                  currentPage={currentPage}
                  orderField={orderField}
                  field="order"
                  label="Ordenação"
                  orderDirection={orderDirection}
                  setField={setOrderField}
                  setDirection={setOrderDirection}
                  disableOrder={true}
                />
                <TableCell size="small">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {status &&
                status.map(status => {
                  return (
                    <TableRow
                      key={status.id}
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell size="small">{status.id}</TableCell>
                      <TableCell size="small">{status.description}</TableCell>
                      <TableCell size="small">
                        <Color style={{ backgroundColor: status.color || '#e5e5e5' }} />
                      </TableCell>
                      <TableCell size="small">
                        <img src={status?.icon} width="30" alt="Ícone" />
                      </TableCell>
                      <TableCell size="small">{status.order}</TableCell>
                      <TableCell size="small">
                        <Tooltip title="Editar" placement="top">
                          <IconButton disabled={loading} onClick={() => orderStatusForm.current?.openDialog(status.id)}>
                            <EditIcon style={{ color: '#0052BE' }} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <CustomTablePagination
            totalRows={totalRows}
            currentPage={currentPage}
            perPage={perPage}
            setPerPage={setPerPage}
            setCurrentPage={setCurrentPage}
            orderField={orderField}
            orderDirection={orderDirection}
          />
        </TableContainer>
      </PageCard>
    </>
  );
};

export default OrderStatusList;
