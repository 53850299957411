import AppError from '../errors/AppError';
import { useCustomerPortalAuth } from '../hooks/custmerPortalAuth';
import { ICustomerPortalCreateUser } from '../interfaces/ICustomerPortalCreateUser';
import { ICustomerPortalEmail } from '../interfaces/ICustomerPortalEmail';
import { ICustomerPortalUserCustomer } from '../interfaces/ICustomerPOrtalUserCustomer';
import { useRequest } from './useRequest';

const useCustomerPortalUserService = () => {
  const request = useRequest<ICustomerPortalEmail>();
  const requestCustomerPortalUserCustomer = useRequest<ICustomerPortalUserCustomer>();
  const requestActivateCustomerUser = useRequest<void>();
  const path = 'customer-portal/customer-portal-user';
  const { customerPortalSession } = useCustomerPortalAuth();

  const createUser = async (data: ICustomerPortalCreateUser): Promise<ICustomerPortalEmail> => {
    return await request
      .post({ path: `${path}`, body: { ...data }, sendAuthorization: false })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const findUserCustomer = async (id: string, tenantDomain: string): Promise<ICustomerPortalUserCustomer> => {
    return await requestCustomerPortalUserCustomer
      .getOne({ path: `${path}/customer/${id}?tenantDomain=${tenantDomain}`, sendAuthorization: false })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const activate = async (id: string, tenantDomain: string): Promise<void> => {
    return await requestActivateCustomerUser
      .patch({
        path: `${path}/activate/${id}?tenantDomain=${tenantDomain}`,
        sendAuthorization: false,
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const addCustomer = async (corporateDocument: string): Promise<ICustomerPortalEmail> => {
    return await request
      .post({
        path: `${path}/add`,
        body: { corporateDocument: corporateDocument },
        headers: {
          Authorization: `bearer ${customerPortalSession.token.access_token}`,
          tenant: customerPortalSession.user.tenant,
        },
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  return { createUser, findUserCustomer, activate, addCustomer };
};

export { useCustomerPortalUserService };
