import Content from '../components/content/Content';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { AppProvider } from '../hooks/AppProvider';
import React, { HtmlHTMLAttributes, useMemo, useState } from 'react';
import * as locales from '@mui/material/locale';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { useLocalStorage } from '../services/useLocalStorage';

type SupportedLocales = keyof typeof locales;

interface IProps extends HtmlHTMLAttributes<HTMLElement> {}

const TypeRouter: React.FC<IProps> = ({ children }) => {
  return (
    <>
      {process.env.REACT_APP_USE_BROWSER_ROUTER && process.env.REACT_APP_USE_BROWSER_ROUTER?.toUpperCase() === 'YES' ? (
        <BrowserRouter>{children}</BrowserRouter>
      ) : (
        <HashRouter>{children}</HashRouter>
      )}
    </>
  );
};

function App() {
  const [locale] = useState<SupportedLocales>('ptBR');

  const theme = useTheme();

  const themeWithLocale = useMemo(
    () => createTheme({ ...theme, typography: { fontSize: 12 } }, locales[locale]),
    [locale, theme],
  );

  const localStorage = useLocalStorage();

  const customization = localStorage.getItem('@PEDIDO-WEB:customization');

  if (customization) {
    document.documentElement.style.setProperty('--main-color', `${customization.mainColor}` || '#088671');
    document.documentElement.style.setProperty('--hover-color', `${customization.hoverColor}` || '#08816d');
  }

  return (
    <TypeRouter>
      <ThemeProvider theme={themeWithLocale}>
        <AppProvider>
          <Content />
        </AppProvider>
      </ThemeProvider>
    </TypeRouter>
  );
}

export default App;
