import React, { useCallback, useEffect, useState } from 'react';
import { Button, DialogActions, DialogContent } from '@mui/material';

import {
  BottonCustom,
  Container,
  DownloadArea,
  Header,
  InvoiceCard,
  InvoiceKey,
  NoneInvoices,
  Title,
} from './OrderInvoicesDialog.styled';

import { useToastr } from '../../../hooks/useToastr';

import IInvoice from '../../../interfaces/IInvoice';
import { useOrderInvoicesService } from '../../../services/useOrderInvoiceService';
import moment from 'moment';
import useFormatIntegerDecimalValues from '../../../util/useFormatIntegerDecimalValues';
import { FiDownload } from 'react-icons/fi';
import { BackdropCustom } from '../../../components/backdrop/Backdrop';

interface IOrderInvoicesDialogProps {
  dialogOpen: boolean;
  orderId: string;
  handleCloseDialog: () => void;
  handleOpenDialog: () => void;
}

const OrderInvoicesDialog: React.FC<IOrderInvoicesDialogProps> = ({
  dialogOpen,
  orderId,
  handleCloseDialog,
  handleOpenDialog,
}) => {
  const toastr = useToastr();
  const { listOrderInvoice } = useOrderInvoicesService();
  const { formatIntegerDecimalValues } = useFormatIntegerDecimalValues();
  const [loading, setLoading] = useState(false);

  const [invoices, setInvoices] = useState<IInvoice[]>([]);

  const [os] = useState(() => {
    const toMatch = [/Macintosh/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
    return toMatch.some(toMatchItem => {
      return navigator.userAgent.match(toMatchItem);
    });
  });

  const handleListInvoices = useCallback(async () => {
    setLoading(true);
    await listOrderInvoice(orderId, true)
      .then(response => {
        setInvoices(response);
      })
      .catch(error => {
        toastr.error(error?.message || 'Contate a equipe de suporte');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [orderId]);

  useEffect(() => {
    if (dialogOpen) {
      handleListInvoices();
    }
  }, [dialogOpen]);

  const downloadPDF = useCallback(
    (file: string | null, invoiceNumber: number) => {
      // Obter o base64 do arquivo PDF do estado ou de onde você o armazena
      const base64PDF = file;

      if (base64PDF) {
        // Converter o base64 em blob
        const byteCharacters = atob(base64PDF);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        // Criar um URL do blob e realizar o download
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        if (os) {
          link.target = '_blank';
          link.click();
        } else {
          link.download = `${invoiceNumber}.pdf`;
          link.click();
        }

        // Limpar o URL do blob após o download
        URL.revokeObjectURL(url);
      }
    },
    [os],
  );

  const downloadXML = useCallback((file: string | null, invoiceNumber: number) => {
    // Obter o base64 do arquivo XML do estado ou de onde você o armazena
    const base64XML = file;

    if (base64XML) {
      // Converter o base64 em blob
      const byteCharacters = atob(base64XML);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/xml' });

      // Criar um URL do blob e realizar o download
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      link.download = `${invoiceNumber}.xml`;
      link.click();

      // Limpar o URL do blob após o download
      URL.revokeObjectURL(url);
    }
  }, []);

  return (
    <>
      {loading ? (
        <BackdropCustom />
      ) : (
        <Container
          open={dialogOpen}
          onClose={handleOpenDialog}
          sx={{
            '& .MuiDialog-container': {
              width: '100vw',
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}
        >
          <DialogContent sx={{ minWidth: '250px' }}>
            <Title>Documentos eletrônicos</Title>

            {invoices && invoices.length > 0 ? (
              invoices.map(invoice => {
                return (
                  <InvoiceCard key={invoice.id}>
                    <Header>
                      <div>
                        <span>NF: </span>
                        <strong>{invoice.number}</strong>
                      </div>
                      <span>Data de emissão: {moment(invoice.emissionDate).format('DD/MM/YYYY')}</span>
                    </Header>

                    <span>Valor líquido: {formatIntegerDecimalValues(invoice.netValue, 'DECIMAL')}</span>
                    <InvoiceKey>Chave: {invoice.invoiceKey}</InvoiceKey>

                    <DownloadArea>
                      {invoice.pdf && (
                        <BottonCustom
                          onClick={() => {
                            downloadPDF(invoice.pdf, invoice.number);
                          }}
                        >
                          <FiDownload size={16} />
                          Baixar PDF
                        </BottonCustom>
                      )}

                      {invoice.xml && (
                        <BottonCustom
                          onClick={() => {
                            downloadXML(invoice.xml, invoice.number);
                          }}
                        >
                          <FiDownload size={16} />
                          Baixar XML
                        </BottonCustom>
                      )}
                    </DownloadArea>
                  </InvoiceCard>
                );
              })
            ) : (
              <NoneInvoices>
                <span>Nenhum documento eletrônico para listar...</span>
              </NoneInvoices>
            )}
          </DialogContent>
          <DialogActions sx={{ marginRight: '10px' }}>
            <Button color="info" onClick={handleCloseDialog} sx={{ textTransform: 'none' }}>
              Fechar
            </Button>
          </DialogActions>
        </Container>
      )}
    </>
  );
};

export { OrderInvoicesDialog };
