import AppError from '../errors/AppError';
import { IEvent } from '../interfaces/IEvent';
import { useRequest } from './useRequest';

const useOrderEventsService = () => {
  const requestOrders = useRequest<IEvent[]>();
  const pathOrders = 'events';

  const listOrderEvent = async (id: string, useAuth = false): Promise<IEvent[]> => {
    return await requestOrders
      .getOne({ path: `${pathOrders}/${id}`, sendAuthorization: useAuth })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message, error.status);
      });
  };

  return { listOrderEvent };
};

export { useOrderEventsService };
