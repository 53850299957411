import AppError from '../errors/AppError';
import { ICustomerPortalUserCustomer } from '../interfaces/ICustomerPOrtalUserCustomer';
import { IPaginationReturn } from '../interfaces/IPaginationReturn';
import { useRequest } from './useRequest';

interface IData {
  id: string;
  password: string;
  passwordConfirmation: string;
}
const useCustomerPortalUsersService = () => {
  const requestCustomerUserCustomer = useRequest<ICustomerPortalUserCustomer>();
  const requestActivateCustomerUser = useRequest<void>();
  const path = 'customer-portal-users';

  const findAllCustomerPortalUser = async (
    queryParams: string,
  ): Promise<IPaginationReturn<ICustomerPortalUserCustomer[]>> => {
    return await requestCustomerUserCustomer
      .getManyPaginated({ path: `${path}?${queryParams}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const activeCustomerPortalUser = async (id: string): Promise<void> => {
    return await requestActivateCustomerUser
      .patch({
        path: `${path}/active/${id}`,
        sendAuthorization: true,
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const updatePasswordCustomerPortalUser = async ({ id, password, passwordConfirmation }: IData): Promise<void> => {
    return await requestActivateCustomerUser
      .put({
        path: `${path}/password/${id}`,
        sendAuthorization: true,
        body: {
          password,
          passwordConfirmation,
        },
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  return { findAllCustomerPortalUser, activeCustomerPortalUser, updatePasswordCustomerPortalUser };
};

export { useCustomerPortalUsersService };
