import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Alert, FormControl, FormControlLabel, Radio, Stack } from '@mui/material';
import InputMask from 'react-input-mask';

import {
  Container,
  Content,
  LogoImg,
  ButtonCustom,
  Input,
  SignUpLink,
  TextFieldCustom,
} from './CustomerPortalSignIn.styles';

import logoLoginDefault from '../../../assets/logoLogin.svg';
import IFormError from '../../../interfaces/IFormError';
import getValidationError from '../../../util/getValidationError';
import IPage from '../../../interfaces/IPage';
import { useCustomerPortalAuth } from '../../../hooks/custmerPortalAuth';
import { useOpenedRoutesService } from '../../../services/useOpenedRoutesService';
import { BackdropCustom } from '../../../components/backdrop/Backdrop';
import { RadioGroupCustom } from '../signup/CustomerPortalSignup.styles';
import { useToastr } from '../../../hooks/useToastr';
import { useLocalStorage } from '../../../services/useLocalStorage';

const CustomerPortalSignIn: React.FC<IPage> = ({ title }) => {
  window.document.title = title;

  const navigate = useNavigate();
  const location = useLocation();
  const toastr = useToastr();
  const localStorage = useLocalStorage();

  const { customerPortalSignIn, customerPortalUpdateTheme } = useCustomerPortalAuth();
  const { getCustomization } = useOpenedRoutesService();

  const [tenant] = useState(() => {
    return process.env.REACT_APP_CLIENT_TENANT_DOMAIN
      ? process.env.REACT_APP_CLIENT_TENANT_DOMAIN
      : location.search.replaceAll('?tenant=', '');
  });

  const [loginType, setLoginType] = useState<'EMAIL' | 'PHONE'>(() => {
    return localStorage.getItem('@PEDIDO-WEB:loginType') ?? 'EMAIL';
  });
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErros] = useState<IFormError>({});
  const [loadingCustom, setLoadingCustom] = useState(false);
  const [logoLogin, setLogoLogin] = useState<string | null>(null);
  const [textLogin, setTextLogin] = useState<string | null>(null);

  const [responseError, setResponseError] = useState<string | null>(null);
  const [responseSuccess] = useState<string | null>(null);

  const handleSubmit = useCallback(async (): Promise<void> => {
    setLoading(true);
    setFormErros({});
    localStorage.setItem('@PEDIDO-WEB:loginType', loginType);

    try {
      const data = {
        email: loginType === 'EMAIL' ? email : '',
        phoneNumber: loginType === 'PHONE' ? phoneNumber.replace(/\D/g, '') : '',
        password,
        tenant,
      };

      if (!tenant) {
        navigate('/customer-portal/tenant');
        toastr.error('Por favor informe o domínio.');
        return;
      }

      let schema;

      if (loginType === 'EMAIL') {
        schema = Yup.object().shape({
          email: Yup.string().required('E-mail obrigatório').email('Informe um e-mail válido'),
          password: Yup.string().required('Senha obrigatória'),
        });
      } else {
        schema = Yup.object().shape({
          phoneNumber: Yup.string().required('Telefone obrigatório').min(10, 'O número de telefone não é válido'),
          password: Yup.string().required('Senha obrigatória'),
        });
      }

      await schema.validate(data, {
        abortEarly: false,
      });

      await customerPortalSignIn(data)
        .then(() => {
          navigate('/customer-portal/dashboard');
        })
        .catch(error => {
          setResponseError(error.message || 'Ocorreu um erro ao fazer login, Contate o suporte.');
        });
    } catch (err: Yup.ValidationError | any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationError(err);
        setFormErros(errors);
        return;
      }

      setResponseError('Ocorreu um erro ao fazer login. Contate o suporte.');
    } finally {
      setLoading(false);
    }
  }, [email, phoneNumber, password, tenant, navigate]);

  const handleGetCustomization = useCallback(async () => {
    setLoadingCustom(true);
    await getCustomization(btoa(tenant))
      .then(customization => {
        localStorage.setItem('@PEDIDO-WEB:customization', JSON.stringify(customization));

        setLogoLogin(customization.logoLogin);
        setTextLogin(customization.textLogin);

        customerPortalUpdateTheme(customization);
      })
      .catch(error => {
        toastr.error(`${error.message}. Contate o suporte.` || 'Erro ao obter domínio. Contate o suporte.');
      })
      .finally(() => {
        setLoadingCustom(false);
      });
  }, [tenant]);

  useEffect(() => {
    if (tenant) {
      handleGetCustomization();
    }
  }, [tenant, toastr]);

  const handleChangeLoginType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber('');
    setEmail('');
    const type = (event.target as HTMLInputElement).value === 'EMAIL' ? 'EMAIL' : 'PHONE';

    setLoginType(type);
    localStorage.setItem('@PEDIDO-WEB:loginType', type);
  };

  return (
    <Container textLogin={!!textLogin}>
      {textLogin && (
        <Stack sx={{ width: '100%', marginBottom: '40px' }} spacing={2}>
          <Alert severity="info" variant="filled" style={{ fontSize: '16px' }}>
            {textLogin}
          </Alert>
        </Stack>
      )}
      {loadingCustom ? (
        <BackdropCustom />
      ) : (
        <Content elevation={5}>
          {loading && <BackdropCustom />}
          {(!!responseError || !!responseSuccess) && (
            <Stack sx={{ width: '100%', marginBottom: '16px' }} spacing={2}>
              {!!responseSuccess && <Alert severity="success">{responseSuccess}</Alert>}
              {!!responseError && <Alert severity="error">{responseError}</Alert>}
            </Stack>
          )}
          <LogoImg>
            <img src={logoLogin || logoLoginDefault} alt="Portal de Boletos" />
            <h2>Portal de Boletos</h2>
          </LogoImg>

          <form>
            <FormControl style={{ width: '100%', marginTop: '16px' }}>
              <RadioGroupCustom
                aria-labelledby="login-type-radio-buttons-group"
                name="login-type"
                value={loginType}
                onChange={handleChangeLoginType}
              >
                <FormControlLabel value="EMAIL" control={<Radio />} label="E-mail" />
                <FormControlLabel value="PHONE" control={<Radio />} label="Telefone" />
              </RadioGroupCustom>
            </FormControl>

            {loginType === 'EMAIL' ? (
              <Input>
                <label>Endereço de e-mail</label>
                <TextFieldCustom
                  size="small"
                  value={email}
                  onChange={e => setEmail(e.target.value.toLowerCase())}
                  autoComplete="off"
                  helperText={formErrors.email}
                  error={!!formErrors.email}
                  id="email"
                />
              </Input>
            ) : (
              <Input>
                <label>Número de telefone</label>
                <InputMask
                  mask={'(99) 999999999'}
                  value={phoneNumber}
                  onChange={e => setPhoneNumber(e.target.value)}
                  maskPlaceholder={null}
                >
                  <TextFieldCustom
                    fullWidth
                    size="small"
                    helperText={formErrors.phoneNumber}
                    error={!!formErrors.phoneNumber}
                  />
                </InputMask>
              </Input>
            )}

            <Input>
              <div>
                <label>Senha</label>

                <span
                  onClick={() => {
                    if (tenant) {
                      navigate(`/customer-portal/forgot-password?tenant=${tenant}`);
                    } else {
                      navigate('/customer-portal/tenant');
                      toastr.error('Por favor informe o domínio.');
                      return;
                    }
                  }}
                >
                  Recuperar senha?
                </span>
              </div>
              <TextFieldCustom
                size="small"
                value={password}
                onChange={e => setPassword(e.target.value)}
                type="password"
                autoComplete="off"
                helperText={formErrors.password}
                error={!!formErrors.password}
                id="password"
              />
            </Input>

            <ButtonCustom onClick={handleSubmit} disabled={loading} variant="contained" id="submit">
              Entrar
            </ButtonCustom>
          </form>

          <SignUpLink>
            <strong>Você não tem sua conta?</strong>
            <span
              onClick={() => {
                if (tenant) {
                  navigate(`/customer-portal/signup?tenant=${tenant}`);
                } else {
                  navigate('/customer-portal/tenant');
                  toastr.error('Por favor informe o domínio.');
                  return;
                }
              }}
            >
              Crie sua conta
            </span>
          </SignUpLink>
        </Content>
      )}
    </Container>
  );
};

export { CustomerPortalSignIn };
