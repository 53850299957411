import { FormControl, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';

export const DialogEditItem = styled(Dialog)`
  && {
    .product-area {
      width: 500px;
    }

    @media (max-width: 620px) {
      .product-area {
        width: 400px;
      }
    }

    @media (max-width: 520px) {
      .product-area {
        width: 300px;
      }
    }

    @media (max-width: 420px) {
      .product-area {
        width: 250px;
      }
    }

    @media (max-width: 360px) {
      .product-area {
        width: 220px;
      }
    }
  }
`;

export const ProductsSelected = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: start;
  align-items: center;
  height: 116px;
  margin-bottom: -16px;

  img {
    min-width: 20%;
  }

  div {
    font-size: 14px;
    display: flex;
    justify-content: start;
    align-items: center;
  }

  p {
    margin-left: 16px;
  }
`;

export const ProductInfo = styled.div`
  font-size: 12px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 16px;
  }
`;

export const InputMui = styled(TextField)`
  && {
    height: 55px;
    margin: 8px;
    width: 100%;
  }
`;

export const FormControlMui = styled(FormControl)`
  && {
    padding: 0;
  }
`;

export const FlexContainer = styled.div`
  font-size: 12px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  span {
    margin-right: 10px;
  }
`;
