import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 8px;
`;

export const FilterArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

interface IContentProps {
  disable: boolean;
}

export const Content = styled.div<IContentProps>`
  display: flex;
  padding: 16px;
  padding-bottom: 14px;
  font-size: 0.75rem;
  border: 0.5px solid #c0c0c0;
  border-radius: 8px;

  & + & {
    margin-top: 8px;
  }

  > div {
    flex: 1;
  }

  ${props =>
    props.disable &&
    css`
      &:hover {
        cursor: default;
      }
    `}
`;

export const HeaderCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .receivable-account-area {
    display: flex;

    span {
      margin-left: 16px;
    }
  }

  .receivable-account {
    font-size: 0.875rem;
    text-decoration: none;
    color: var(--main-color);
    margin-right: 10px;
    line-height: 16px;
  }
`;

export const BodyCard = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;

  span + span {
    margin-top: 12px;
  }
`;

export const BodyCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const RightArea = styled.div`
  display: flex;
  flex: 2;
  max-width: 150px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;

  > span {
    white-space: nowrap;
  }
`;

interface IActionAreaProps {
  selected: boolean;
}
export const ActionArea = styled.div<IActionAreaProps>`
  display: flex;
  margin-top: 16px;

  svg {
    font-size: 36px;
  }

  #select-button {
    color: #e0e0e0;
  }

  ${props =>
    props.selected &&
    css`
      #select-button {
        color: #2f7d31;
      }
    `}
`;

interface ISelectButtonProps {
  selected: boolean;
}

export const SelectButton = styled.div<ISelectButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  transition: color 0.3s;

  color: #c0c0c0;

  &:hover {
    color: #1b5e20;
    cursor: pointer;
  }

  ${props =>
    props.selected &&
    css`
      color: #2e7d32;

      &:hover {
        color: #1b5e20;
      }
    `}
`;

export const FileDownloader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 16px;

  span {
    margin-left: 8px;
  }

  @media (max-width: 600px) {
    margin-left: 0px;
    justify-content: space-between;

    button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    span {
      margin-left: 0px;
    }
  }
`;

export const DownloadButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;

  color: #1876d2;

  &:hover {
    color: #0054b0;
    cursor: pointer;
  }
`;

export const Input = styled.div`
  font-size: 0.75rem;
  font-weight: 600;

  > div {
    display: flex;
    justify-content: space-between;
  }

  span {
    cursor: pointer;
    color: #1876d2;

    &:hover {
      color: #0054b0;
    }
  }

  & + & {
    margin-top: 16px;
  }
`;
