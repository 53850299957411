import styled from 'styled-components';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
interface IThProps {
  minWidth?: string;
}

export const OrderComments = styled.div`
  max-width: 780px;
  margin-left: 30px;
  font-size: 13px;
`;

export const Container = styled.table`
  page-break-after: always;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  width: 100%;
  margin-bottom: 0px;
  height: 0px;
  min-width: 1270px;
`;

export const Content = styled.tbody``;

export const OrderContentContainer = styled.div`
  margin-bottom: 20px;
  padding-bottom: 40px;
`;

export const Header = styled.thead`
  display: table-header-group;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -15px;
  padding-left: 20px;
  color: #0453ba;
  max-width: 100%;
`;

export const HeaderContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
`;

export const HeaderContentTotal = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
`;

export const HeaderValue = styled.span`
  width: 100px;
  text-align: 'left';
`;

export const HeaderTitleValue = styled.div`
  font-family: MontserratBold;
  width: 140px;
`;

export const HeaderContentRight = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 250px;
`;

export const HeaderNumberArea = styled.div`
  width: 100%;
  height: 80px;
  background-color: #0453ba;
  border-radius: 10px;
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
`;

export const HeaderNumberInternal = styled.div`
  padding: 3px 10px 3px 10px;
  font-family: MontserratBold;
`;

export const HeaderNumberLine = styled.div`
  width: 0.5px;
  height: 80%;
  background-color: white;
  opacity: 0.6;
`;

export const HeaderBold = styled.div`
  font-family: MontserratBold;
  margin-right: 10px;
`;

export const HeaderContentRow = styled.div`
  display: flex;
  position: relative;
`;

export const HeaderContentColumn = styled.div`
  & + & {
    position: absolute;
    left: 500px;
    width: 100%;
  }
`;

export const HeaderTitle = styled.div`
  font-family: MontserratBold;
  font-size: 15px;
`;

export const HeaderComment = styled.div`
  display: flex;
  font-size: 12px;
  text-align: start;
`;

export const HeaderFlex = styled.div`
  display: flex;
  font-size: 12px;
  text-align: start;

  span {
    width: 47vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 1800px) {
      width: 30vw;
    }

    @media (max-width: 1400px) {
      width: 25vw;
    }

    @media (max-width: 1000px) {
      width: 20vw;
    }

    @media (max-width: 900px) {
      width: 15vw;
    }
  }
`;

export const Footer = styled.div`
  @media print {
    display: none;
  }
  min-width: 1270px;
  width: 100%;
`;

export const FooterPrint = styled.tfoot`
  display: none;
  @media print {
    display: table-footer-group;
    margin-top: auto;
    /* position: fixed;
    bottom: 0;
    margin-bottom: -5px; */
  }
`;

export const Table = styled.table`
  width: 100%;
  color: #0453ba;
  border-collapse: collapse;
`;

export const THead = styled.thead``;

export const TBody = styled.tbody``;

export const Tr = styled.tr`
  &:hover {
    background-color: #dfe1e3;
  }

  @media print {
    &:hover {
      background-color: white;
    }
  }
`;

export const Th = styled.th<IThProps>`
  min-width: ${p => p.minWidth};
  text-align: center;
  padding: 15px 0px 15px 0px;
  background-color: #dfe1e3;
`;

export const Td = styled.td<IThProps>`
  width: ${p => p.minWidth};
  text-align: center;
  font-family: MontserratBold;
  padding: 0px 0px 0px 0px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 11px;
  border-bottom: 1px solid gray;
`;

export const Button = styled.a`
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#00a5fe, #0063ff);
  color: white;
  border-radius: 20px;
  padding: 5px;
  width: 110px;
  font-size: 12px;
  font-family: MontserratBold;
  border: none;
  margin-top: 10px;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  &:active {
    background: #0453ba;
    opacity: 0.5;
  }
`;

export const Whatsapp = styled(WhatsAppIcon)`
  font-size: 15px !important;
  position: absolute !important;
  left: 5px !important;
`;

export const Email = styled(EmailOutlinedIcon)`
  font-size: 15px !important;
  position: absolute !important;
  left: 8px !important;
`;

export const ImageLink = styled.a`
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
  min-width: 1270px;
  width: 100%;
`;

export const ImageLinkFooter = styled.a`
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */

  width: 100%;

  img {
    min-width: 1284px;
    width: 100%;
  }
`;

export const TableImageLink = styled.a`
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 4px;

  img {
    width: auto;
    height: 52px;
    margin: 2px;
    border-radius: 4px;
  }
`;

export const ProductImageContainer = styled.div`
  background-color: #dfe1e3;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ContentRedValue = styled.div`
  color: red;
  font-size: 0.7rem;
  margin-top: 5px;
  position: absolute;
  bottom: -15px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
