import { Grid } from '@mui/material';

import styled from 'styled-components';

export const TableBodyCard = styled.div``;

export const FilterArea = styled(Grid)`
  && {
    padding: 0px 8px;
    margin-bottom: 20px;
  }
`;

export const OrderStatus = styled.span`
  font-weight: bold;
  font-size: 14px;
`;

export const BodyCard = styled.div`
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-top: 12px;

  span + span {
    margin-top: 12px;
  }
`;

export const TableCardItem = styled.div`
  display: flex;
  justify-content: space-between;

  & + & {
    margin-top: 24px;
  }
`;
