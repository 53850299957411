import { LoadingButton } from '@mui/lab';
import styled, { css } from 'styled-components';

interface IContanerProps {
  disabled: boolean;
  default: boolean;
}

export const Container = styled(LoadingButton)<IContanerProps>`
  && {
    text-transform: none;
    min-width: 80px;

    ${props =>
      props.default &&
      css`
        color: var(--main-color); !important;
      `}

    ${props =>
      props.disabled &&
      css`
        color: #fff !important;
        margin-right: 8px;
      `}

      & +& {
      margin-left: 8px;
    }

    @media (max-width: 900px) {
      flex-direction: column;
      justify-content: center;
    }
  }
`;
