import React, { HtmlHTMLAttributes } from 'react';
import { Container } from './ButtonGroup.styled';

interface IButtonGroupProps extends HtmlHTMLAttributes<HTMLDivElement> {}

const ButtonGroup: React.FC<IButtonGroupProps> = ({ children }) => {
  return <Container>{children}</Container>;
};

export { ButtonGroup };
