import React, { HtmlHTMLAttributes, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import List from '@mui/material/List';
import { Avatar, SwipeableDrawer } from '@mui/material';

import { useCustomerPortalAuth } from '../../../hooks/custmerPortalAuth';
import useWindowDimensions from '../../../util/useWindowDimensions';
import logoHeaderDefault from '../../../assets/logo-senior.png';

import {
  AppMenuStyled,
  UserMenu,
  MenuStyled,
  AppMenuInfo,
  MenuButton,
  LogoLarge,
  MenuContentLarge,
  MenuItemCustom,
  LogoSmall,
  MenuContentSmall,
  ListItemMenuSmall,
  ListItemTileMenuSmall,
} from './CustomerPortalAppMenu.styled';
import { ICustomization } from '../../../interfaces/ICustomization';
import { AddCustomer } from '../../../pages/customer-portal/add-customer/AddCustomer';

interface IProps extends HtmlHTMLAttributes<HTMLElement> {
  logoHeader: string;
}

const CustomerPortalAppMenu: React.FC<IProps> = ({ logoHeader }) => {
  const navigate = useNavigate();
  const pathLocation = useLocation();
  const { width } = useWindowDimensions();
  const {
    customerPortalSession,
    customerPortalCustomerSelected,
    customerPortalSignOut,
    customerPortalUpdateCustomerSelected,
    customerPortalCustomization,
  } = useCustomerPortalAuth();

  const [anchorUserMenu, setAnchorUserMenu] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorUserMenu);

  const [isScreenSmall, setIsScreenSmall] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [toogleMenuPoint] = useState(1100);
  const [customization] = useState<ICustomization>(() => {
    return customerPortalCustomization;
  });

  const receivableAccountsSelected = useMemo(() => {
    return (
      pathLocation.pathname === '/customer-portal/receivable-accounts' ||
      pathLocation.pathname.includes('/customer-portal/receivable-accounts/')
    );
  }, [pathLocation.pathname]);

  const [addCustomerDialogOpen, setAddCustomerDialogOpen] = useState(false);

  useEffect(() => {
    if (width < toogleMenuPoint) {
      setIsScreenSmall(true);
      setIsMenuOpen(false);
    } else {
      setIsScreenSmall(false);
    }
  }, [width]);

  const getAvatar = (): string => {
    try {
      const firstLetter = customerPortalSession?.user?.name?.split('')[0]?.toUpperCase();
      const lastLetter = customerPortalSession?.user?.name
        ?.split(' ')
        [customerPortalSession?.user?.name?.split(' ').length - 1]?.split('')[0]
        ?.toUpperCase();
      return `${firstLetter}${lastLetter}`;
    } catch (error) {
      return customerPortalSession?.user?.name?.split('')[0]?.toUpperCase();
    }
  };

  const getName = () => {
    return customerPortalSession?.user?.name?.split(' ')[0];
  };

  const logout = () => {
    customerPortalSignOut();
  };

  const getAppMenuLarge = () => {
    return (
      <MenuContentLarge>
        <LogoLarge onClick={() => navigate('/customer-portal/dashboard')}>
          <img src={logoHeader || logoHeaderDefault} alt="" />
        </LogoLarge>

        <MenuButton
          key={'meus-boletos-large'}
          variant="text"
          onClick={() => navigate('/customer-portal/receivable-accounts')}
          selected={receivableAccountsSelected}
        >
          Meus Boletos
        </MenuButton>

        <MenuButton
          key={'adicionar-cnpj-cpf-large'}
          variant="text"
          onClick={() => {
            setAddCustomerDialogOpen(!addCustomerDialogOpen);
          }}
          selected={receivableAccountsSelected}
        >
          Adicionar CNPJ/CPF
        </MenuButton>
      </MenuContentLarge>
    );
  };

  const getAppMenuSmall = () => {
    return (
      <MenuContentSmall>
        <LogoSmall
          onClick={() => {
            navigate('/customer-portal/dashboard');
            setIsMenuOpen(false);
          }}
        >
          <img src={customization?.logoHeader || logoHeaderDefault} alt="" />
        </LogoSmall>

        <List>
          <ListItemMenuSmall
            key={'meus-boletos-small'}
            disablePadding
            onClick={() => {
              navigate('/customer-portal/receivable-accounts');
              setIsMenuOpen(false);
            }}
          >
            <ListItemTileMenuSmall primary="Meus Boletos" selected={receivableAccountsSelected} />
          </ListItemMenuSmall>
          <ListItemMenuSmall
            key={'adicionar-cnpj-cpf-small'}
            disablePadding
            onClick={() => {
              setIsMenuOpen(false);
              setAddCustomerDialogOpen(!addCustomerDialogOpen);
            }}
          >
            <ListItemTileMenuSmall primary="Adicionar CNPJ/CPF" selected={false} />
          </ListItemMenuSmall>
        </List>
      </MenuContentSmall>
    );
  };

  return (
    <AppMenuStyled logged={!!customerPortalSession?.user} isScreenSmall={isScreenSmall}>
      <MenuStyled isScreenSmall={isScreenSmall}>
        {isScreenSmall ? (
          <>
            <MenuButton onClick={() => setIsMenuOpen(true)} sx={{ position: 'absolute', left: -25, top: -17.5 }}>
              <MenuIcon sx={{ color: '#fff' }} />
            </MenuButton>
            <SwipeableDrawer
              anchor="left"
              open={isMenuOpen}
              onClose={() => setIsMenuOpen(false)}
              onOpen={() => setIsMenuOpen(true)}
              PaperProps={{
                sx: { display: 'flex', alignItems: 'flex-start' },
              }}
            >
              {getAppMenuSmall()}
            </SwipeableDrawer>
          </>
        ) : (
          getAppMenuLarge()
        )}
      </MenuStyled>

      <AppMenuInfo>
        <MenuButton
          variant="text"
          color="inherit"
          aria-controls={open ? 'menu-button' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={e => setAnchorUserMenu(e.currentTarget)}
          endIcon={customerPortalSession?.user && (anchorUserMenu ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)}
        >
          <Avatar sx={{ width: 24, height: 24, fontSize: '12px' }}>{getAvatar()}</Avatar>
          {width > toogleMenuPoint && <span style={{ marginLeft: '16px' }}>{getName()}</span>}
        </MenuButton>
        <UserMenu
          anchorEl={anchorUserMenu}
          open={open}
          onClose={() => setAnchorUserMenu(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {customerPortalSession &&
            customerPortalSession.user &&
            customerPortalSession?.user?.customers.map(customer => (
              <MenuItemCustom
                sx={{ minWidth: '150px' }}
                selected={customerPortalCustomerSelected.id === customer.id}
                onClick={() => {
                  customerPortalUpdateCustomerSelected(customer.id);
                }}
              >
                {customer.corporateDocument} - {customer.tradeName}
              </MenuItemCustom>
            ))}
          <MenuItemCustom
            sx={{ minWidth: '150px' }}
            onClick={() => {
              setAnchorUserMenu(null);
              logout();
            }}
          >
            Sair
          </MenuItemCustom>
        </UserMenu>
      </AppMenuInfo>

      <AddCustomer
        dialogOpen={addCustomerDialogOpen}
        deny={() => {
          setAddCustomerDialogOpen(!addCustomerDialogOpen);
        }}
      />
    </AppMenuStyled>
  );
};

export default CustomerPortalAppMenu;
